import axios from 'axios';
import { useExercise } from './ExerciseContext';

export const useSubmission = (id) => {
    const { userAnswers, setSubmissionResult, setError, exerciseData } = useExercise();
  
    const handleSubmit = async () => {
      console.log('Submitting answers:', userAnswers); // Debugging log
  
      try {
        // Make sure to stringify keys for consistency
        const formattedAnswers = Object.fromEntries(
          Object.entries(userAnswers).map(([key, value]) => [String(key), value])
        );
        console.log(exerciseData)
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/submit-listening-exercise`, {
          exercise_type: exerciseData.exercise_type,
          exercise_id: id,
          answers: formattedAnswers, // Send formatted answers
        });
  
        console.log('Submission response:', response.data); // Debugging log to check response
        setSubmissionResult(response.data);
      } catch (error) {
        console.error('Error submitting exercise:', error);
        setError('Failed to submit exercise. Please try again later.');
      }
    };
  
    return {
      handleSubmit,
    };
  };