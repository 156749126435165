import React, { useState, useEffect } from "react";

const Tick=()=>{
  return (
    <svg
      className="h-8 w-8 text-white"
      fill="none"
      stroke="#26bd4e"
      strokeWidth="3"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
    </svg>
  );
}



const SingleMCQ = ({
  currentQuestionIndex,
  question,
  onAnswer,
  userAnswer,
  isSubmitted,
}) => {
  const handleOptionChange = (e) => {
    onAnswer(question.question_number, e.target.value);
  };
  return (
    <div>
      <h2 className="text-xl mr-2 text-justify font-semibold mb-4">
        {currentQuestionIndex + 1}
        {". "}
        {question.question_text}
      </h2>
      <div>
        {Object.entries(question.options).map(([optionText, optionLabel]) => (
          <div key={optionLabel} className="mb-2">
            <label className="flex items-baseline">
              <div className="p-1">{optionLabel}) </div>
              <input
                type="radio"
                name={`question-${question.question_number}`}
                value={optionLabel}
                checked={userAnswer === optionLabel}
                onChange={handleOptionChange}
                className="mr-2"
              />
              <div className="mr-2 text-justify flex">
                {optionText}
                {question.correct_answers.includes(optionLabel) &&
                  isSubmitted && (
                    <span>
                      <Tick />
                    </span>
                  )}
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const HighlightTextSingleMCQ = ({
  currentQuestionIndex,
  question,
  onAnswer,
  userAnswer,
  highlightText,
  isSubmitted,
}) => {
  const handleOptionChange = (e) => {
    onAnswer(question.question_number, e.target.value);
  };
  return (
    <div>
      <h2 className="text-xl mr-2 text-justify font-semibold mb-4">
        {currentQuestionIndex + 1}
        {". "}
        {question.question_text}
      </h2>
      <div className="mr-2 text-justify">
        <span className=" bg-yellow-200">{highlightText}</span>
      </div>
      <br />
      <div>
        {Object.entries(question.options).map(([optionText, optionLabel]) => (
          <div key={optionLabel} className="mb-2">
            <label className="flex items-baseline">
              <div className="p-1">{optionLabel}) </div>
              <input
                type="radio"
                name={`question-${question.question_number}`}
                value={optionLabel}
                checked={userAnswer === optionLabel}
                onChange={handleOptionChange}
                className="mr-2"
              />
              <div className="mr-2 text-justify flex">
                {optionText}
                {question.correct_answers.includes(optionLabel) &&
                  isSubmitted && (
                    <span>
                      <Tick />
                    </span>
                  )}
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const HighlightWordSingleMCQ = ({
  currentQuestionIndex,
  question,
  onAnswer,
  userAnswer,
  highlightWord,
  isSubmitted,
}) => {
  const handleOptionChange = (e) => {
    onAnswer(question.question_number, e.target.value);
  };
  const QuestionWord = question.question_text.split(highlightWord);
  return (
    <div>
      <h2 className="text-xl font-semibold mr-2 text-justify mb-4">
        {currentQuestionIndex + 1}
        {". "}
        {QuestionWord[0]}
        <span className="bg-yellow-200">{highlightWord}</span>
        {QuestionWord[1]}
      </h2>

      <br />
      <div>
        {Object.entries(question.options).map(([optionText, optionLabel]) => (
          <div key={optionLabel} className="mb-2">
            <label className="flex items-baseline">
              <div className="p-1">{optionLabel}) </div>
              <input
                type="radio"
                name={`question-${question.question_number}`}
                value={optionLabel}
                checked={userAnswer === optionLabel}
                onChange={handleOptionChange}
                className="mr-2"
              />
              <div className="mr-2 text-justify flex">
                {optionText}
                {question.correct_answers.includes(optionLabel) &&
                  isSubmitted && (
                    <span>
                      <Tick />
                    </span>
                  )}
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const InsertTextMCQ = ({
  currentQuestionIndex,
  question,
  statement,
  onAnswer,
  userAnswer,
  isSubmitted,
}) => {
  const handleOptionChange = (e) => {
    onAnswer(question.question_number, e.target.value);
  };
  return (
    <div>
      <h2 className="text-xl font-semibold mr-2 text-justify mb-4">
        {currentQuestionIndex + 1}
        {". "}
        {question.question_text}
      </h2>
      <h3 className="text-lg mr-2 text-justify font-semibold mb-4">
        {statement}
      </h3>
      <div>
        {Object.entries(question.options).map(([optionText, optionLabel]) => (
          <div key={optionLabel} className="mb-2">
            <label className="flex items-baseline">
              <div className="p-1">{optionLabel}) </div>
              <input
                type="radio"
                name={`question-${question.question_number}`}
                value={optionLabel}
                checked={userAnswer === optionLabel}
                onChange={handleOptionChange}
                className="mr-2"
              />
              <div className="mr-2 text-justify flex">
                {optionText}
                {question.correct_answers.includes(optionLabel) &&
                  isSubmitted && (
                    <span>
                      <Tick />
                    </span>
                  )}
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};


const SummaryMCQ = ({
  currentQuestionIndex,
  question,
  startSentence,
  onAnswer,
  userAnswer = [],
  isSubmitted,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(userAnswer);

  useEffect(() => {
    if (selectedOptions.length <= 3) {
      onAnswer(question.question_number, selectedOptions);
    }
  }, [selectedOptions]);

  const handleOptionChange = (option) => {
    let updatedOptions;
    if (selectedOptions.includes(option)) {
      updatedOptions = selectedOptions.filter((item) => item !== option);
    } else if (selectedOptions.length < 3) {
      updatedOptions = [...selectedOptions, option];
    } else {
      return;
    }
    setSelectedOptions(updatedOptions);
  };

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4 mr-2 text-justify">
        {currentQuestionIndex + 1}
        {". "}
        {question.question_text}
      </h2>
      <h3 className="text-lg font-semibold mr-2 text-justify mb-4">
        {startSentence}
      </h3>
      <div>
        {Object.entries(question.options).map(([optionText, optionLabel]) => (
          <div key={optionLabel} className="mb-2">
            <label className="flex items-baseline">
              <div className="p-1">{optionLabel})</div>
              <input
                type="checkbox"
                name={`question-${question.question_number}`}
                value={optionLabel}
                checked={selectedOptions.includes(optionLabel)}
                onChange={() => handleOptionChange(optionLabel)}
                className="mr-2"
              />
              <div className="mr-2 text-justify flex">
                {optionText}
                {question.correct_answers.includes(optionLabel) &&
                  isSubmitted && (
                    <span>
                      <Tick />
                    </span>
                  )}
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const Question = ({
  currentQuestionIndex,
  question,
  questionType,
  highlightText,
  highlightWord,
  statement,
  onAnswer,
  userAnswer,
  startSentence,
  isSubmitted,
}) => {
  if (
    questionType === "Single_MCQ" ||
    questionType === "Factual_Information" ||
    questionType === "Inference" ||
    questionType === "Rhetorical_Purpose"
  )
    return (
      <SingleMCQ
        currentQuestionIndex={currentQuestionIndex}
        question={question}
        onAnswer={onAnswer}
        userAnswer={userAnswer}
        isSubmitted={isSubmitted}
      />
    );
  else if (
    questionType === "Highlight_Text_Single_MCQ" ||
    questionType === "Sentence_Simplification"
  )
    return (
      <HighlightTextSingleMCQ
        currentQuestionIndex={currentQuestionIndex}
        question={question}
        onAnswer={onAnswer}
        userAnswer={userAnswer}
        highlightText={highlightText}
        isSubmitted={isSubmitted}
      />
    );
  else if (
    questionType === "Highlight_Word_Single_MCQ" ||
    questionType === "Vocabulary"
  )
    return (
      <HighlightWordSingleMCQ
        currentQuestionIndex={currentQuestionIndex}
        question={question}
        onAnswer={onAnswer}
        userAnswer={userAnswer}
        highlightWord={highlightWord}
        isSubmitted={isSubmitted}
      />
    );
  else if (
    questionType === "Insert_Text_MCQ" ||
    questionType === "Insert_Text"
  ) {
    return (
      <InsertTextMCQ
        currentQuestionIndex={currentQuestionIndex}
        question={question}
        onAnswer={onAnswer}
        userAnswer={userAnswer}
        statement={statement}
        isSubmitted={isSubmitted}
      />
    );
  } else if (questionType === "Summary_MCQ" || questionType === "Prose_Summary")
    return (
      <SummaryMCQ
        currentQuestionIndex={currentQuestionIndex}
        question={question}
        onAnswer={onAnswer}
        userAnswer={userAnswer}
        startSentence={startSentence}
        isSubmitted={isSubmitted}
      />
    );
  else return <div>error</div>;
};


export default Question;
