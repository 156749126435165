// src/NotFound.js
import React from "react";
import { NavLink } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <NavLink to="/dashboard">
            <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
          </NavLink>
        </div>
      </header>
      <main className="flex-grow flex items-center justify-center px-4 py-12">
        <div className="w-[30rem] space-y-8">
          <div className="bg-white rounded-lg shadow-md p-8">
                <div className="text-center">
                    <h1 className="text-6xl font-bold text-gray-800">404</h1>
                    <p className="text-xl text-gray-600 mt-4">Page Not Found</p>
                    <a
                    href="/"
                    className="mt-8 inline-block px-6 py-3 text-white bg-amber-400 rounded-lg hover:bg-amber-500"
                    >
                    Go Home
                    </a>
                </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NotFound;
