import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <Helmet>
        <title>Terms of Service - OpenStudyAI</title>
        <meta
          name="description"
          content="Read our Terms of Service to understand the rules and guidelines for using OpenStudyAI."
        />
      </Helmet>
      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-3xl font-bold text-teal-600">
            {" "}
            <Link to="/">OpenStudyAI</Link>
          </h1>
          <Link
            to="/"
            className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
          >
            Back
          </Link>
        </div>
      </header>{" "}
      <div className="w-3/4 md:w-2/4 h-full m-auto flex flex-col justify-center bg-white p-3 border rounded-lg ">
        <div className="mb-4">
          <h1 className="text-3xl font-bold text-gray-800 mb-2">
            Terms of Service
          </h1>
          <p className="text-gray-700 mb-4">
            By accessing or using the OpenStudyAI website, you agree to be bound
            by these Terms of Service and all applicable laws and regulations.
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-2 underline">
            Use of the Site
          </h2>
          <p className="text-gray-700 mb-4">
            You agree to use the site only for lawful purposes. You are
            prohibited from posting or transmitting any material that is
            unlawful, threatening, defamatory, obscene, or otherwise
            objectionable.
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-2 underline">
            Intellectual Property
          </h2>
          <p className="text-gray-700 mb-4">
            All content on the site, including text, graphics, logos, and
            images, is the property of OpenStudyAI and is protected by copyright
            and other intellectual property laws.
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-2 underline">
            Limitation of Liability
          </h2>
          <p className="text-gray-700 mb-4">
            In no event shall OpenStudyAI or its affiliates be liable for any
            indirect, incidental, special, or consequential damages arising out
            of the use of or inability to use the site.
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-2 underline">
            Governing Law
          </h2>
          <p className="text-gray-700 mb-4">
            These Terms of Service shall be governed by and construed in
            accordance with the laws of the jurisdiction in which OpenStudyAI
            operates.
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-2 underline">
            Contact Us
          </h2>
          <p className="text-gray-700 mb-4">
            If you have any questions about these Terms of Service, please
            contact us at:{" "}
            <a
              href="mailto:mockxbytechnoverse@gmail.com"
              className="text-teal-600 hover:underline"
            >
              mockxbytechnoverse@gmail.com
            </a>
          </p>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default TermsOfService;
