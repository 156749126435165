import React from 'react';

const AudioControls = ({ audioRef, isPlaying, handlePlay, handleAudioEnd, handleTimeUpdate, handleLoadedMetadata, progress, duration }) => (
  <div className="space-y-5 mb-2 mt-0">
    <button
      onClick={handlePlay}
      className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
    >
      {isPlaying ? 'Playing...' : 'Play Audio'}
    </button>
    <div className="time-remaining text-center text-gray-600 font-medium ">
      {Math.floor((duration * (100 - progress)) / 100)} seconds remaining
    </div>
    <div className="audio-progress-container bg-gray-200 rounded-full h-4 overflow-hidden ">
      <div
        className="audio-progress bg-blue-500 h-full transition-all duration-300 ease-out"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
    
    
  </div>
);

export default AudioControls;