import React from "react";

const Navigation = ({
  totalQuestions,
  currentIndex,
  onNavigate,
  onSubmit,
  isSubmitted,
  scores
}) => {
  const handleNext = () => {
    if (currentIndex < totalQuestions - 1) {
      onNavigate(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      onNavigate(currentIndex - 1);
    }
  };

  return (
    <div className="flex justify-between mt-6">
      <div>
        {isSubmitted && (
          <div>
            <button
              onClick={() => window.location.reload()}
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Retake Quiz
            </button>
            <button className="px-4 mx-1 py-2 bg-blue-500 text-white rounded">
              {" "}
              score: {scores}/11
            </button>
          </div>
        )}
      </div>
      <div>
        <button
          onClick={handlePrevious}
          disabled={currentIndex === 0}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
        >
          Previous
        </button>

        <button
          onClick={handleNext}
          disabled={currentIndex === 9}
          className="px-4 py-2 bg-blue-500 mx-1 text-white rounded disabled:opacity-50"
        >
          Next
        </button>

        {!isSubmitted&&<button
          onClick={onSubmit}
          className="px-4 py-2 bg-green-500 text-white rounded"
        >
          Submit
        </button>}
      </div>
    </div>
  );
};

export default Navigation;
