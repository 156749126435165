import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import '../../styles/Task.css';
import '../../styles/TranscriptAndGrade.css';
import AudioPlayer from './AudioPlayer';
import { useAtom } from "jotai";
import {
  questionAtom,
  attemptIndexAtom,
  answersAtom,
  scoreAtom,
} from "../../atom";
import getPersonalData from '../../GetPersonalData/GetpersonalData';

const TranscriptAndGrade = ({ setGradedDone, sampleResponse1, sampleResponse2, scrollContainerRef, audioUrl, responseTime }) => {
   const [questionNum, setQuestionNum] = useAtom(questionAtom);
   const [attemptIndex, setAttemptIndex] = useAtom(attemptIndexAtom);
   const [attemptAnswers, setAttemptAnswers] = useAtom(answersAtom);
   const [attemptScore, setAttemptScore] = useAtom(scoreAtom);
  const [transcript, setTranscript] = useState('');
  const [llmResponse, setLlmResponse] = useState('');
  const [s3URL,setS3URL]=useState();
  const taskNo = localStorage.getItem("OpenStudyAiSpeakingTaskNo") || "1";
  const [questionNumber, setQuestionNumber] = useState(localStorage.getItem("OpenStudyAiSpeakingExamNum") || '1');
  const [parsedResponse, setParsedResponse] = useState({});
  const [notEnoughWords, setNotEnoughWords] = useState(false);
  const [loading, setLoading] = useState(true);
  const [overallScore, setOverallScore] = useState(null);
  const [wordCount, setWordCount] = useState(0);
  const [audioURL,setAudioURL]=useState(audioUrl)
  const minimumWordCount = 25;

  const rubricTableRef = useRef(null);
  const sampleResponse1Ref = useRef(null);
  const sampleResponse2Ref = useRef(null);

  const countWords = useCallback((text) => {
    if (!text) return 0;
    const words = text.trim().split(/\s+/);
    return words.length;
  }, []);
  const calculateOverallScore = useCallback(async(parsed) => {
    const categories = ['Delivery', 'Language Use', 'Topic Development'];
    let totalScore = 0;
    let validScores = 0;

    categories.forEach(category => {
      if (parsed[category] && parsed[category].score !== undefined) {
        totalScore += parsed[category].score;
        validScores += 1;
      }
    });

    const averageScore = totalScore / validScores;
    const roundedScore = Math.round(averageScore);

    setOverallScore(roundedScore);
  }, []);

  const parseResponse = useCallback((response) => {
    const lines = response.split('\n').map(line => line.trim()).filter(Boolean);
    const parsed = {};
    let currentCategory = '';

    const categories = ['General Description', 'Delivery', 'Language Use', 'Topic Development'];

    lines.forEach(line => {
      const categoryMatch = categories.find(category => line.includes(category));
      if (categoryMatch) {
        currentCategory = categoryMatch;
        const scoreMatch = line.match(/(\d+)/);
        parsed[currentCategory] = { 
          score: scoreMatch ? parseFloat(scoreMatch[1]) : null, 
          explanation: '' 
        };
      } else if (currentCategory) {
        const cleanedLine = line.replace(/^\s*-\s*Explanation:\s*/, '');
        parsed[currentCategory].explanation += (parsed[currentCategory].explanation ? ' ' : '') + cleanedLine;
      } else if (line.toLowerCase().includes('overall')) {
        parsed['Overall'] = { explanation: line };
      }
    });

    if (Object.keys(parsed).length === 0) {
      parsed['Overall'] = { explanation: lines.join(' ') };
    }

    console.log('Parsed Response:', JSON.stringify(parsed, null, 2));

    setParsedResponse(parsed);
    calculateOverallScore(parsed);
  }, [calculateOverallScore]);


  const fetchTranscriptionAndResponse = useCallback(async (fileId) => {
    try {
      if(!questionNum)
      {const transcriptionResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/get-transcription-and-response`, { file_id: fileId });

      setS3URL(transcriptionResponse.data.s3_url);
      setTranscript(transcriptionResponse.data.transcription);
      setLlmResponse(transcriptionResponse.data.llm_response);
      setWordCount(countWords(transcriptionResponse.data.transcription));
    if (transcriptionResponse.data.llm_response === "Minimum words count not met. Try again.") {
        setNotEnoughWords(true);
      } else {
        parseResponse(transcriptionResponse.data.llm_response);
      }
    }
      else{
        setTranscript(attemptAnswers.transcript);
      setLlmResponse(attemptAnswers.llmResponse);
      setWordCount(countWords(attemptAnswers.transcript))
      setAudioURL(attemptAnswers.audioUrl)
      if (attemptAnswers.llmResponse === "Minimum words count not met. Try again.") {
        setNotEnoughWords(true);
      } else {
        parseResponse(attemptAnswers.llmResponse);
      }
    }
    } catch (error) {
      console.error('Error:', error);
    }
    setLoading(false);
    setGradedDone(true);
  }, [countWords, parseResponse, setTranscript, setLlmResponse, setWordCount, setNotEnoughWords, setLoading, setGradedDone]);

  const saveScores=async()=>{
    const getEmail = await getPersonalData();
      const currentDateTime = new Date().toISOString();
      const responseSave = {
        transcript: transcript,
        audioUrl: s3URL,
        llmResponse: llmResponse,
      };
      const newtry = {
        Answer: responseSave,
        score: overallScore,
        timestamp: currentDateTime,
      };
      const data = {
        Email: getEmail.email,
        Section: `TOEFL#speakingTask${taskNo}#${questionNumber}`,
        Score: overallScore,
        NewTry: newtry,
      };
      if (!questionNum) {
        try {
          // Send POST request to the Flask backend
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/send-speaking-response`,
            data, // Include the formatted data as the request body
            {
              headers: {
                "Content-Type": "application/json", // Ensure correct content type
              },
            }
          );
          // Handle successful response
          console.log("Response:", response.data);
        } catch (error) {
          // Handle errors if the request fails
          console.error("Error sending data:", error);
        }
      }
  }
  useEffect(() => {
  // Call saveScores only when all required data is available
  if (transcript && s3URL && llmResponse && overallScore !== null) {
    saveScores();
  }
}, [transcript, s3URL, llmResponse, overallScore]); // Run the effect whenever these values change
  useEffect(() => {
    const intervalId = setInterval(() => {
      const fileId = localStorage.getItem('FileIDTask1');
      console.log("TranscriptAndGrade fileid: ", fileId);

      if (fileId) {
        console.log("VALID: TranscriptAndGrade fileid: ", fileId);
        fetchTranscriptionAndResponse(fileId);
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [fetchTranscriptionAndResponse]);

  const scrollToRubricTable = () => {
    if (rubricTableRef.current) {
      const yOffset = -15;
      const rect = rubricTableRef.current.getBoundingClientRect();
      const containerRect = scrollContainerRef.current.getBoundingClientRect();
      const y = rect.top - containerRect.top + scrollContainerRef.current.scrollTop + yOffset;

      scrollContainerRef.current.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    }
  };

  const scrollToSampleResponse1 = () => {
    if (sampleResponse1Ref.current && scrollContainerRef.current) {
      const yOffset = -15;
      const rect = sampleResponse1Ref.current.getBoundingClientRect();
      const containerRect = scrollContainerRef.current.getBoundingClientRect();
      const y = rect.top - containerRect.top + scrollContainerRef.current.scrollTop + yOffset;

      scrollContainerRef.current.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    } else {
      console.log('sampleResponse1Ref.current or scrollContainerRef.current is not defined');
    }
  };

  const scrollToSampleResponse2 = () => {
    if (sampleResponse2Ref.current && scrollContainerRef.current) {
      const yOffset = -10;
      const containerRect = scrollContainerRef.current.getBoundingClientRect();
      const rect = sampleResponse2Ref.current.getBoundingClientRect();
      const y = rect.top - containerRect.top + scrollContainerRef.current.scrollTop + yOffset;

      scrollContainerRef.current.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    }
  };



  return (
    <div>
      {loading && <div className="loading-screen"><div className="loading-spinner"></div><p>Grading in progress... <br></br>Please wait before moving on to the next task</p></div>}
      {!loading && (
        <div className="instructions-container">
          <h2 className="section-title">Transcript</h2>
          <p className="transcript">
            {transcript ? transcript : 'Transcript not available. Please try again.'}
          </p>
          <p className="pt-1 text-right text-xs">Word Count: {wordCount}</p>
          {audioURL && (
            <div className="audio-container">
              {console.log("transcript and grade", responseTime)}
            <AudioPlayer audioSrc={audioURL} defaultDuration={responseTime}/>  
            </div> 
          )}
          {console.log("audioUrl: ", audioURL)}
        </div>
      )}
      {llmResponse && (
        <div className="instructions-container relative " ref={rubricTableRef}>
          <button className="scrollButtonResponses" onClick={scrollToSampleResponse1}>Sample Response 1 &#x2193; </button> 

          <h2 className="section-title">Rubric and Scores</h2>
          {!notEnoughWords ? (
            <>
              {overallScore !== null && (
                <div className="rubric-title">
                  <h3>Overall Score: {overallScore}/4</h3>
                </div>
              )}
              <table className="rubric-table" >
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Score and Explanation</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(parsedResponse).map((key) => (
                    <tr key={key}>
                      <td className="rubric-title">{key}</td>
                      <td className="rubric-details">
                        <p className="rubric-score">Score: {parsedResponse[key].score}/4</p>
                        <p className="rubric-explanation"><b>Explanation:</b> {parsedResponse[key].explanation}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <p>Minimum word count not met. Please respond with at least <strong>{minimumWordCount} words</strong> and try again. Thank you!</p>
          )}
          
        </div>
      )}

      
          <div className="instructions-container relative" ref={sampleResponse1Ref}>
          <button className="scrollButtonResponses" onClick={scrollToSampleResponse2}>Sample Response 2 &#x2193; </button> 
            <h2 className="section-title">Sample Response 1</h2>
            <p className="transcript">{sampleResponse1}</p>
          </div>

          <div className="instructions-container" ref={sampleResponse2Ref}>
            <h2 className="section-title">Sample Response 2</h2>
            <p className="transcript">{sampleResponse2}</p>
          </div>
      {!loading && (<button className="scrollButtonRubric" onClick={scrollToRubricTable}>Scroll to Rubric &#x2193; </button>)}

      {/*<button className="scrollButton" onClick={scrollToSampleResponse1}>Scroll to Sample Response 1 &#x2193; </button>*/}
    </div>
  );
};

export default TranscriptAndGrade;
