import React, { useState ,useEffect} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { CiMenuBurger } from "react-icons/ci";
import { FaDiscord } from "react-icons/fa";
import { IoPersonCircleOutline } from "react-icons/io5";

const Logout = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 640);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const handleLogout = () => {
    localStorage.removeItem("OpenStudyAi-token");
    localStorage.removeItem("OpenStudyAi-hasVisited");
    navigate("/login");
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640);
      if (window.innerWidth >= 640) {
        setDropdownOpen(false); // Close dropdown on larger screens
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  return (
    <div className="relative">
      {/* Button for small screens */}
      <button
        onClick={toggleDropdown}
        className=" sm:hidden bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200 flex items-center"
      >
        <CiMenuBurger className="text-bold" />
        <svg
          className={`w-4 h-4 ml-2 transition-transform ${
            isDropdownOpen ? "rotate-180" : "rotate-0"
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>

      {/* Dropdown menu for small screens */}
      {isDropdownOpen && (
        
        <div
          className="absolute right-0 mt-2 w-48 bg-white text-gray-900 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 z-10"
          // onClick={closeDropdown}
        >
          <div className="relative">
            <button
              onClick={() => setIsDropdownOpen1((prev) => !prev)}
              className=" text-gray-700  hover:bg-teal-600 block px-4 py-2 text-sm w-full transition duration-200 rounded-t-lg"
            >
              Coursework
            </button>
            {isDropdownOpen1 && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                <NavLink
                  to="/courses/toefl"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                  onClick={() => setIsDropdownOpen1(false)}
                >
                  TOEFL
                </NavLink>
              </div>
            )}
          </div>
          <a
            href="https://forms.gle/BopWYfDohLpAdtxj9"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="block w-full px-4 py-2 text-left text-md hover:bg-amber-300  " onClick={closeDropdown}>
              Feedback
            </button>
          </a>
          <a
            href="https://discord.gg/JFTwXm2Rgt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className=" w-full px-4 py-2 text-left text-md hover:bg-[#5865F2] flex items-center gap-2" onClick={closeDropdown}>
              <FaDiscord /> <div>Join Discord</div>
            </button>
          </a>
          <NavLink
            to="/profile"
            className=" w-full px-4 py-2 text-left text-md hover:bg-amber-300 flex items-center gap-2 transition duration-200"
          >
            <IoPersonCircleOutline />Profile
          </NavLink>
          <button
            onClick={handleLogout}
            className="block w-full px-4 py-2 text-left text-md hover:bg-amber-300  rounded-b-lg "
          >
            Logout
          </button>
          
        </div>
      )}

      {/* Buttons for larger screens */}
    
      <div className="hidden sm:flex gap-2 items-center">
        <div className="relative">
          <button
            onClick={() => setIsDropdownOpen1((prev)=>!prev)}
            className="bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600 transition duration-200 mr-4"
          >
            Coursework
          </button>
          {isDropdownOpen1 && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
              <NavLink
                to="/courses/toefl"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                onClick={() => setIsDropdownOpen1(false)}
              >
                TOEFL
              </NavLink>
            </div>
          )}
        </div>
        
        <a
          href="https://forms.gle/BopWYfDohLpAdtxj9"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200">
            Feedback
          </button>
        </a>
        <a
          href="https://discord.gg/JFTwXm2Rgt"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="bg-[#6b75ec] text-white px-4 py-2 rounded-md hover:bg-[#535ee0] transition duration-200 flex items-center gap-2">
            <FaDiscord /> <div>Join Discord</div>
          </button>
        </a>
        <NavLink
          to="/profile"
          className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200 flex items-center gap-2"
        >
          <IoPersonCircleOutline/>Profile
        </NavLink>
        <button
          onClick={handleLogout}
          className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Logout;

//     const [isDropdownOpen, setIsDropdownOpen] = useState(false);

// const toggleDropdown = () => {
//   setIsDropdownOpen(!isDropdownOpen);
// };

// const closeDropdown = () => {
//   setIsDropdownOpen(false);
// };
// {
  /* <div className="relative">
          <button
            onClick={toggleDropdown}
            className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200 flex items-center"
          >
            <span className="mr-2">Profile</span>
            <svg
              className={`w-4 h-4 transition-transform ${
                isDropdownOpen ? "rotate-180" : "rotate-0"
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>
          {isDropdownOpen && (
            <div
              className="absolute right-0 mt-2 w-48 bg-gray-200 text-gray-900 rounded-lg shadow-lg"
              onClick={closeDropdown}
            >
              <NavLink
                to="/profile"
                className="block px-4 py-2 text-sm hover:bg-gray-100 rounded-lg"
              >
                Profile
              </NavLink>
              <button
                onClick={handleLogout}
                className="block w-full px-4 py-2 text-left text-sm hover:bg-gray-100 rounded-lg"
              >
                Logout
              </button>
            </div>
          )}
          </div> */

