import React, { useContext, useEffect, useRef, useState } from 'react';
import '../../styles/AudioTester.css';
import { AudioContext } from './AudioContext';

const AudioTester = React.forwardRef((props, ref) => {
    const { selectedDevice, setSelectedDevice, audioDevices, setAudioDevices } = useContext(AudioContext);
    const [audioBlob, setAudioBlob] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [streamReady, setStreamReady] = useState(false);
    const [permissionStatus, setPermissionStatus] = useState('prompt');
    const [isAudioAvailable, setIsAudioAvailable] = useState(false);
    const [isMicrophoneReady, setIsMicrophoneReady] = useState(false);

    const audioRef = useRef(null);
    const recorderRef = useRef(null);
    const streamRef = useRef(null);
    const analyzerRef = useRef(null);
    const animationFrameRef = useRef(null);
    const canvasRef = useRef(null);
    const unavailableCanvasRef = useRef(null);
    const chunks = useRef([]);
    const mimeType = 'audio/webm';

    useEffect(() => {
        drawUnavailableCanvas();
        listAudioDevices();

        return () => {
            cleanupAudio();
        };
    }, []);
    useEffect(() => {
        listAudioDevices();
    }, [permissionStatus]);

    useEffect(() => {
        if (selectedDevice) {
            setupAudioStream();
        }
    }, [selectedDevice]);

    const cleanupAudio = () => {
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
            streamRef.current = null;
        }
        if (recorderRef.current && recorderRef.current.state !== 'inactive') {
            recorderRef.current.stop();
            recorderRef.current = null;
        }
        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
        }
        setStreamReady(false);
        setAudioBlob(null);
        setIsRecording(false);
    };

    React.useImperativeHandle(ref, () => ({
        cleanup: cleanupAudio
    }));

    const listAudioDevices = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioInputDevices = devices.filter(device => {
                // Filter for audio input devices that are likely physical
                if (device.kind === 'audioinput') {
                    const label = device.label.toLowerCase();
                    // Exclude devices that contain virtual/VoIP software keywords
                    if (
                        label.includes('virtual') ||
                        label.includes('voicemeter') ||
                        label.includes('stereo mix') ||
                        label.includes('loopback') ||
                        label.includes('vb-audio') ||
                        label.includes('camo') ||
                        label.includes('droidcam') ||
                        label.includes('obs') ||
                        label.includes('nvidia broadcast') ||
                        label.includes('blackhole') ||
                        label.includes('soundflower') ||
                        label.includes('ishowu') ||
                        label.includes('screenflick') ||
                        label.includes('voip') ||
                        label.includes('wirecast') ||
                        label.includes('zoom') ||
                        label.includes('skype') ||
                        label.includes('teams') ||
                        label.includes('discord') ||
                        label.includes('slack') ||
                        label.includes('webex') ||
                        label.includes('meeting') ||
                        label.includes('dummy') ||
                        label.includes('voicemeeter') ||
                        label.includes('null') 
                    ) {
                        return false;
                    }
                    return true;
                }
                return false;
            });
            
            console.log('Filtered physical audio input devices:', audioInputDevices);
            setAudioDevices(audioInputDevices);
            
            if (audioInputDevices.length > 0) {
                setSelectedDevice(audioInputDevices[0].deviceId);
            }
        } catch (error) {
            console.error('Error listing audio devices:', error);
            setPermissionStatus('error');
        }
    };

    const setupAudioStream = async () => {
        try {
            if (streamRef.current) {
                cleanupAudio();
            }
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: { deviceId: selectedDevice ? { exact: selectedDevice } : undefined }
            });
            streamRef.current = stream;
            setupAudioAnalyzer(stream);
            setStreamReady(true);
            setPermissionStatus('granted');
        } catch (error) {
            console.error('Error accessing microphone', error);
            setStreamReady(false);
            setPermissionStatus('error');
        }
    };

    const startRecording = () => {
        if (!streamReady || !streamRef.current) {
            console.error('Media stream is not ready.');
            return;
        }

        try {
            const options = { mimeType };
            if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                options.mimeType = 'audio/mp4';
            }
            const recorder = new MediaRecorder(streamRef.current, options);

            recorder.ondataavailable = (event) => {
                chunks.current.push(event.data);
            };

            recorder.onstop = () => {
                const blob = new Blob(chunks.current, { type: options.mimeType });
                setAudioBlob(blob);
                chunks.current = [];
            };

            recorder.start();
            recorderRef.current = recorder;
            setIsRecording(true);
        } catch (error) {
            console.error('Error starting recording', error);
        }
    };

    const setupAudioAnalyzer = (stream) => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContext.createMediaStreamSource(stream);
        const analyzer = audioContext.createAnalyser();
        analyzer.fftSize = 512;
        source.connect(analyzer);
        analyzerRef.current = analyzer;

        setIsMicrophoneReady(true);


        const updateAudioData = () => {
            if (!analyzerRef.current) return;
            const dataArray = new Uint8Array(analyzer.frequencyBinCount);
            analyzer.getByteFrequencyData(dataArray);
            
            // Check if audio is available
            const audioAvailable = dataArray.some(value => value > 0);
            setIsAudioAvailable(audioAvailable);

            if (audioAvailable) {
                drawWaveform(dataArray);
            }
            
            
            
            animationFrameRef.current = requestAnimationFrame(updateAudioData);
        };

        updateAudioData();
    };

    const drawWaveform = (dataArray) => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const canvasCtx = canvas.getContext('2d');
        const WIDTH = canvas.width;
        const HEIGHT = canvas.height;
        const bufferLength = dataArray.length;
        const barWidth = (WIDTH / bufferLength) * 1.25;
        const centerY = HEIGHT / 2;

        canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

        for (let i = 0; i < bufferLength; i++) {
            const barHeight = ((dataArray[i] / 255) * HEIGHT) / 2;
            canvasCtx.fillStyle = '#4CAF50';
            canvasCtx.fillRect(i * barWidth, centerY - barHeight / 2, barWidth - 1, barHeight);
        }
    };

    const drawUnavailableCanvas = () => {
        const canvas = unavailableCanvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        
        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        
        // Set background color
        ctx.fillStyle = '#f0f0f0';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        
        // Draw text
        ctx.font = '16px Arial';
        ctx.fillStyle = '#333';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('no audio, try speaking', canvas.width / 2, canvas.height / 2);
    };



    const stopRecording = () => {
        if (recorderRef.current && isRecording) {
            recorderRef.current.stop();
            setIsRecording(false);
        }
    };

    const requestMicrophoneAccess = () => {
        setPermissionStatus('prompt'); // Reset status to prompt for permission again
        setupAudioStream();
    };

    useEffect(() => {
        if (audioBlob && audioRef.current) {
            const audioUrl = URL.createObjectURL(audioBlob);
            audioRef.current.src = audioUrl;
            audioRef.current.load();
        }
    }, [audioBlob]);

    return (
        <div className="audio-test-container">
            <h3 className="audio-test-header"><strong>Test your audio before starting task!</strong></h3>

            {permissionStatus === 'prompt' && (
                <div className="permission-prompt">
                    <p>This application requires access to your microphone. Please click the button below to grant permission.</p>
                    {/*<button onClick={requestMicrophoneAccess} className="audio-test-button">
                        Grant Microphone Access
                    </button> */}
                </div>
            )}

            {permissionStatus === 'denied' && (
                <div className="permission-denied">
                    <p>Microphone access was denied. Please enable microphone access in your browser settings and refresh the page.</p>
                </div>
            )}

            {permissionStatus === 'unavailable' && (
                <div className="microphone-unavailable">
                    <p>No microphone was found on this device. Please connect a microphone and refresh the page.</p>
                </div>
            )}

            {permissionStatus === 'unsupported' && (
                <div className="browser-unsupported">
                    <p>Your browser does not support microphone access. Please use a modern browser that supports getUserMedia.</p>
                </div>
            )}

            {permissionStatus === 'error' && (
                <div className="general-error">
                    <p>An error occurred while trying to access the microphone. Please refresh the page and try again.</p>
                </div>
            )}

            {permissionStatus === 'granted' && (
                <>
                    <div className="select-microphone-div">
                        <label htmlFor="device-select" className="font-medium">Select microphone: </label>
                        <select
                            id="device-select"
                            value={selectedDevice}
                            onChange={(e) => setSelectedDevice(e.target.value)}
                        >
                            {audioDevices.map((device) => (
                                <option key={device.deviceId} value={device.deviceId}>
                                    {device.label || `Device ${device.deviceId}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="waveform-container">
                        {isAudioAvailable || isMicrophoneReady ? (
                        <canvas 
                            ref={canvasRef} 
                            className="audio-waveform" 
                            width="600" 
                            height="100" 
                            style={{ display: isAudioAvailable ? 'block' : 'none' }}
                        /> 

                        ) : (
                        
                        <p className='mt-12 pb-10 text-gray-600 text-xl font-semibold'>Audio is loading.. </p>
                        )}
                        </div>

                        {isMicrophoneReady && !isAudioAvailable && (
                             <p className='mt-12 pb-10 text-gray-600 text-xl font-semibold'>Empty Audio Feed. Try Speaking.. </p>

                        )}
                    <button
                        className="audio-test-button"
                        onClick={startRecording}
                        disabled={isRecording || !streamReady || audioBlob}
                    >
                        Start Recording
                    </button>
                    <button
                        className="audio-test-button"
                        onClick={stopRecording}
                        disabled={!isRecording}
                    >
                        Stop Recording
                    </button>
                    {audioBlob && (
                        <div>
                            <audio className="audio-test-audio" ref={audioRef} controls />
                            <button
                                className="audio-test-button audio-test-record-again-button"
                                onClick={() => setAudioBlob(null)}
                            >
                                Record Again
                            </button>
                        </div>
                    )}
                </>
            )}
            <button className="audio-test-button" onClick={requestMicrophoneAccess} disabled={(permissionStatus==='granted')}>
                Grant Microphone Access Again
            </button>
        </div>
    );
});

export default AudioTester;