import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Select from 'react-select';
import {countryOptions} from './Countries'

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    birthdate: '',
    gender: '',
    city: '',
    country: '',
  });
  const [error, setError] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    if (e.target) {
      // Handle text inputs
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      country: selectedOption ? selectedOption.value : "", // Update the country field with the selected value or an empty string if cleared
    });
  };
  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      console.log('Attempting to register with:', formData);
      console.log('API URL:', process.env.REACT_APP_API_URL);
      const fullUrl = `/api/register`;
      console.log('Full request URL:', fullUrl);
      const response = await axios.post(fullUrl, formData);
      console.log('Registration response:', response.data);
      if (response.data.status === 'success') {
        setIsConfirmation(true);
      } else {
        setError(response.data.message || 'Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Registration error:', error);
      if (error.response) {
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      setError(error.response?.data?.message || error.message || 'An error occurred. Please try again.');
    }
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/api/confirm-registration`, {
        email: formData.email,
        code: confirmationCode,
      });
      if (response.data.status === 'success') {
        alert('Registration confirmed successfully. You can now log in.');
        navigate("/login");
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error('Confirmation error:', error);
      setError(error.response?.data?.message || error.message || 'An error occurred. Please try again.');
    }
  };

  if (isConfirmation) {
    return (
      <div>
        <Helmet>
          <title>Confirmation</title>
          <meta
            name="description"
            content="Register for Master TOEFL with OpenStudyAI—gain access to personalized learning, adaptive practice sessions, and instant feedback, all powered by advanced artificial intelligence to elevate your TOEFL preparation."
          />
          <meta
            property="og:title"
            content="Log in for Master TOEFL with OpenStudyAI"
          />
          <meta
            property="og:description"
            content="Register for Master TOEFL with OpenStudyAI—gain access to personalized learning, adaptive practice sessions, and instant feedback, all powered by advanced artificial intelligence to elevate your TOEFL preparation."
          />
          <meta
            name="twitter:title"
            content="Log in for Master TOEFL with OpenStudyAI"
          />
          <meta
            name="twitter:description"
            content="Register for Master TOEFL with OpenStudyAI—gain access to personalized learning, adaptive practice sessions, and instant feedback, all powered by advanced artificial intelligence to elevate your TOEFL preparation."
          />
          <link rel="canonical" href="/register" />
        </Helmet>
        <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
          <header className="bg-white p-4 shadow-md flex justify-center items-center">
            <NavLink to="/">
              <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
            </NavLink>
          </header>
          <main className="flex-grow flex items-center justify-center px-4 py-12">
            <div className="w-full max-w-md space-y-8">
              <div className="bg-white rounded-lg shadow-md p-8">
                <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
                  Confirm Registration
                </h2>
                <form onSubmit={handleConfirm} className="space-y-6">
                  <div className="relative">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Enter Verification code received through email
                    </label>
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      autoComplete="current-password"
                      required
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={confirmationCode}
                      onChange={(e) => setConfirmationCode(e.target.value)}
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 top-[20px] pr-3 flex items-center text-sm leading-5"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </button>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-400 hover:bg-amber-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                    >
                      Confirm
                    </button>
                  </div>
                </form>
                {error && (
                  <p className="mt-2 text-center text-sm text-red-600">
                    {error}
                  </p>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <Helmet>
        <title>Register</title>
        <meta
          name="description"
          content="Register for Master TOEFL with OpenStudyAI—gain access to personalized learning, adaptive practice sessions, and instant feedback, all powered by advanced artificial intelligence to elevate your TOEFL preparation."
        />
        <link rel="canonical" href="/register" />
      </Helmet>
      <header className="bg-white p-4 shadow-md flex justify-center items-center">
        <NavLink to="/">
          <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
        </NavLink>
      </header>
      <main className="flex-grow flex items-center justify-center px-4 py-12">
        <div className="w-full max-w-md space-y-8">
          <div className="bg-white rounded-lg shadow-md p-8">
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
              Register
            </h2>
            <form onSubmit={handleRegister} className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="relative">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={formData.password}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 top-[20px] pr-3 flex items-center text-sm leading-5"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  autoComplete="name"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="birthdate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Birthdate
                </label>
                <input
                  id="birthdate"
                  name="birthdate"
                  type="date"
                  autoComplete="birthdate"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={formData.birthdate}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="gender"
                  className="block text-sm font-medium text-gray-700"
                >
                  Gender
                </label>
                <input
                  id="gender"
                  name="gender"
                  type="text"
                  autoComplete="gender"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={formData.gender}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  City
                </label>
                <input
                  id="city"
                  type="text"
                  name="city"
                  autoComplete="city"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Country
                </label>
                <Select
                  id="country"
                  name="country"
                  options={countryOptions}
                  value={countryOptions.find(
                    (option) => option.value === formData.country
                  )}
                  onChange={handleSelectChange}
                  placeholder="Select a country"
                  isClearable
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-400 hover:bg-amber-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                >
                  Register
                </button>
              </div>
            </form>
            <div>
              <NavLink
                to="/login"
                className="flex justify-center py-4 px-4 hover:text-amber-500"
              >
                Already have an account? Login
              </NavLink>
            </div>
            {error && (
              <p className="mt-2 text-center text-sm text-red-600">{error}</p>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default RegisterPage;
