import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import LoginPage from './Login/LoginPage';
import RegisterPage from './Login/RegisterPage';
import Dashboard from './LandingPages/Dashboard';
import Reading from './Sections/Reading';
import Writing from './Sections/Writing';
import ReadingLandingPage from './Reading/ReadingLandingPage'
import Speaking from './Sections/Speaking';
import SpeakingLandingPage from './Speaking/SpeakingLandingPage';
import SpeakingTask from './Speaking/SpeakingTask';
import ListeningLandingPage from './Listening/ListeningLandingPage';
import ListeningExercise from './Listening/ListeningExercise'; 
import ConversationNumberOfQuestions from './Listening/ConversationNumberOfQuestions';
import LectureNumberOfQuestions from './Listening/LectureNumberOfQuestions';
import ConversationExercise from './Listening/ConversationExercise'; // Add this import
import LectureExercise from './Listening/LectureExercise'; // Add this import
import AuthRoute from './Auth/AuthRoute';
import AuthRouteLogin from './Auth/AuthRouteLogin';
import WritingAcademic from './Writing/WritingAcademic'
import WritingAcademicQuestions from './Writing/WritingAcadamicQuestions';
import { AudioProvider } from './Speaking/Tasks/AudioContext';
import NotFound from './NotFound';
import IntegratedWritingQuestions from './Writing/IntegratedWriting/IntegratedWritingQuestions';
import IntegratedWriting from './Writing/IntegratedWriting/IntegratedWriting';
import InstructionsPage from './Writing/IntegratedWriting/InstructionPage';
import { ExerciseProvider } from './Listening/ExerciseContext'; // Import the provider

import WritingAttempts from './Grading/WritingAttempts';
import DisplayAttempts from './Grading/displayWritingAttempts/DisplayAttempts';
import ContactPage from "./Footercontent/Contacts";
import PrivacyPolicy from './Footercontent/PrivacyandPolicy';
import TermsOfService from './Footercontent/TermsandServices';

// Import TOEFL course components
import TOEFLCoursework from './courses/toefl/TOEFLCoursework';
import Introduction from './courses/toefl/pages/Introduction';
import GeneralStrategies from './courses/toefl/pages/GeneralStrategies';
import ReadingSection from './courses/toefl/pages/ReadingSection';
import ListeningSection from './courses/toefl/pages/ListeningSection';
import SpeakingSection from './courses/toefl/pages/SpeakingSection';
import WritingSection from './courses/toefl/pages/WritingSection';
import FinalPreparation from './courses/toefl/pages/FinalPreparation';

import ReadingAttempts from "./Grading/ReadingAttempts";
import SpeakingAttempts from './Grading/SpeakingAttempts';
import Gradings from './Profile/Gradings';
import DisplaySpeakingAttempts from './Grading/displayWritingAttempts/DisplaySpeakingAttempts';
import ForgotPassword from './PasswordReset/ForgorPassword';
function App() {
  return (
    <AudioProvider>
      <Router>
        <div className="App">
        <ExerciseProvider>
          <Routes>
            <Route element={<AuthRouteLogin />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/forgotpassword" element={<ForgotPassword/>}/>
              <Route path="/register" element={<RegisterPage />} />
            </Route>
            <Route element={<AuthRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/reading" element={<Reading />} />
              <Route
                path="/reading-landing-page"
                element={<ReadingLandingPage />}
              />
              <Route path="/writing" element={<Writing />} />
              <Route
                path="/academic-discussion"
                element={<WritingAcademicQuestions />}
              />
              <Route
                path="/academic-discussion-task"
                element={<WritingAcademic />}
              />
              <Route
                path="/integrated-writing"
                element={<IntegratedWritingQuestions />}
              />
              <Route
                path="/integrated-writing-instructions"
                element={<InstructionsPage />}
              />
              <Route
                path="/integrated-writing-task"
                element={<IntegratedWriting />}
              />
              <Route path="/writing-attempts" element={<WritingAttempts />} />
              <Route
                path="/writing-attempt-questions"
                element={<DisplayAttempts />}
              />

              <Route path="/Speaking" element={<Speaking />} />

              <Route
                path="/SpeakingLandingPage"
                element={<SpeakingLandingPage />}
              />
              <Route
                path="/speakingTask"
                element={<SpeakingTask />}
              />
              <Route path="/listening" element={<ListeningLandingPage />} />
              <Route path="/listening-exercise" element={<ListeningExercise />} />
              <Route path="/listening/conversation-questions" element={<ConversationNumberOfQuestions />} />
              <Route path="/listening/lecture-questions" element={<LectureNumberOfQuestions />} />
              <Route path="/listening/conversation/:id" element={<ConversationExercise />} />
              <Route path="/listening/lecture/:id" element={<LectureExercise />} /> 

              <Route path="/speakingTask" element={<SpeakingTask />} />
              <Route path="/speakingAttempts" element={<SpeakingAttempts />} />
              <Route path="/displaySpeakingAttempts" element={<DisplaySpeakingAttempts />} />

              <Route path="/reading-attempts" element={<ReadingAttempts />} />
              <Route path="/profile" element={<Gradings />} />
            </Route>

            {/* TOEFL Course Routes */}
            <Route path="/courses/toefl" element={<TOEFLCoursework />} />
            <Route path="/courses/toefl/introduction" element={<Introduction />} />
            <Route path="/courses/toefl/strategies" element={<GeneralStrategies />} />
            <Route path="/courses/toefl/reading" element={<ReadingSection />} />
            <Route path="/courses/toefl/listening" element={<ListeningSection />} />
            <Route path="/courses/toefl/speaking" element={<SpeakingSection />} />
            <Route path="/courses/toefl/writing" element={<WritingSection />} />
            <Route path="/courses/toefl/final-prep" element={<FinalPreparation />} />

            {/* Other routes */}
            <Route path="/contacts" element={<ContactPage />}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
            <Route path="/terms-services" element={<TermsOfService />}/>

            {/* Catch-all route for 404 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
          </ExerciseProvider>
        </div>
      </Router>
    </AudioProvider>
  );
}

export default App;
