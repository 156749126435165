import React from 'react';
import MultipleChoiceQuestion from './questions/MultipleChoiceQuestion';
import TwoChoiceQuestion from './questions/TwoChoiceQuestion';
// Import other question types here

const Question = ({
  question,
  currentQuestionIndex,
  userAnswers,
  handleAnswer,
  submissionResult,
  handleNextQuestion,
  handlePreviousQuestion,
  isLastQuestion,
  handleSubmit,
  handleRetake,
}) => {
  if (!question) return null;

  // Function to render the appropriate component based on question type
  const renderQuestionComponent = () => {
    switch (question.question_type) { 
      case 'lecture_q':
      case 'multiple_choice':        
        return (
          <MultipleChoiceQuestion
            question={question}
            userAnswers={userAnswers}
            handleAnswer={handleAnswer}
            submissionResult={submissionResult}
            currentQuestionIndex={currentQuestionIndex}
          />
        );
      case 'two_choice':
        return (
          <TwoChoiceQuestion
            question={question}
            userAnswers={userAnswers}
            handleAnswer={handleAnswer}
            submissionResult={submissionResult}
            currentQuestionIndex={currentQuestionIndex}
          />
        );
      // Add cases for other question types
      default:
        return <p>Unknown question type</p>;
    }
  };

  return (
    <div className="mt-8">
      <h3 className="text-2xl font-bold mb-6 text-gray-800">
        Question {currentQuestionIndex + 1}
      </h3>
      <p className="text-xl mb-6 text-gray-900 font-medium">{question.question_text}</p>
      {question.question_convo_transcript && <p className="text-lg mb-6 text-gray-900 ">{question.question_convo_transcript}</p>}

      {renderQuestionComponent()} {/* Render specific question component */}

      {/* Rest of the component logic like Next/Previous buttons */}
      <div className="flex justify-between mt-8">
        <button
          onClick={handlePreviousQuestion}
          disabled={currentQuestionIndex === 0}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-6 rounded-lg transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Previous
        </button>
        {isLastQuestion ? (
          submissionResult ? (
            <button
              onClick={handleRetake}
              className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-6 rounded-lg transition duration-300 ease-in-out"
            >
              Retake Quiz
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded-lg transition duration-300 ease-in-out"
            >
              Submit Test
            </button>
          )
        ) : (
          <button
            onClick={handleNextQuestion}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg transition duration-300 ease-in-out"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default Question;