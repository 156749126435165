import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import '../../styles/AudioPlayer.css';

const AudioPlayer = ({ audioSrc, defaultDuration }) => {
  const audioElement = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);

  // Keep track of the animation frame
  const animationFrameId = useRef(null);

  useEffect(() => {
    const updateProgress = () => {
      // Ensure audioElement.current is defined before accessing its properties
      if (audioElement.current) {
        setCurrentTime(audioElement.current.currentTime);
        if (audioElement.current.duration && audioElement.current.duration < Infinity){
            setDuration(audioElement.current.duration); 
        } 
        else{
            console.log("def", defaultDuration);
            setDuration(defaultDuration);
        }
        //setDuration(audioElement.current.duration);
        
        if (isPlaying && audioElement.current.currentTime < audioElement.current.duration) {
          animationFrameId.current = requestAnimationFrame(updateProgress);
        }
      }
    };

    if (isPlaying && audioElement.current) {
      animationFrameId.current = requestAnimationFrame(updateProgress);
    }

    return () => cancelAnimationFrame(animationFrameId.current); // Cleanup on unmount
  }, [isPlaying]);

  useEffect(() => {
    console.log('duration: ', duration, audioElement.current.duration);
    }, [duration]);

  const togglePlayPause = () => {
    if (audioElement.current) {
      if (isPlaying) {
        audioElement.current.pause();
        cancelAnimationFrame(animationFrameId.current); // Stop updating when paused
      } else {
        audioElement.current.play();
        animationFrameId.current = requestAnimationFrame(() => {
          setCurrentTime(audioElement.current.currentTime);
          setDuration(audioElement.current.duration);
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMute = () => {
    if (audioElement.current) {
      setIsMuted(!isMuted);
      audioElement.current.muted = !audioElement.current.muted;
    }
  };

  const handleProgressChange = (e) => {
    const newTime = e.target.value;
    if (audioElement.current) {
      audioElement.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    if (audioElement.current) {
      setVolume(newVolume);
      audioElement.current.volume = newVolume;
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className="audio-player">
      {/* Play/Pause Button */}
      <button onClick={togglePlayPause} className="control-button">
        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
      </button>

      {/* Progress Bar */}
      <div className="progress-container">
        <span className="audio-player-span">{formatTime(currentTime)}</span>
        <input
          type="range"
          className="progress-bar"
          min="0"
          max={duration || 0}
          value={currentTime}
          step="0.01" // Step set to a small value to make the progress bar smoother
          onChange={handleProgressChange}
        />
        <span className="audio-player-span">{formatTime(duration)}</span>
      </div>

      {/* Volume Control */}
      <div className="volume-container">
        <button onClick={toggleMute} className="control-button">
          <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
        </button>
        <input
          type="range"
          className="volume-bar"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
        />
      </div>

      <audio ref={audioElement} src={audioSrc}></audio>
    </div>
  );
};

export default AudioPlayer;