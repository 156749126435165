import React from 'react';
import CourseContentLayout from '../components/CourseContentLayout';

const Introduction = () => {
  return (
    <CourseContentLayout title="Introduction to the TOEFL iBT Test">
      <h2 className="text-2xl font-bold mb-4">1.1 Overview of the Test Structure</h2>
      <p className="mb-4">
        The TOEFL iBT (Internet-Based Test) is a comprehensive assessment of English language proficiency for academic purposes. Developed by ETS (Educational Testing Service), it's widely recognized by over 12,000 institutions worldwide, including top universities in the United States, Canada, the United Kingdom, Australia, and New Zealand.
      </p>
      <h3 className="text-xl font-semibold mb-2">Key Features of the TOEFL iBT:</h3>
      <ul className="list-disc pl-6 mb-4">
        <li>Measures academic English skills in an academic environment</li>
        <li>Integrates multiple language skills, reflecting real-world academic scenarios</li>
        <li>Available in more than 200 countries and territories globally</li>
        <li>Offered in three formats: on a computer at a test center, on a computer at home, or on paper at a test center</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">Detailed Breakdown of Test Sections:</h3>

      <h4 className="text-lg font-semibold mt-4 mb-2">Reading Section</h4>
      <ul className="list-disc pl-6 mb-4">
        <li>Duration: 35 minutes (36 minutes allowed)</li>
        <li>Structure: 2 passages, 10 questions per passage</li>
        <li>Skills Tested: Reading to find information, basic comprehension, reading to learn</li>
        <li>Question Types: Multiple choice with one or more correct answers, insertion of text, completion of summary</li>
      </ul>

      <h4 className="text-lg font-semibold mt-4 mb-2">Listening Section</h4>
      <ul className="list-disc pl-6 mb-4">
        <li>Duration: 36 minutes (41 minutes allowed)</li>
        <li>Structure: 3 lectures and 2 conversations</li>
        <li>Skills Tested: Basic comprehension, pragmatic understanding, connecting and synthesizing information</li>
        <li>Question Types: Multiple choice, ordering events, matching objects or text to categories</li>
      </ul>

      <h4 className="text-lg font-semibold mt-4 mb-2">Speaking Section</h4>
      <ul className="list-disc pl-6 mb-4">
        <li>Duration: 16 minutes</li>
        <li>Structure: 4 tasks (1 independent, 3 integrated)</li>
        <li>Skills Tested: Express opinions, speak based on reading and listening tasks, summarize information</li>
      </ul>

      <h4 className="text-lg font-semibold mt-4 mb-2">Writing Section</h4>
      <ul className="list-disc pl-6 mb-4">
        <li>Duration: 29 minutes</li>
        <li>Structure: 2 tasks (Integrated Writing Task and Writing for an Academic Discussion Task)</li>
        <li>Skills Tested: Summarize and synthesize information, express and support opinions, write clearly and coherently</li>
      </ul>

      <p className="mb-4">
        Total Test Time: Approximately 2 hours<br />
        Recommended Time Allocation: 2.5 hours (including check-in procedures)
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">1.2 Understanding the Scoring System</h2>
      <p className="mb-4">
        The TOEFL iBT employs a sophisticated scoring system designed to provide a comprehensive assessment of a test taker's English language proficiency.
      </p>

      <h3 className="text-xl font-semibold mb-2">Scoring Scale:</h3>
      <ul className="list-disc pl-6 mb-4">
        <li>Each section (Reading, Listening, Speaking, and Writing) is scored on a scale of 0-30</li>
        <li>Total score range: 0-120 (sum of the four section scores)</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">Scoring Process:</h3>
      <ul className="list-disc pl-6 mb-4">
        <li>Reading and Listening: Machine-scored</li>
        <li>Speaking and Writing: Combination of certified human raters and AI scoring systems</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">Key Scoring Features:</h3>
      <h4 className="text-lg font-semibold mt-4 mb-2">MyBest® Scores:</h4>
      <ul className="list-disc pl-6 mb-4">
        <li>Combines highest section scores from all valid test dates within the past two years</li>
        <li>Allows test takers to showcase their best performance across multiple attempts</li>
      </ul>

      <h4 className="text-lg font-semibold mt-4 mb-2">Score Reporting:</h4>
      <ul className="list-disc pl-6 mb-4">
        <li>Available online in your ETS account</li>
        <li>Timing varies based on test format</li>
      </ul>

      <h2 className="text-2xl font-bold mt-8 mb-4">1.3 Computer-Based Testing Environment</h2>
      <p className="mb-4">
        The TOEFL iBT leverages technology to provide a standardized and efficient testing experience. Understanding the computer-based environment is crucial for optimal performance.
      </p>

      <h3 className="text-xl font-semibold mb-2">Test Delivery Systems:</h3>
      <ul className="list-disc pl-6 mb-4">
        <li>Test Center Computers: Secure, monitored environment with standardized equipment and software</li>
        <li>Home Edition: Uses test taker's personal computer, requires meeting specific technical requirements, proctored remotely via ProctorU</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">Key Features of the Testing Interface:</h3>
      <ul className="list-disc pl-6 mb-4">
        <li>On-screen Toolbar: Section indicator, question number tracker, time remaining display, volume control, help function</li>
        <li>Navigation Tools: Next and Back buttons, Review function</li>
        <li>Keyboard and Input Devices: Standard QWERTY English-language keyboard, computer mouse, microphone for Speaking section</li>
        <li>Note-taking: Paper and pencil provided at test centers</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">Preparation for the Computer-Based Environment:</h3>
      <ul className="list-disc pl-6 mb-4">
        <li>Familiarize yourself with the interface using TOEFL iBT Free Practice Test and TOEFL Practice Online</li>
        <li>Practice typing in English to improve speed and accuracy</li>
        <li>Learn to use the on-screen clock effectively and practice pacing strategies</li>
        <li>For Home Edition: Test your equipment and internet connection in advance</li>
        <li>Practice in a simulated test environment to reduce test-day anxiety</li>
      </ul>
    </CourseContentLayout>
  );
};

export default Introduction;