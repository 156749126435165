import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import axios from 'axios';

const CheckIcon = () => (
  <svg className="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
  </svg>
);

const ListeningExercise = () => {
  const { type, id } = useParams();
  const [exerciseData, setExerciseData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [error, setError] = useState(null);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [submissionResult, setSubmissionResult] = useState(null);
  const audioRef = useRef(null);

  const fetchExerciseData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/listening-exercise/${type}/${id}`);
      setExerciseData(response.data);
    } catch (error) {
      console.error('Error fetching exercise data:', error);
      setError('Failed to load exercise data. Please try again later.');
    }
  }, [type, id]);

  useEffect(() => {
    fetchExerciseData();
  }, [fetchExerciseData]);

  const handlePlay = () => {
    if (audioRef.current && !audioPlayed) {
      audioRef.current.play().catch(e => {
        console.error('Playback failed:', e);
        setError(`Failed to play audio: ${e.message}`);
      });
      setIsPlaying(true);
    }
  };

  const handleSkipToQuestions = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setIsPlaying(false);
    setAudioPlayed(true);
  };

  const handleAudioEnd = () => {
    setIsPlaying(false);
    setAudioPlayed(true);
  };

  const handleAnswer = (questionNumber, answer) => {
    setUserAnswers(prev => ({ ...prev, [questionNumber]: answer }));
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < exerciseData.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/submit-listening-exercise`, {
        exercise_type: type,
        exercise_id: id,
        answers: userAnswers
      });
      setSubmissionResult(response.data);
    } catch (error) {
      console.error('Error submitting exercise:', error);
      setError('Failed to submit exercise. Please try again later.');
    }
  };

  const handleRetake = () => {
    setUserAnswers({});
    setSubmissionResult(null);
    setCurrentQuestionIndex(0);
    setAudioPlayed(false);
    fetchExerciseData();
  };

  if (!exerciseData) {
    return <div>Loading...</div>;
  }

  const currentQuestion = exerciseData.questions[currentQuestionIndex];

  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <NavLink to="/dashboard">
            <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
          </NavLink>
          <NavLink
            to="/listening"
            className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
          >
            Back to Listening
          </NavLink>
        </div>
      </header>
      <main className="flex-grow flex items-center justify-center px-4 py-12">
        <div className="bg-white rounded-lg shadow-md p-8 max-w-4xl w-full">
          <h2 className="text-2xl font-bold mb-4">{exerciseData.title}</h2>
          {!audioPlayed && (
            <div className="flex space-x-4 mb-4">
              <button
                onClick={handlePlay}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              >
                {isPlaying ? 'Playing...' : 'Play Audio'}
              </button>
              <button
                onClick={handleSkipToQuestions}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
              >
                Skip to Questions
              </button>
            </div>
          )}
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <audio
            ref={audioRef}
            src={`${process.env.REACT_APP_API_URL}/api/listening-audio/${type}/${id}`}
            onEnded={handleAudioEnd}
            onError={(e) => {
              console.error('Audio error:', e);
              setError(`Error loading audio: ${e.target.error.message}`);
            }}
          />
          {audioPlayed && (
            <div className="mt-6">
              <h3 className="text-xl font-bold mb-4">
                {currentQuestionIndex + 1}. {currentQuestion.question_text}
              </h3>
              {currentQuestion.options && Object.entries(currentQuestion.options).map(([key, value]) => (
                <div key={key} className="mb-2 flex items-center">
                  <input
                    type="radio"
                    id={`question-${currentQuestionIndex}-${key}`}
                    name={`question-${currentQuestionIndex}`}
                    value={key}
                    checked={userAnswers[currentQuestion.question_number] === key}
                    onChange={() => handleAnswer(currentQuestion.question_number, key)}
                    className="mr-2"
                    disabled={submissionResult !== null}
                  />
                  <label htmlFor={`question-${currentQuestionIndex}-${key}`} className="flex items-center">
                    <span>{key})</span>
                    <span className="ml-2">{value}</span>
                    {submissionResult && submissionResult.results[currentQuestionIndex].correct_answer === key && (
                      <CheckIcon />
                    )}
                  </label>
                </div>
              ))}
              {submissionResult && (
                <div className="mt-4 p-4 border-t">
                  <p className={`text-lg ${submissionResult.results[currentQuestionIndex].is_correct ? "text-green-600" : "text-red-600"}`}>
                    {submissionResult.results[currentQuestionIndex].is_correct ? "Correct!" : "Incorrect."}
                  </p>
                  <p className="text-md text-gray-600 mt-2">
                    {submissionResult.results[currentQuestionIndex].explanation}
                  </p>
                </div>
              )}
              <div className="flex justify-between mt-6">
                <button
                  onClick={handlePreviousQuestion}
                  disabled={currentQuestionIndex === 0}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                >
                  Previous
                </button>
                {currentQuestionIndex === exerciseData.questions.length - 1 ? (
                  submissionResult ? (
                    <button
                      onClick={handleRetake}
                      className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                    >
                      Retake Quiz
                    </button>
                  ) : (
                    <button
                      onClick={handleSubmit}
                      className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                    >
                      Submit
                    </button>
                  )
                ) : (
                  <button
                    onClick={handleNextQuestion}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          )}
          {submissionResult && (
            <div className="mt-6 p-4 bg-gray-100 rounded">
              <p className="text-xl font-bold">Score: {submissionResult.score} / {submissionResult.total_questions}</p>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default ListeningExercise;