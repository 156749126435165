import React from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
const InstructionsPage = () => {
  const navigate=useNavigate();
  const handleNextPage = () => {
    navigate("/integrated-writing-task");
  };

  return (
    <div>
      <Helmet>
        <title>Integrated-Writing-Instructions</title>
        <meta
          name="description"
          content="Integrated Writing Instructions: Combine information from the reading passage and the listening material to write a cohesive essay. Follow the prompts closely and use AI-driven feedback to refine your response for clarity and accuracy."
        />
        <link rel="canonical" href="/integrated-writing-instructions" />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
        <header className="bg-white p-4 shadow-md">
          <div className="container mx-auto flex justify-between items-center">
            <NavLink to="/dashboard">
              <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
            </NavLink>
            <NavLink
              to="/dashboard"
              className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
            >
              Back to Home
            </NavLink>
          </div>
        </header>
        <main className="flex-grow p-4 text-black flex items-center justify-center">
          <section className="container mx-auto flex flex-col bg-white p-8 m-2 rounded-lg lg:w-[50%] justify-around w-[80%]">
            <h2 className="text-lg sm:text-lg md:text-2xl font-semibold mb-6">
              Instructions for Integrated Writing Task
            </h2>
            <p className="text-base sm:text-md md:text-lg mb-6">
              The TOEFL iBT test Writing section measures your ability to write
              in English in an academic setting and to present your ideas in a
              clear, well-organized way.
            </p>
            <p className="text-base sm:text-md md:text-lg mb-4">
              <strong className="text-lg sm:text-lg md:text-xl">
                Integrated writing task (20 minutes)
              </strong>{" "}
              — read a short passage and listen to a short lecture, then write
              in response to what you have read and listened to.
            </p>
            <ol className="list-decimal list-inside text-base sm:text-md md:text-lg mb-4">
              <li>Read the passage.</li>
              <li>
                When you are ready, you can listen to the lecture by clicking
                the button under the title.
              </li>
              <li>
                After listening to the lecture, write and submit your response.
                It will be evaluated by AI.
              </li>
            </ol>
            <button
              onClick={handleNextPage}
              className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
            >
              Next
            </button>
          </section>
        </main>
      </div>
    </div>
  );
};

export default InstructionsPage;
