import { useRef, useState } from 'react';
import { useExercise } from './ExerciseContext';

export const useAudio = () => {
  const audioRef = useRef(null);
  const { setAudioPlayed, setError } = useExercise();
  const [isPlaying, setIsPlaying] = useState(false); // Declare isPlaying and setIsPlaying state here
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.play().catch(e => {
        setError(`Failed to play audio: ${e.message}`);
      });
      setIsPlaying(true); // Set the state to playing
    }
  };

  const handleAudioEnd = () => {
    setIsPlaying(false); // Set the state to not playing
    setAudioPlayed(true);
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
      setProgress(progress);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  return {
    audioRef,
    isPlaying,
    setIsPlaying, // Expose setIsPlaying from the hook
    handlePlay,
    handleAudioEnd,
    handleTimeUpdate,
    handleLoadedMetadata,
    progress,
    duration
  };
};