import React from 'react';
import { NavLink } from 'react-router-dom';

const NavBar = () => {
  return (
    <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <NavLink to="/dashboard">
            <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
          </NavLink>
          <NavLink
            to="/dashboard"
            className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
          >
            Back to Home
          </NavLink>
        </div>
      </header>
  );
};

export default NavBar;