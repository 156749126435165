// src/Speaking/Tasks/Timer.jsx
import React, { useState, useEffect } from 'react';

const Timer = ({ duration, onComplete, label }) => {
    const [timeLeft, setTimeLeft] = useState(duration);

    useEffect(() => {
        if (timeLeft === 0) {
            onComplete();
            return;
        }

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft, onComplete]);

    return (
        <div className="timer">
            <h3><strong>{label} Time Left: </strong>{timeLeft} seconds</h3>
        </div>
    );
};

export default Timer;