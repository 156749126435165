import React from 'react';
import CourseContentLayout from '../components/CourseContentLayout';

const SpeakingSection = () => {
  return (
    <CourseContentLayout title="Speaking Section">
      <section >
        <h2 className="text-2xl font-bold mb-4">5.1 Overview and Structure</h2>
        <p className="mb-4">
          The TOEFL iBT Speaking section assesses the English speaking proficiency of non-native speakers aiming to pursue undergraduate or graduate studies in English-speaking academic environments. This section evaluates your ability to communicate effectively in English across various topics and scenarios relevant to academic life.
        </p>

        <h3 className="text-xl font-semibold mb-2">Key Structural Points:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>Duration:</strong> Estimated time: 16 minutes</li>
          <li><strong>Number of Tasks:</strong> 4 speaking tasks in total</li>
          <li><strong>Task Types:</strong> 1 Independent Speaking task, 3 Integrated Speaking tasks</li>
          <li><strong>Response Time:</strong>
            <ul className="list-disc pl-6">
              <li>45 seconds for the Independent task</li>
              <li>60 seconds for each Integrated task</li>
            </ul>
          </li>
          <li><strong>Preparation Time:</strong>
            <ul className="list-disc pl-6">
              <li>15 seconds for the Independent task</li>
              <li>30 seconds for Integrated tasks 2 and 3</li>
              <li>20 seconds for Integrated task 4</li>
            </ul>
          </li>
          <li><strong>Delivery Method:</strong> Responses are spoken into a microphone, and recordings are sent to ETS for scoring.</li>
          <li><strong>Scoring:</strong> Combination of AI scoring and certified human raters. Holistic scoring based on overall performance. Scores reported on a scale of 0-30 for the entire Speaking section.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Key Features:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>Diverse Topic Areas:</strong> Personal experiences, campus-based situations, and academic content material.</li>
          <li><strong>Integration of Skills:</strong> Tasks combine listening, reading, and speaking skills, reflecting real-world academic communication demands.</li>
          <li><strong>Note-taking:</strong> Allowed throughout the section to help organize thoughts and information.</li>
          <li><strong>Authentic Academic Content:</strong> Reflects real-life university scenarios and discussions.</li>
          <li><strong>Time Management:</strong> Crucial for delivering effective responses within time constraints.</li>
          <li><strong>Holistic Evaluation:</strong> Raters consider delivery, language use, and topic development.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Scoring Criteria:</h3>
        <p className="mb-4">
          Responses are evaluated based on four main criteria:
        </p>

        <h4 className="text-lg font-semibold mt-4 mb-2">General Description:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>How well the response fulfills the demands of the task.</li>
          <li>Overall intelligibility and coherence of the discourse.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Delivery:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Clarity and fluidity of speech.</li>
          <li>Pronunciation and intonation patterns.</li>
          <li>Pacing and rhythm.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Language Use:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Effective use of grammar and vocabulary.</li>
          <li>Control of basic and complex structures.</li>
          <li>Range and precision of vocabulary.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Topic Development:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Fullness and relevance of the response.</li>
          <li>Coherence and progression of ideas.</li>
          <li>Relationships between ideas.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Scoring Levels:</h3>
        <p className="mb-4">
          Responses are scored on a scale from 0 to 4, with the following general descriptions:
        </p>

        <h4 className="text-lg font-semibold mt-4 mb-2">Score 4 (Highest):</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Fulfills the demands of the task with at most minor lapses in completeness.</li>
          <li>Highly intelligible and exhibits sustained, coherent discourse.</li>
          <li>Generally well-paced flow with clear speech.</li>
          <li>Effective use of grammar and vocabulary with good control of basic and complex structures.</li>
          <li>Sustained and well-developed response with clear progression of ideas.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Score 3:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Addresses the task appropriately but may fall short of being fully developed.</li>
          <li>Generally intelligible and coherent with some fluidity of expression.</li>
          <li>Speech is generally clear, though may require some listener effort.</li>
          <li>Fairly automatic and effective use of grammar and vocabulary.</li>
          <li>Mostly coherent and sustained response, though may lack elaboration or specificity.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Score 2:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Addresses the task, but development of the topic is limited.</li>
          <li>Contains some intelligible speech, though problems with delivery and/or coherence occur.</li>
          <li>Speech is basically intelligible, but listener effort is needed.</li>
          <li>Limited range and control of grammar and vocabulary.</li>
          <li>Limited number of ideas presented with limited elaboration.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Score 1:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Very limited in content and/or coherence or only minimally connected to the task.</li>
          <li>Speech may be largely unintelligible.</li>
          <li>Severe limitations in grammar and vocabulary.</li>
          <li>Limited relevant content expressed.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Score 0:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Speaker makes no attempt to respond OR response is unrelated to the topic.</li>
        </ul>

        <p className="mb-4">
          Understanding this structure and scoring criteria is crucial for effective preparation. Test-takers should practice speaking on various topics, timing their responses, and becoming comfortable with the format of each task type.
        </p>
      </section>
      <section className="mt-8">
        <h2 className="text-2xl font-bold mb-4">5.2 Independent Speaking Task (Question 1)</h2>
        <p className="mb-4">
          The Independent Speaking task, also known as the "Paired Choice" task, is the first question in the Speaking section. It requires test-takers to express and support a personal opinion or preference based on their own ideas and experiences.
        </p>

        <h3 className="text-xl font-semibold mb-2">Key Features:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>Format:</strong> Presents two possible actions, situations, or opinions. Asks test-taker to choose one and explain their preference.</li>
          <li><strong>Topic Range:</strong> Everyday issues of general interest to students. May include campus life, social issues, or personal preferences.</li>
          <li><strong>Time Allocation:</strong>
            <ul className="list-disc pl-6">
              <li>15 seconds preparation time</li>
              <li>45 seconds response time</li>
            </ul>
          </li>
          <li><strong>Scoring Focus:</strong>
            <ul className="list-disc pl-6">
              <li>Clarity of choice or opinion</li>
              <li>Quality of reasons and examples provided</li>
              <li>Overall coherence and fluency of response</li>
            </ul>
          </li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Task Structure:</h3>
        <h4 className="text-lg font-semibold mt-4 mb-2">Question Presentation:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Appears on screen and is read aloud.</li>
          <li>Typically asks to choose between two options or agree/disagree with a statement.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Preparation Phase:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>15 seconds to organize thoughts.</li>
          <li>Note-taking is allowed but not required.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Response Phase:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>45 seconds to speak into the microphone.</li>
          <li>Clock counts down on screen.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Example Question:</h3>
        <p className="mb-4">
          "Some people think it is more fun to spend time with friends in restaurants or cafes. Others think it is more fun to spend time with friends at home. Which do you think is better? Explain why."
        </p>

        <h3 className="text-xl font-semibold mb-2">Strategies for Success:</h3>

        <h4 className="text-lg font-semibold mt-4 mb-2">Clear Position:</h4>
        <p className="mb-4">Begin by clearly stating your preference. Use phrases like "In my opinion..." or "I believe that...".</p>

        <h4 className="text-lg font-semibold mt-4 mb-2">Structured Response:</h4>
        <p className="mb-4">
          Provide 2-3 main reasons for your choice and support each reason with specific examples or explanations.
        </p>

        <h4 className="text-lg font-semibold mt-4 mb-2">Personal Experiences:</h4>
        <p className="mb-4">
          Draw from your own life to illustrate points. Be specific and relevant in your examples.
        </p>

        <h4 className="text-lg font-semibold mt-4 mb-2">Time Management:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Practice giving complete responses within 45 seconds.</li>
          <li>Aim for a brief introduction, 2-3 main points, and a quick conclusion.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Balanced Approach:</h4>
        <p className="mb-4">
          If you see merits in both options, you can acknowledge this. However, still make a clear choice and explain why one option is preferable overall.
        </p>

        <h4 className="text-lg font-semibold mt-4 mb-2">Avoid Memorization:</h4>
        <p className="mb-4">
          Raters can easily detect pre-memorized responses. Focus on developing flexible speaking skills rather than memorizing scripts.
        </p>

        <h3 className="text-xl font-semibold mb-2">Common Pitfalls to Avoid:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>Indecisiveness:</strong> Failing to make a clear choice between the options presented.</li>
          <li><strong>Lack of Support:</strong> Stating a preference without providing sufficient reasons or examples.</li>
          <li><strong>Irrelevance:</strong> Straying off-topic or providing unrelated information.</li>
          <li><strong>Time Mismanagement:</strong>
            <ul className="list-disc pl-6">
              <li>Speaking too slowly and running out of time.</li>
              <li>Speaking too quickly and finishing too early.</li>
            </ul>
          </li>
          <li><strong>Overreliance on Notes:</strong> Reading directly from notes instead of speaking naturally.</li>
          <li><strong>Neglecting Fluency:</strong> Focusing too much on grammar at the expense of natural flow.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Sample High-Scoring Response Structure:</h3>

        <h4 className="text-lg font-semibold mt-4 mb-2">Introduction (5-7 seconds):</h4>
        <p className="mb-4">Clearly state your preference. Example: "In my opinion, spending time with friends at home is more enjoyable than meeting in restaurants or cafes."</p>

        <h4 className="text-lg font-semibold mt-4 mb-2">Main Point 1 (10-12 seconds):</h4>
        <p className="mb-4">State your first reason and provide a brief example. Example: "Firstly, being at home offers a more relaxed and comfortable environment. We can lounge on sofas, play music we like, and not worry about disturbing others."</p>

        <h4 className="text-lg font-semibold mt-4 mb-2">Main Point 2 (10-12 seconds):</h4>
        <p className="mb-4">Present your second reason with support. Example: "Secondly, staying at home is more economical. We can prepare snacks or meals together, which is not only cost-effective but also a fun bonding activity."</p>

        <h4 className="text-lg font-semibold mt-4 mb-2">Main Point 3 (if time allows, 10-12 seconds):</h4>
        <p className="mb-4">Offer a third reason or elaborate on previous points. Example: "Moreover, at home, we have more privacy and can engage in a wider range of activities, from watching movies to playing board games."</p>

        <h4 className="text-lg font-semibold mt-4 mb-2">Conclusion (5-7 seconds):</h4>
        <p className="mb-4">Briefly restate your preference and summarize. Example: "For these reasons, I find spending time with friends at home more enjoyable and beneficial for our relationships."</p>

        <p className="mb-4">
          By understanding the structure and expectations of the Independent Speaking task and practicing regularly, test-takers can improve their ability to provide clear, well-supported responses within the given time constraints.
        </p>
      </section>
      <section className="mt-8">
        <h2 className="text-2xl font-bold mb-4">5.3 Integrated Speaking Tasks (Questions 2, 3, and 4)</h2>
        <p className="mb-4">
          The Integrated Speaking tasks in the TOEFL iBT test are designed to assess your ability to combine information from different sources and express ideas coherently. These tasks simulate real academic scenarios where students need to process information from readings and lectures, then speak about it.
        </p>

        <h3 className="text-xl font-semibold mb-2">5.3.1 Question 2: Fit and Explain (Campus Situation)</h3>
        <p className="mb-4">
          This task asks you to integrate and explain information from both a reading passage and a conversation related to a university situation.
        </p>

        <h4 className="text-lg font-semibold mt-4 mb-2">Task Overview:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Read a short passage about a campus-related topic (90-115 words).</li>
          <li>Listen to two people discussing the topic (60-80 seconds).</li>
          <li>Respond to a question based on both the reading and listening materials.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Time Allocation:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>45-50 seconds to read the passage.</li>
          <li>30 seconds preparation time.</li>
          <li>60 seconds response time.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Key Features:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Topics typically involve university policies, plans, facilities, or campus life.</li>
          <li>One speaker in the conversation usually expresses a strong opinion.</li>
          <li>The question asks you to summarize the speaker's opinion and reasons.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Strategy:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>During reading:</strong> Identify the main proposal or issue and note key reasons or points mentioned.</li>
          <li><strong>While listening:</strong> Determine the speaker's stance (agree/disagree) and note how their points relate to the reading.</li>
          <li><strong>In your response:</strong> Clearly state the speaker's opinion, explain their reasons, and connect them to the reading.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Example Structure:</h4>
        <p className="mb-4">
          "The student [agrees/disagrees] with the university's plan to [proposal]. She thinks [reason 1 from conversation], which relates to the [point from reading]. Additionally, she mentions [reason 2 from conversation], addressing the [another point from reading]."
        </p>

        <h3 className="text-xl font-semibold mb-2">5.3.2 Question 3: General/Specific (Academic Course Topics)</h3>
        <p className="mb-4">
          This task asks you to integrate general academic concepts from a reading passage with specific examples or details from a lecture.
        </p>

        <h4 className="text-lg font-semibold mt-4 mb-2">Task Overview:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Read a short academic passage (90-115 words).</li>
          <li>Listen to a brief lecture excerpt (60-90 seconds).</li>
          <li>Respond to a question integrating information from both sources.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Time Allocation:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>45-50 seconds to read the passage.</li>
          <li>30 seconds preparation time.</li>
          <li>60 seconds response time.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Key Features:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Topics are drawn from various academic fields.</li>
          <li>The reading provides general concepts, while the lecture offers specific examples.</li>
          <li>No prior knowledge of the subject is required.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Strategy:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>During reading:</strong> Identify the main concept or principle and note key characteristics or components.</li>
          <li><strong>While listening:</strong> Focus on how the lecture applies or illustrates the reading concept. Note specific examples or applications.</li>
          <li><strong>In your response:</strong> Briefly introduce the general concept from the reading, explain how the lecture examples illustrate this concept, and make clear connections between the general and specific information.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Example Structure:</h4>
        <p className="mb-4">
          "The reading describes [general concept]. The lecture illustrates this by discussing [specific example 1]. For instance, [detail from lecture]. This demonstrates [connection to reading]. Additionally, the professor mentions [specific example 2], which further shows [another connection to reading]."
        </p>

        <h3 className="text-xl font-semibold mb-2">5.3.3 Question 4: Summary (Academic Lecture)</h3>
        <p className="mb-4">
          This task asks you to summarize the main points of an academic lecture, synthesizing information into a coherent response.
        </p>

        <h4 className="text-lg font-semibold mt-4 mb-2">Task Overview:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Listen to a short lecture excerpt (90-120 seconds).</li>
          <li>Summarize the main points and provide relevant details.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Time Allocation:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>20 seconds preparation time.</li>
          <li>60 seconds response time.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Key Features:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Focuses on a single academic topic.</li>
          <li>Often involves explaining a concept, theory, or phenomenon.</li>
          <li>Requires synthesizing and organizing information from the lecture.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Strategy:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>While listening:</strong> Identify the main topic or concept, note key points and supporting details, and pay attention to the overall structure of the lecture.</li>
          <li><strong>In your response:</strong> Start with a brief introduction of the main topic, present the key points in a logical order, include relevant examples or details from the lecture, and show how the points relate to each other or to the main concept.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">Example Structure:</h4>
        <p className="mb-4">
          "The lecture discusses [main topic]. The professor explains that [key point 1], which involves [detail or example]. Furthermore, [key point 2] is important because [reason or example]. Lastly, the professor emphasizes [key point 3], demonstrating [connection to main topic]."
        </p>

        <h3 className="text-xl font-semibold mb-2">General Tips for Integrated Tasks:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>Practice Active Listening:</strong> Focus on main ideas and supporting details. Don't try to write down everything.</li>
          <li><strong>Develop Efficient Note-taking:</strong> Use abbreviations and symbols. Focus on key words and concepts.</li>
          <li><strong>Organize Your Response:</strong> Use a clear structure (introduction, main points, conclusion). Use transition words to connect ideas.</li>
          <li><strong>Paraphrase Information:</strong> Don't simply repeat the exact words from the sources. Show understanding by restating ideas in your own words.</li>
          <li><strong>Manage Your Time:</strong> Practice with a timer to ensure you can complete your response. Leave a few seconds at the end to wrap up your answer.</li>
          <li><strong>Stay Focused on the Task:</strong> Answer only what is asked. Don't include irrelevant information or personal opinions.</li>
        </ul>
      </section>
      <section>
        <h2 className="text-2xl font-bold mb-4">5.4 Speaking Strategies</h2>

        <h3 className="text-xl font-semibold mb-2">Organizing Your Response</h3>

        <h4 className="text-lg font-semibold mt-4 mb-2">a) Clear structure:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>For Independent tasks:
            <ul className="list-disc pl-6">
              <li><strong>Introduction (5-7 seconds):</strong> Clearly state your position. Use phrases like "In my opinion..." or "I believe that..."</li>
              <li><strong>Main body (30-35 seconds):</strong> Present 2-3 main points. Support each point with specific examples or explanations.</li>
              <li><strong>Conclusion (5-7 seconds):</strong> Briefly restate your position and summarize your main arguments.</li>
            </ul>
          </li>
          <li>For Integrated tasks:
            <ul className="list-disc pl-6">
              <li><strong>Introduction (5-7 seconds):</strong> Briefly mention the context (e.g., "The reading passage and lecture discuss...")</li>
              <li><strong>Main body (45-50 seconds):</strong> Summarize key information from both sources. Show how the listening portion relates to the reading. Use phrases like "According to the reading..." and "The professor explains..."</li>
              <li><strong>Conclusion (5-7 seconds):</strong> Summarize the main relationship between the sources.</li>
            </ul>
          </li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">b) Time management:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Practice with a stopwatch to internalize the feel of 45 and 60 seconds.</li>
          <li>Aim for roughly 15-20 seconds per main point in Independent tasks.</li>
          <li>For Integrated tasks, allocate about 20-25 seconds each to the reading summary, listening summary, and their connection.</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">c) Preparation time utilization:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>For Independent tasks (15 seconds prep):
            <ul className="list-disc pl-6">
              <li>Quickly decide your stance.</li>
              <li>Jot down 2-3 key words for main points.</li>
            </ul>
          </li>
          <li>For Integrated tasks (20-30 seconds prep):
            <ul className="list-disc pl-6">
              <li>Note key concepts from reading/listening.</li>
              <li>Identify how the sources relate (support, contradict, exemplify).</li>
            </ul>
          </li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Using Transitions and Connectors</h3>

        <h4 className="text-lg font-semibold mt-4 mb-2">a) Transition phrases:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>To introduce points:</strong>
            <ul className="list-disc pl-6">
              <li>"First and foremost," "To begin with," "The primary reason is..."</li>
              <li>"Secondly," "Another crucial point is," "Additionally,"</li>
              <li>"Lastly," "Finally," "The third aspect to consider is..."</li>
            </ul>
          </li>
          <li><strong>To add information:</strong>
            <ul className="list-disc pl-6">
              <li>"Furthermore," "Moreover," "What's more,"</li>
              <li>"In addition to this," "Another key factor is,"</li>
            </ul>
          </li>
          <li><strong>To contrast ideas:</strong>
            <ul className="list-disc pl-6">
              <li>"On the other hand," "In contrast," "Conversely,"</li>
              <li>"Despite this," "Although," "While it's true that... it's also important to note..."</li>
            </ul>
          </li>
          <li><strong>To conclude:</strong>
            <ul className="list-disc pl-6">
              <li>"In conclusion," "To sum up," "All things considered,"</li>
              <li>"Taking everything into account," "Ultimately,"</li>
            </ul>
          </li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">b) Connectors for relationships between ideas:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>Cause and effect:</strong> "because," "therefore," "as a result," "consequently," "due to," "this leads to"</li>
          <li><strong>Comparison:</strong> "similarly," "likewise," "in the same way," "comparable to," "just as... so too"</li>
          <li><strong>Emphasis:</strong> "importantly," "significantly," "notably," "it should be emphasized that," "a key point to remember is"</li>
          <li><strong>Exemplification:</strong> "for instance," "to illustrate," "as an example," "specifically," "a case in point is"</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Practical Application of Strategies</h3>

        <h4 className="text-lg font-semibold mt-4 mb-2">a) Active listening (for Integrated tasks):</h4>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>Focus on:</strong> Main topic or argument, key supporting points (usually 2-3), examples or evidence provided</li>
          <li><strong>Note-taking techniques:</strong>
            <ul className="list-disc pl-6">
              <li>Use symbols (e.g., "=" for "equals," "→" for "leads to")</li>
              <li>Abbreviate common words (e.g., "b/c" for "because," "w/" for "with")</li>
              <li>Create a simple outline structure (main idea → supporting points)</li>
            </ul>
          </li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">b) Paraphrasing:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>Techniques:</strong> Use synonyms, change word forms, restructure sentences</li>
          <li><strong>Practice exercises:</strong>
            <ul className="list-disc pl-6">
              <li>Rewrite newspaper headlines in your own words</li>
              <li>Summarize short paragraphs without looking at the original text</li>
            </ul>
          </li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">c) Elaboration and examples:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>For abstract concepts, provide concrete examples:</strong> Mention specific scenarios like "clear email writing" or "active listening in meetings"</li>
          <li><strong>Use the "STAR" method for personal examples:</strong>
            <ul className="list-disc pl-6">
              <li><strong>Situation:</strong> Set the context</li>
              <li><strong>Task:</strong> Describe the challenge or goal</li>
              <li><strong>Action:</strong> Explain what you did</li>
              <li><strong>Result:</strong> Share the outcome</li>
            </ul>
          </li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">d) Clarity and coherence:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Speak at a moderate pace (aim for about 130-150 words per minute)</li>
          <li>Use a mix of simple and compound sentences</li>
          <li>Maintain logical flow: Use sequencing words (e.g., "first," "next," "then," "finally"). Ensure each sentence logically follows from the previous one</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">e) Time-saving techniques:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>If running out of time:
            <ul className="list-disc pl-6">
              <li>Quickly summarize remaining points: "Briefly, my other points are..."</li>
              <li>Provide a concise conclusion: "In short, these reasons support my view that..."</li>
            </ul>
          </li>
          <li><strong>Filler phrases (use sparingly):</strong> "That's an interesting question...", "Let me think about that for a moment..."</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">f) Recovery strategies:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>For mistakes:</strong> Briefly correct: "I mean..." or "Rather...". If unsure, move on without drawing attention to the error</li>
          <li><strong>If losing train of thought:</strong> Restate last point: "As I was saying about...". Bridge to next point: "Moving on to my next point..."</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Task-Specific Strategies</h3>

        <h4 className="text-lg font-semibold mt-4 mb-2">a) Independent Speaking Task:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Clearly state opinion: "I strongly believe that..." or "In my view..."</li>
          <li><strong>Support with personal experiences:</strong>
            <ul className="list-disc pl-6">
              <li>Use vivid details: "I vividly remember when..."</li>
              <li>Explain impact: "This experience taught me that..."</li>
            </ul>
          </li>
          <li><strong>Acknowledge opposing view (optional):</strong> "While some might argue that..., I believe..." or "Although there are merits to the opposing view, I think..."</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">b) Integrated Speaking Tasks:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>Reading passage:</strong> Quickly identify main topic and 2-3 key points. Note any definitions or explanations of concepts</li>
          <li><strong>Listening:</strong> Listen for how it relates to reading (supports, contradicts, exemplifies). Note any new information or perspectives introduced</li>
          <li><strong>Response:</strong> Begin with a brief overview: "The reading and listening passages discuss...". Clearly state how the listening relates to the reading. Integrate information from both sources throughout your response</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Practice and Self-Evaluation</h3>

        <h4 className="text-lg font-semibold mt-4 mb-2">a) Regular practice:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Use official TOEFL practice tests</li>
          <li>Set aside dedicated time (e.g., 30 minutes daily) for speaking practice</li>
          <li>Gradually increase difficulty of practice materials</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">b) Self-assessment:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Create a detailed checklist based on TOEFL Speaking rubric:
            <ul className="list-disc pl-6">
              <li>Delivery: Clarity, fluency, pronunciation</li>
              <li>Language Use: Grammar accuracy, vocabulary range</li>
              <li>Topic Development: Relevance, coherence, completeness</li>
            </ul>
          </li>
          <li>Record and transcribe your responses. Analyze transcriptions for grammar and vocabulary use</li>
          <li>Listen to recordings for pronunciation and fluency</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">c) Peer practice:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Form a study group or find an online language exchange partner</li>
          <li>Take turns being the speaker and the evaluator</li>
          <li>Provide constructive feedback using the TOEFL rubric criteria</li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-bold mb-4">5.5 Pronunciation and Fluency</h2>

        <h3 className="text-xl font-semibold mb-2">Pronunciation</h3>

        <h4 className="text-lg font-semibold mt-4 mb-2">a) Focus areas:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Individual sounds:
            <ul className="list-disc pl-6">
              <li>Vowels: Distinguish between similar sounds (e.g., /i/ in "beat" vs. /ɪ/ in "bit")</li>
              <li>Consonants: Practice problematic sounds (e.g., /θ/ in "think," /ð/ in "this")</li>
            </ul>
          </li>
          <li>Word stress:
            <ul className="list-disc pl-6">
              <li>Learn stress patterns (e.g., nouns often stressed on first syllable, verbs on second)</li>
              <li>Practice with multi-syllable words (e.g., PHO-to-graph, pho-TO-gra-phy)</li>
            </ul>
          </li>
          <li>Sentence stress:
            <ul className="list-disc pl-6">
              <li>Emphasize content words (nouns, main verbs, adjectives, adverbs)</li>
              <li>De-emphasize function words (articles, prepositions, auxiliaries)</li>
            </ul>
          </li>
          <li>Intonation:
            <ul className="list-disc pl-6">
              <li>Rising intonation for yes/no questions</li>
              <li>Falling intonation for statements and wh-questions</li>
            </ul>
          </li>
          <li>Linking words:
            <ul className="list-disc pl-6">
              <li>Consonant-to-vowel linking (e.g., "an apple" → "a napple")</li>
              <li>Consonant-to-consonant blending (e.g., "good day" → "goo day")</li>
            </ul>
          </li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">b) Common challenges for non-native speakers:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Th-sounds:
            <ul className="list-disc pl-6">
              <li>Voiced /ð/ (as in "this"): Practice with tongue between teeth, vibrating vocal cords</li>
              <li>Voiceless /θ/ (as in "think"): Same tongue position, no vocal cord vibration</li>
            </ul>
          </li>
          <li>R and L distinction:
            <ul className="list-disc pl-6">
              <li>For /r/, curl tongue tip back without touching roof of mouth</li>
              <li>For /l/, touch tongue tip to ridge behind upper teeth</li>
            </ul>
          </li>
          <li>Vowel length:
            <ul className="list-disc pl-6">
              <li>Short vowels: Relax mouth muscles (e.g., "ship," "put")</li>
              <li>Long vowels: Tense mouth muscles (e.g., "sheep," "boot")</li>
            </ul>
          </li>
          <li>Silent letters:
            <ul className="list-disc pl-6">
              <li>Common patterns: "k" before "n" (know), "b" after "m" (climb), "gh" (light)</li>
              <li>Practice with word lists focusing on specific silent letter patterns</li>
            </ul>
          </li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">c) Improvement techniques:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Minimal pair exercises:
            <ul className="list-disc pl-6">
              <li>Create lists: ship/sheep, bet/bat, fool/full</li>
              <li>Practice discrimination: Listen and identify which word you hear</li>
              <li>Production practice: Say pairs aloud, focusing on the distinction</li>
            </ul>
          </li>
          <li>Shadowing:
            <ul className="list-disc pl-6">
              <li>Choose audio with transcripts (podcasts, TED talks)</li>
              <li>Listen and repeat in real-time, mimicking exactly</li>
              <li>Focus on matching intonation and stress patterns</li>
            </ul>
          </li>
          <li>IPA (International Phonetic Alphabet) study:
            <ul className="list-disc pl-6">
              <li>Learn symbols for English sounds</li>
              <li>Use IPA transcriptions in dictionaries for correct pronunciation</li>
            </ul>
          </li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Fluency</h3>

        <h4 className="text-lg font-semibold mt-4 mb-2">a) Components of fluency:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Speech rate:
            <ul className="list-disc pl-6">
              <li>Aim for 130-150 words per minute (practice with timed readings)</li>
            </ul>
          </li>
          <li>Pausing patterns:
            <ul className="list-disc pl-6">
              <li>Natural pauses at punctuation and between thought groups</li>
              <li>Avoid pausing within phrases or after function words</li>
            </ul>
          </li>
          <li>Use of fillers:
            <ul className="list-disc pl-6">
              <li>Limit use of "um," "uh," "like"</li>
              <li>Practice more natural fillers: "well," "you see," "in fact"</li>
            </ul>
          </li>
          <li>Self-correction:
            <ul className="list-disc pl-6">
              <li>Develop ability to rephrase smoothly: "or rather," "I mean"</li>
            </ul>
          </li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">b) Strategies to improve fluency:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Regular speaking practice:
            <ul className="list-disc pl-6">
              <li>Daily 5-minute free-speaking sessions on random topics</li>
              <li>Record and analyze for unnaturally long pauses or repetitions</li>
            </ul>
          </li>
          <li>Shadowing:
            <ul className="list-disc pl-6">
              <li>Start with short phrases, build up to longer passages</li>
              <li>Focus on matching the speaker's rhythm and speed</li>
            </ul>
          </li>
          <li>Impromptu speaking:
            <ul className="list-disc pl-6">
              <li>Use TOEFL Independent Speaking prompts</li>
              <li>Give yourself 15 seconds to prepare, then speak for 45 seconds</li>
            </ul>
          </li>
          <li>Expanding vocabulary:
            <ul className="list-disc pl-6">
              <li>Learn words in context through extensive reading</li>
              <li>Focus on collocations and phrasal verbs for natural expression</li>
            </ul>
          </li>
          <li>Practicing common phrases:
            <ul className="list-disc pl-6">
              <li>Memorize and practice using transitional phrases</li>
              <li>Learn idiomatic expressions for more natural speech</li>
            </ul>
          </li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">c) Rhythm and intonation:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Stress-timed rhythm:
            <ul className="list-disc pl-6">
              <li>Practice with limericks or nursery rhymes to internalize rhythm</li>
              <li>Use a metronome to maintain consistent stress timing</li>
            </ul>
          </li>
          <li>Rising and falling intonation:
            <ul className="list-disc pl-6">
              <li>Mark texts with arrows for rising/falling intonation</li>
              <li>Practice reading aloud, exaggerating intonation patterns</li>
            </ul>
          </li>
          <li>Content vs. function words:
            <ul className="list-disc pl-6">
              <li>Highlight content words in a text and practice emphasizing them</li>
              <li>Read sentences focusing on de-emphasizing function words</li>
            </ul>
          </li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Accent Reduction</h3>

        <h4 className="text-lg font-semibold mt-4 mb-2">a) Understanding accent's impact:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Focus on intelligibility:
            <ul className="list-disc pl-6">
              <li>Prioritize clear pronunciation of key words</li>
              <li>Ensure stress and intonation convey intended meaning</li>
            </ul>
          </li>
          <li>Embrace your accent as part of your identity:
            <ul className="list-disc pl-6">
              <li>Aim for clarity and confidence, not accent elimination</li>
            </ul>
          </li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">b) Techniques for accent reduction:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Sound analysis:
            <ul className="list-disc pl-6">
              <li>Compare phoneme inventories of your native language and English</li>
              <li>Identify sounds that don't exist in your language and prioritize them</li>
            </ul>
          </li>
          <li>Mouth and tongue positioning:
            <ul className="list-disc pl-6">
              <li>Use diagrams or videos to understand correct articulation</li>
              <li>Practice in front of a mirror to ensure correct positioning</li>
            </ul>
          </li>
          <li>Prosody imitation:
            <ul className="list-disc pl-6">
              <li>Listen to podcasts or audiobooks by native speakers</li>
              <li>Focus on copying the "music" of the language: stress, rhythm, and intonation</li>
            </ul>
          </li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Practical Exercises</h3>

        <h4 className="text-lg font-semibold mt-4 mb-2">a) Tongue twisters:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Start with simple ones: "She sells seashells by the seashore"</li>
          <li>Progress to more complex: "How much wood would a woodchuck chuck if a woodchuck could chuck wood?"</li>
          <li>Create custom tongue twisters focusing on your problem sounds</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">b) Reading aloud:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Use diverse materials: news articles, academic papers, fiction</li>
          <li>Mark up texts for stress, pauses, and intonation before reading</li>
          <li>Record yourself and compare with native speaker recordings</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">c) Conversations with native speakers:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Participate in language exchange programs or conversation groups</li>
          <li>Focus on one aspect of pronunciation in each conversation</li>
          <li>Ask for specific feedback on your pronunciation and intonation</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">d) Mimicry exercises:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Choose 30-second clips from various sources</li>
          <li>Listen multiple times, then record yourself mimicking exactly</li>
          <li>Compare your recording with the original, focusing on matching rhythm and intonation</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Technology-Aided Practice</h3>

        <h4 className="text-lg font-semibold mt-4 mb-2">a) Speech recognition software:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Use tools like Google's speech-to-text</li>
          <li>Practice with various accents to improve versatility</li>
          <li>Focus on words or phrases that the software consistently misrecognizes</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">b) Pronunciation apps:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Use apps like ELSA Speak or Speechling for targeted practice</li>
          <li>Focus on sounds specific to English that don't exist in your native language</li>
          <li>Use features that provide visual feedback (e.g., waveform comparisons)</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">c) Audio recording and analysis:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Use software like Audacity to record and analyze your speech</li>
          <li>Look at waveforms to compare your stress and intonation patterns with native speakers</li>
          <li>Use pitch analysis tools to visualize and improve your intonation</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Stress Management for Fluency</h3>

        <h4 className="text-lg font-semibold mt-4 mb-2">a) Breathing exercises:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Practice diaphragmatic breathing:
            <ul className="list-disc pl-6">
              <li>Inhale deeply through your nose, expanding your belly</li>
              <li>Exhale slowly through your mouth</li>
            </ul>
          </li>
          <li>Use 4-7-8 technique before speaking:
            <ul className="list-disc pl-6">
              <li>Inhale for 4 counts, hold for 7, exhale for 8</li>
            </ul>
          </li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">b) Visualization techniques:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Create a mental image of successful speaking:
            <ul className="list-disc pl-6">
              <li>Visualize yourself speaking confidently and fluently</li>
              <li>Imagine positive responses from listeners</li>
            </ul>
          </li>
          <li>Practice this visualization daily, especially before speaking tasks</li>
        </ul>

        <h4 className="text-lg font-semibold mt-4 mb-2">c) Positive self-talk:</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Develop a list of affirmations:
            <ul className="list-disc pl-6">
              <li>"I am a clear and effective communicator"</li>
              <li>"My ideas are valuable and worth expressing"</li>
            </ul>
          </li>
          <li>Repeat these affirmations before speaking practice or tests</li>
        </ul>

        <p className="mb-4">
          By implementing these detailed strategies and practicing consistently, test-takers can significantly enhance their speaking skills for the TOEFL iBT. Remember, improvement comes with regular, focused practice and self-reflection.
        </p>
      </section>


      

    </CourseContentLayout>
  );
};

export default SpeakingSection;