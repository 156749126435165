import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { DataState } from '../config/ContextApi';
import Loading from '../LoadingPage/Loading';
import { Helmet } from 'react-helmet-async';
const Reading = () => {
  const { setQuestionNo } = DataState();
  const [noQuestions,setNoQuestions] = useState(null);
  const [scores,setScores]=useState();
  const navigate = useNavigate();

  const getNumberOfQuestions=async()=>{
  localStorage.setItem("OpenStudyAi-hasVisited", "true");//using for feedback
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/reading-number`)//getting the number of questions
    if (response.statusText === 'OK')
      setNoQuestions(response.data.message);
  const token = localStorage.getItem("OpenStudyAi-token");
  const getEmail = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/protected`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const requestData = {
    Email: getEmail.data.logged_in_as.email,
    SectionPrefix: "TOEFL#reading",
  };
  try{
  const scr = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/reading-results`,
    requestData,
    {
      headers: {
        "Content-Type": "application/json", // Ensure correct content type
      },
    }
  );
    setScores(scr.data);
}catch(e){
  console.log(e)
}
  }
  useEffect(()=>{
    getNumberOfQuestions();
},[])
const buttons = Array.from(
  { length: noQuestions },
  (_, i) => `Question ${i + 1}`
);

const handleQuestion=(index)=>{
  setQuestionNo(index+1);
  localStorage.setItem("OpenStudyAiQuestionNo", index+1);
  navigate("/reading-landing-page");
}

if (!noQuestions) {
  return <Loading />;
}

  return (
    <div>
      <Helmet>
        <title>Reading</title>
        <meta
          name="description"
          content="Reading Section: Choose any question to start practicing and enhance your reading skills. Tackle a variety of passages and question types to build comprehension, speed, and accuracy. Tailor your practice to focus on specific areas and track your progress as you go."
        />
        <link rel="canonical" href="/reading" />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
        <header className="bg-white p-4 shadow-md">
          <div className="container mx-auto flex justify-between items-center">
            <NavLink to="/dashboard">
              <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
            </NavLink>
            <NavLink
              to="/dashboard"
              className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
            >
              Back to Home
            </NavLink>
          </div>
        </header>

        <div className=" p-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {buttons.map((button, index) => (
              <button
                key={index}
                className={`${
                  scores?.hasOwnProperty(button)
                    ? "hover:bg-amber-100 bg-amber-500 "
                    : "bg-amber-400 hover:bg-amber-500"
                }bg-amber-400 hover:bg-amber-500 text-white font-bold py-2 px-4 rounded `}
                onClick={() => handleQuestion(index)}
              >
                {scores?.hasOwnProperty(button) ? (
                  <div className="flex justify-around">
                    <div>{button}</div>
                    <div className="ml-2">Score: {scores[button]}</div>
                  </div>
                ) : (
                  button
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reading