import React, { useEffect, useRef, useContext, useState } from 'react';
import axios from 'axios';
import { AudioContext } from './AudioContext.js';

const AudioRecorder = ({ duration, taskPrompt, taskNum, llmContext }) => {
    const { selectedDevice } = useContext(AudioContext);
    const [audioBlob, setAudioBlob] = useState(null);
    const [transcript, setTranscript] = useState('');
    const [streamReady, setStreamReady] = useState(false);
    const audioRef = useRef(null);
    const streamRef = useRef(null);
    const recorderRef = useRef(null);
    const chunks = useRef([]);
    const [mimeType, setMimeType] = useState('audio/webm'); // Default MIME type
    const browser = localStorage.getItem('browser');

    useEffect(() => {
        const fetchMediaStream = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: { deviceId: selectedDevice ? { exact: selectedDevice } : undefined }
                });
                streamRef.current = stream;
                setStreamReady(true);
            } catch (error) {
                console.error('Error accessing microphone', error);
            }
        };

        fetchMediaStream();

        return () => {
            if (streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
                streamRef.current = null;  // Clear the reference
            }
        };
    }, [selectedDevice]);

    useEffect(() => {
        const startRecording = async () => {
            if (!streamReady || !streamRef.current) {
                console.error('Media stream is not ready.');
                return;
            }

            try {
                let options = { mimeType };
                if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                    options = { mimeType: 'audio/mp4' }; // Fallback MIME type for Safari
                    console.log("options.mimeType: ", options.mimeType);

                    setMimeType('audio/mp4');
                }
                const recorder = new MediaRecorder(streamRef.current, options);

                recorder.ondataavailable = event => {
                    chunks.current.push(event.data);
                };

                recorder.onstop = async () => {
                    const blob = new Blob(chunks.current, { type: options.mimeType });
                    setAudioBlob(blob);
                    const audioUrl = URL.createObjectURL(blob);
                    localStorage.setItem("audioUrlTask", audioUrl);
                    console.log("audioUrlTask: ", localStorage.getItem('audioUrlTask'));
                    if (audioRef.current) {
                        audioRef.current.src = audioUrl;
                    }
                    await uploadAudio(blob, taskNum, taskPrompt);
                    streamRef.current = null;  // Clear the reference
                };

                recorder.start();
                recorderRef.current = recorder;
                setTimeout(() => {
                    recorder.stop();
                }, duration * 1000);
            } catch (error) {
                console.error('Error starting recording', error);
            }
        };

        startRecording();

        return () => {
            if (recorderRef.current) {
                recorderRef.current.stop();
            }
        };
    }, [duration, streamReady]);

    const uploadAudio = async (audioBlob, taskNum, taskPrompt) => {
        localStorage.setItem("FileIDTask1", "");
        console.log("uploadAudio fileid: ", localStorage.getItem('FileIDTask1'));

        const formData = new FormData();
        if (mimeType !== 'audio/webm' || browser === 'Safari') {
            formData.append('audio', audioBlob, 'recording.mp4');
        } else {
            formData.append('audio', audioBlob, 'recording.webm');
        }
        formData.append('taskNum', taskNum);
        formData.append('taskPrompt', taskPrompt);
        if (llmContext) {
            formData.append('llmContext', llmContext);
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload-audio`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            localStorage.setItem("FileIDTask1", response.data.file_id);
            console.log("POST: uploadAudio fileid: ", localStorage.getItem('FileIDTask1'), response.data);
        } catch (error) {
            console.error('Error uploading audio', error);
        }
    };


};

export default AudioRecorder;