// AudioContext.js
import React, { createContext, useState } from 'react';

export const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
    const [selectedDevice, setSelectedDevice] = useState('default');
    const [audioDevices, setAudioDevices] = useState([]);

    return (
        <AudioContext.Provider value={{ selectedDevice, setSelectedDevice, audioDevices, setAudioDevices }}>
            {children}
        </AudioContext.Provider>
    );
};