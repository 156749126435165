import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Loading from "../../LoadingPage/Loading";
import Popup from "../Popup";
import QuestionData from "./question_1.json";
import Speech from "./Speech";
import CountdownTimer from "../../Timer/CountdownTimer";
import { Helmet } from "react-helmet-async";
import { useAtom } from "jotai";
import {
  questionAtom,
  attemptIndexAtom,
  answersAtom,
  scoreAtom,
} from "../../atom";
const IntegratedWriting = () => {
       const [questionNum, setQuestionNum] = useAtom(questionAtom);
       const [attemptIndex, setAttemptIndex] = useAtom(attemptIndexAtom);
       const [attemptAnswers, setAttemptAnswers] = useAtom(answersAtom);
       const [attemptScore, setAttemptScore] = useAtom(scoreAtom);
  const [userResponse, setUserResponse] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [discussionData, setDiscussionData] = useState(null);
  const [questionNumber, setQuestionNumber] = useState(1);
  const [resultData, setResultData] = useState(null); // Added state for result data
  const [words, setWords] = useState([""]);
  const [showPopup, setShowPopup] = useState(false);
  const [next, setNext]=useState(false);
  const [speechNext,setSpeechNext]=useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [email, setEmail] = useState("");
  const getQuestion = async () => {
    try {
      const questionNo = localStorage.getItem(
        "OpenStudyAiIntegratedWritingQuestionNo"
      );
      setQuestionNumber(questionNo);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get-integrated-writing-json-file/question_${questionNo}.json`
      );
      const token = localStorage.getItem("OpenStudyAi-token");
      const getEmail = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/protected`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(getEmail.data.logged_in_as);
      setEmail(getEmail.data.logged_in_as.email);
      setDiscussionData(response.data);
    } catch (error) {
      setDiscussionData(QuestionData);
      setQuestionNumber(1);
      console.error("Error fetching question data:", error);
    }
  };

  useEffect(() => {
    getQuestion();
  }, []);

  useEffect(() => {
    const alreadyAttempted = () => {
      if (questionNum && discussionData) {
        // console.log(questionNum.substring(9));
        const res = {
          score: attemptScore,
          parameters: attemptAnswers.AIresponse.parameters,
        };
        setShowTimer(true);
        setNext(true);
        setSpeechNext(true);
        setResultData(res);
        setUserResponse(attemptAnswers.userResponse);
        setWords(attemptAnswers.userResponse.trim().split(/\s+/));
      }
    };
    alreadyAttempted();
  }, [discussionData]);
  
  if (!discussionData) {
    return <Loading />;
  }

  // Handle change in the textarea
  const handleChange = (event) => {
    setUserResponse(event.target.value);
    setWords(event.target.value.trim().split(/\s+/));
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/integrated-writing-response`,
        { userResponse, questionNumber }
      );
      setResultData(res.data); // Set result data after submission
      setIsSubmitting(false);
      const currentDateTime = new Date().toISOString();
      const responseSave = {
        userResponse: userResponse,
        AIresponse: res.data,
      };
      const newtry = {
        Answer: responseSave,
        score: res.data.score,
        timestamp: currentDateTime,
      };
      const data = {
        Email: email,
        Section: `TOEFL#integratedWriting#${questionNumber}`,
        Score: res.data.score,
        NewTry: newtry,
      };
      if (!questionNum) {
        try {
          // Send POST request to the Flask backend
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/send-integrated-writing-response`,
            data, // Include the formatted data as the request body
            {
              headers: {
                "Content-Type": "application/json", // Ensure correct content type
              },
            }
          );
          // Handle successful response
          console.log("Response:", response.data);
        } catch (error) {
          // Handle errors if the request fails
          console.error("Error sending data:", error);
        }
      }
      if (words.length <= 50) {
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 10000);
      }
      setShowTimer(true)
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error submitting response:", error);
    }
  };
  const handletestRetake = () => {
    setShowTimer(false)
    setNext(false)
    setSpeechNext(false)
    setUserResponse("");
    setIsSubmitting(false);
    setResultData("");
    setWords([""]);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleNext=()=>{
    if (!next) {
      setNext(true);
    }
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <Helmet>
        <title>Integrated-Writing-Task question{questionNumber}</title>
        <meta
          name="description"
          content="Integrated Writing Task: Try our free test to practice integrating information from reading passages and listening materials into cohesive essays. Benefit from instant AI-driven feedback to enhance your ability to synthesize and articulate complex ideas clearly. See how our intelligent system can help you master integrated writing for improved TOEFL performance."
        />
        <link rel="canonical" href="/academic-discussion-task" />
      </Helmet>
      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <NavLink to="/dashboard">
            <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
          </NavLink>
          <NavLink
            to="/dashboard"
            className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
          >
            Back to Home
          </NavLink>
        </div>
      </header>
      {speechNext && next && !showTimer && <CountdownTimer time={20} />}
      {showPopup && (
        <Popup
          limit={100}
          count={words.length === 1 && words[0] === "" ? 0 : words.length}
          handleClosePopup={handleClosePopup}
        />
      )}
      <div
        className={`min-h-[calc(100vh-170px)] pb-4 px-4 ${
          (!speechNext || showTimer) && "pt-[40px]"
        } `}
      >
        <div className="max-w-[95rem] mx-auto bg-white p-8 rounded-lg shadow-lg flex h-[calc(100vh-150px)]">
          {!speechNext && next ? (
            <div className="w-full h-full flex justify-center items-center">
              <Speech
                setSpeechNext={setSpeechNext}
                questionNumber={questionNumber}
              />
            </div>
          ) : (
            <>
              <div className="w-1/2 pr-4 h-full overflow-y-auto">
                {next && (
                  <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6">
                    <div className="text-base mb-2 text-justify">
                      <span className="text-md font-semibold ">
                        Direction:{" "}
                      </span>
                      Read the question below. You have 20 minutes to plan and
                      write your response. Your response will be judged on the
                      basis of the quality of your writing and on how well your
                      response presents the points in the lecture and their
                      relationship to the reading passage. Typically, an
                      effective response will be 150 to 225 words. An effective
                      response will contain at least 100 words.
                    </div>
                    <div className="text-base text-justify">
                      <span className="text-md font-semibold">Question: </span>
                      Summarize the points made in the lecture, being sure to
                      explain how the oppose specific points made on the reading
                      passage.
                    </div>
                  </div>
                )}
                <h1
                  className={`text-center text-xl font-semibold ${
                    !next ? "mb-3" : "my-3"
                  }`}
                >
                  {discussionData.title}
                </h1>
                {!next && (
                  <div className="flex justify-center mb-4">
                    <button
                      className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                      onClick={handleNext}
                    >
                      Listen to Lecture
                    </button>
                  </div>
                )}
                {discussionData?.passage.map((response, index) => (
                  <div key={index} className=" p-1 text-justify mb-2">
                    <p className="text-lg">{response}</p>
                  </div>
                ))}
              </div>

              {resultData ? (
                <div className="w-1/2 pl-4 h-full gap-3 flex flex-col">
                  <div className="overflow-y-auto">
                    {/* User Response */}
                    <div className="mb-6">
                      <div className="flex justify-between mb-4">
                        <h3 className="text-xl font-semibold">Your Response</h3>
                        <p className="text-md mt-1 mr-1">
                          word Count:{" "}
                          {words.length === 1 && words[0] === ""
                            ? 0
                            : words.length}
                        </p>
                      </div>
                      <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                        <p className="text-lg">{userResponse}</p>
                      </div>
                    </div>

                    {/* Score and Tips */}
                    <div className="mb-6">
                      <h3 className="text-xl font-semibold mb-4">Feedback</h3>
                      <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                        <h4 className="text-lg font-semibold">
                          Score: {resultData?.score}/5
                        </h4>
                        <h4 className="text-lg font-semibold mb-2">
                          Parameters:
                        </h4>
                        <table className="w-full border border-gray-300">
                          <thead>
                            <tr>
                              <th className="border-b px-4 py-2 text-left w-1/3">
                                Parameter
                              </th>
                              <th className="border-b px-4 py-2 text-left w-2/3">
                                Description
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="border-b px-4 py-2 font-semibold">
                                Accuracy and Completeness of Content
                              </td>
                              <td className="border-b px-4 py-2">
                                {
                                  resultData?.parameters[
                                    "Accuracy and Completeness of Content"
                                  ]
                                }
                              </td>
                            </tr>
                            <tr>
                              <td className="border-b px-4 py-2 font-semibold">
                                Clarity and Coherence
                              </td>
                              <td className="border-b px-4 py-2">
                                {
                                  resultData?.parameters[
                                    "Clarity and Coherence"
                                  ]
                                }
                              </td>
                            </tr>
                            <tr>
                              <td className="border-b px-4 py-2 font-semibold">
                                Connection Between Lecture and Reading
                              </td>
                              <td className="border-b px-4 py-2">
                                {
                                  resultData?.parameters[
                                    "Connection Between Lecture and Reading"
                                  ]
                                }
                              </td>
                            </tr>
                            <tr>
                              <td className="border-b px-4 py-2 font-semibold">
                                Language Use and Grammar
                              </td>
                              <td className="border-b px-4 py-2">
                                {
                                  resultData?.parameters[
                                    "Language Use and Grammar"
                                  ]
                                }
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {/* Sample Responses */}
                    <div>
                      <h3 className="text-xl font-semibold mb-4">
                        Sample Responses
                      </h3>
                      {discussionData?.responses.map((response, index) => (
                        <div
                          key={index}
                          className="bg-gray-100 p-4 rounded-lg shadow-md mb-4"
                        >
                          <h4 className="text-lg font-semibold mb-2">
                            Sample Response {index + 1}
                          </h4>
                          {response.response.map((text, idx) => (
                            <div key={idx}>
                              <p className="text-md ">{text}</p>
                              <br />
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                  <button
                    onClick={handletestRetake}
                    className="bg-teal-600 text-white px-4 py-2 rounded-md hover:bg-teal-700 transition duration-200"
                  >
                    Retake Test
                  </button>
                </div>
              ) : (
                <>
                  {/* User Response Form */}
                  {isSubmitting ? (
                    <div className="flex flex-col items-center justify-center w-1/2 h-full bg-gray-100 ml-3 rounded-lg">
                      <div className="w-12 h-12 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin mb-5"></div>
                      <p className="text-lg text-center text-gray-600">
                        Grading in progress...
                        <br />
                        Please Wait
                      </p>
                    </div>
                  ) : (
                    <div className="w-1/2 pl-4 h-full flex flex-col">
                      <div className="flex justify-between mb-4">
                        <h3 className="text-xl font-semibold">Your Response</h3>
                        <p className="text-md mt-1">
                          word Count:{" "}
                          {words.length === 1 && words[0] === ""
                            ? 0
                            : words.length}
                        </p>
                      </div>
                      <textarea
                        value={userResponse}
                        onChange={handleChange}
                        rows="10"
                        className="p-4 border border-gray-300 rounded-lg mb-4 flex-grow resize-none"
                        placeholder="Write your answer here..."
                        disabled={!next}
                      />
                      <button
                        onClick={handleSubmit}
                        disabled={isSubmitting || !next}
                        className={`px-4 py-2 rounded-md transition duration-200 ${
                          next
                            ? "bg-teal-600 text-white hover:bg-teal-700"
                            : "bg-gray-400 text-gray-700 cursor-not-allowed"
                        }`}
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default IntegratedWriting;