import {  NavLink } from "react-router-dom";
import { IoIosMail } from "react-icons/io";
import { FaDiscord } from "react-icons/fa";
import { MdFeedback } from "react-icons/md";
const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto text-center">
        <p>&copy; 2024 OpenStudyAI. All rights reserved.</p>
        <div className="mt-2 flex justify-center">
          <NavLink
            to="/privacy-policy"
            className="text-gray-400 hover:text-white mx-2 bg-transparent border-none cursor-pointer"
          >
            Privacy Policy
          </NavLink>
          <NavLink
            to="/terms-services"
            className="text-gray-400 hover:text-white mx-2 bg-transparent border-none cursor-pointer"
          >
            Terms of Service
          </NavLink>
          <NavLink
            to="/contacts"
            className="text-gray-400 hover:text-white mx-2 bg-transparent border-none cursor-pointer "
          >
            Contact Us{" "}
          </NavLink>
          <div className="flex items-center gap-1">
            <div className=" ">
              <a
                href="mailto:mockxbytechnoverse@gmail.com"
                className="text-gray-400 hover:text-white "
              >
                <div className="text-lg">
                  <IoIosMail />
                </div>
              </a>
            </div>
            <div className=" ">
              <a
                href="https://forms.gle/BopWYfDohLpAdtxj9"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white"
              >
                <MdFeedback />
              </a>
            </div>
            <div className="">
              <a
                href="https://discord.gg/JFTwXm2Rgt"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white"
              >
                <FaDiscord />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
