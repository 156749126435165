
import axios from 'axios';
import React, { useState,useEffect } from "react";
import ReadingText from "./ReadingText";
import Question from "./Question";
import Navigation from "./Navigation";
import questionData from "./question_1.json"; // Assume questionsData.js contains your JSON data
import { NavLink } from "react-router-dom";
import Loading from '../LoadingPage/Loading';
import { Helmet } from 'react-helmet-async';
// import Logout from "../Login/Logout";
import CountdownTimer from "../Timer/CountdownTimer";
import { useAtom } from "jotai";
import {
  questionAtom,
  attemptIndexAtom,
  answersAtom,
  scoreAtom,
} from "../atom";
const ReadingLandingPage = () => {
   const [questionNum, setQuestionNum] = useAtom(questionAtom);
   const [attemptIndex, setAttemptIndex] = useAtom(attemptIndexAtom);
   const [attemptAnswers, setAttemptAnswers] = useAtom(answersAtom);
   const [attemptScore, setAttemptScore] = useAtom(scoreAtom);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [scores, setScore] = useState(0);
  const [partialScore, setPartialScore] = useState(0);
  const [questionsData,setQuestionsData]=useState(null)
  const [displayAnswers,setDisplayAnswers]=useState({});
  const [questionNumber,setQuestionNumber]=useState(1);
  const [email,setEmail]=useState("");
      const getQuestion=async()=>{
        console.log(questionNum, attemptIndex, attemptAnswers, attemptScore);
        try {
          const questionNo = localStorage.getItem("OpenStudyAiQuestionNo");
          if(questionNo)
          setQuestionNumber(questionNo);
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/get-json/question_${questionNo}.json`
          );
          const token = localStorage.getItem("OpenStudyAi-token");
          const getEmail = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/protected`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(getEmail.data.logged_in_as);
          setEmail(getEmail.data.logged_in_as.email);
          setQuestionsData(response.data);
        } catch (error) {
          setQuestionsData(questionData);
          console.error("Error fetching question data:", error);
        }
      }
      useEffect(() => {
        getQuestion()
      }, []);
      useEffect(()=>{
        const alreadyAttempted = () => {
          if (attemptScore && questionsData) {
            setAnswers(attemptAnswers);
            handleSubmit();
          }
        };
        alreadyAttempted();
      },[questionsData])

  const handleAnswer = (questionNumber, answer) => {
    setAnswers({
      ...answers,
      [questionNumber]: answer,
    });
  };

  const handleNavigation = (index) => {
    setCurrentQuestionIndex(index);
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    setCurrentQuestionIndex(0);
    setDisplayAnswers(answers);
    score();
  };
  const score = async() => {
    let x = 0;
    const ansArray=[];
    ansArray[9]="";
    for (let i = 1; i < 10; i++) {
      const userAnswer = answers[i];
      const isCorrect =
        questionsData?.questions[i - 1].correct_answers.includes(userAnswer);
      if (isCorrect) x++;
      answers[i]?ansArray[i-1]=answers[i]:ansArray[i-1]="";
    }
    let y = 0;

    if (answers[10]) {
      if (answers[10].length > 1) answers[10].sort();
      for (let i = 0; i < answers[10].length; i++) {
        ansArray[9]+=answers[10][i];
        if (questionsData?.questions[9].correct_answers.includes(answers[10][i]))
          y++;
      }
    }
    if (y === 1) {
      x += 0.67;
      setPartialScore(1);
    } else if (y === 2) {
      x += 1.33;
      setPartialScore(2);
    } else if (y === 3) {
      x+=2;
      setPartialScore(3);
    }
    x = x.toFixed(2);
    setScore(x);
    const currentDateTime = new Date().toISOString();
    const newtry = {
      Answers: answers,
      score: x,
      timestamp: currentDateTime,
    };
    const data = {
      Email: email,
      Section: `TOEFL#reading#${questionNumber}`,
      Score: x,
      NewTry: newtry,
    };
    if (!attemptScore){
      try {
        // Send POST request to the Flask backend
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/result-send`,
          data,  // Include the formatted data as the request body
          {
            headers: {
              'Content-Type': 'application/json',  // Ensure correct content type
            },
          }
        );
        // Handle successful response
        console.log('Response:', response.data);
      } catch (error) {
        // Handle errors if the request fails
        console.error("Error sending data:", error);
      }
    }
  };

  const renderExplanation = (question) => {
    const userAnswer = displayAnswers[question.question_number];
    const isCorrect = question.correct_answers.includes(userAnswer);
    return (
      <div className="mt-4 mr-2 text-justify p-4 border-t">
        {question.question_number !== 10 ? (
          <p
            className={`text-lg ${
              isCorrect ? "text-green-600" : "text-red-600"
            }`}
          >
            {isCorrect ? "Correct!" : "Incorrect."}
          </p>
        ) : (
          <p
            className={`text-lg ${
              partialScore === 1 || partialScore === 2
                ? "text-yellow-500"
                : partialScore === 3
                ? "text-green-600"
                : "text-red-600"
            }`}
          >
            {partialScore === 1 || partialScore === 2
              ? "Partially Correct"
              : partialScore === 3
              ? "Correct!"
              : "Incorrect."}
          </p>
        )}
        {!isCorrect && (
          <p className="text-md text-gray-600">
            Correct Answer:{" "}
            {question.question_number === 10 ? (
              <span>
                {question.correct_answers[0]},{question.correct_answers[1]},
                {question.correct_answers[2]}
              </span>
            ) : (
              question.correct_answers
            )}
          </p>
        )}
        {!isCorrect && (
          <p className="text-md text-gray-600">
            Your Answer:{" "}
            {userAnswer ? (
              question.question_number === 10 ? (
                userAnswer.length === 0 ? (
                  <span>No Selection</span>
                ) : (
                  userAnswer.map((a, i) =>
                    i === 0 ? <span>{a}</span> : <span>,{a}</span>
                  )
                )
              ) : (
                userAnswer
              )
            ) : (
              <span>No Selection</span>
            )}
          </p>
        )}
        <p className="text-md mt-2 text-gray-600">{question.explanation}</p>
      </div>
    );
  };
  if (!questionsData || !questionsData.questions) {
    return <Loading/>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <Helmet>
        <title>Reading-task question{questionNumber}</title>
        <meta
          name="description"
          content="Reading Section: Reading Task: Try our free test to practice analyzing and understanding various reading passages. Benefit from instant AI-driven feedback to enhance your reading comprehension and interpretation skills. See how our intelligent system can help you master the reading section for improved TOEFL performance."
        />
        <link rel="canonical" href="/reading-landing-page" />
      </Helmet>
      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <NavLink to="/dashboard">
            <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
          </NavLink>
          <NavLink
            to="/dashboard"
            className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
          >
            Back to Home
          </NavLink>
        </div>
      </header>
      {!isSubmitted && <CountdownTimer time={18} />}
      <div
        className={`min-h-[calc(100vh-170px)] ${
          isSubmitted && "pt-[50px]"
        } pb-4 px-4`}
      >
        <div className="max-w-[95rem] mx-auto bg-white p-8 rounded-lg shadow-lg flex h-[calc(100vh-205px)] overflow-hidden">
          <div className="w-1/2 pr-4 h-full overflow-y-auto">
            <ReadingText
              title={questionsData.title}
              textContent={questionsData.text_content}
              questionType={
                questionsData.questions[currentQuestionIndex].question_type
              }
              highlightText={
                questionsData.questions[currentQuestionIndex]?.highlight_text
              }
              highlightWord={
                questionsData.questions[currentQuestionIndex]?.highlight_word
              }
              startSentence={
                questionsData.questions[currentQuestionIndex]?.start_sentence
              }
            />
          </div>
          <div className="w-1/2 pl-4 h-full flex flex-col justify-between overflow-y-auto">
            <Question
              currentQuestionIndex={currentQuestionIndex}
              question={questionsData.questions[currentQuestionIndex]}
              questionType={
                questionsData.questions[currentQuestionIndex].question_type
              }
              highlightText={
                questionsData.questions[currentQuestionIndex]?.highlight_text
              }
              highlightWord={
                questionsData.questions[currentQuestionIndex]?.highlight_word
              }
              statement={
                questionsData.questions[currentQuestionIndex]?.statement
              }
              startSentence={
                questionsData.questions[currentQuestionIndex]?.start_sentence
              }
              isSubmitted={isSubmitted}
              onAnswer={handleAnswer}
              userAnswer={
                answers[
                  questionsData.questions[currentQuestionIndex].question_number
                ]
              }
            />
            {isSubmitted &&
              renderExplanation(questionsData.questions[currentQuestionIndex])}
          </div>
        </div>

        <Navigation
          totalQuestions={questionsData.questions.length}
          currentIndex={currentQuestionIndex}
          onNavigate={handleNavigation}
          onSubmit={handleSubmit}
          isSubmitted={isSubmitted}
          scores={scores}
        />
      </div>
    </div>
  );
};

export default ReadingLandingPage;
