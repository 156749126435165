// ExerciseContext.js
import React, { createContext, useContext, useState } from 'react';

// Create a Context for the exercise
const ExerciseContext = createContext();

// Custom hook to use the Exercise context
export const useExercise = () => {
  const context = useContext(ExerciseContext);
  if (!context) {
    throw new Error('useExercise must be used within an ExerciseProvider');
  }
  return context;
};

// Provider component that holds global state
export const ExerciseProvider = ({ children }) => {
  const [exerciseData, setExerciseData] = useState(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [submissionResult, setSubmissionResult] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [error, setError] = useState(null);
  const [selectedVoice, setSelectedVoice] = useState(null); // Add speech synthesis state

  return (
    <ExerciseContext.Provider
      value={{
        exerciseData,
        setExerciseData,
        userAnswers,
        setUserAnswers,
        submissionResult,
        setSubmissionResult,
        currentQuestionIndex,
        setCurrentQuestionIndex,
        audioPlayed,
        setAudioPlayed,
        error,
        setError,
        selectedVoice,  // Add speech state to context
        setSelectedVoice, // Provide a way to update the selected voice
      }}
    >
      {children}
    </ExerciseContext.Provider>
  );
};