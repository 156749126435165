import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
const SpeakingAttempts = () => {
  const navigate = useNavigate();

  const taskButtons = [
    {
      title: "View Task 1 Grades - Independent Speaking",
      description: "Check your grades for expressing opinions on familiar topics.",
    },
    {
      title: "View Task 2 Grades - Integrated Speaking",
      title_description: "(Read/Listen/Speak)",
      description: "View your grades for summarizing and relating information from a reading and a listening passage.",
    },
    {
      title: "View Task 3 Grades - Integrated Speaking",
      title_description: "(Listen/Speak - Campus Situation)",
      description: "Review your grades for summarizing a conversation and expressing opinions on campus-related issues.",
    },
    {
      title: "View Task 4 Grades - Integrated Speaking",
      title_description: "(Listen/Speak - Academic Course)",
      description: "See your grades for summarizing and explaining points from an academic lecture.",
    },
  ];

  useEffect(() => {
    localStorage.setItem("OpenStudyAi-hasVisited", "true");
  }, []);

  const handleTask = (index) => {
    localStorage.setItem("OpenStudyAiSpeakingTaskNo", index + 1);
    navigate("/displaySpeakingAttempts", {
      state: { section: index + 1 },
    });
  };

  const renderButton = (task, index) => {
    return (
      <button
        key={index}
        onClick={() => handleTask(index)}
        className="w-full flex"
      >
        <div
          className={`bg-amber-100 transition-colors duration-300 rounded-lg shadow-md p-6 w-full flex flex-col min-h-[170px] ${
            index === 0 ? "justify-center" : "justify-between"
          }`}
        >
          <div>
            <h3
              className={`text-xl font-bold text-amber-800 ${
                index === 0 ? "pb-10" : ""
              }`}
            >
              {task.title}
            </h3>
            <h3 className={`text-sm font-bold text-amber-800 `}>
              {task.title_description}
            </h3>
          </div>
          <p className="text-amber-700">{task.description}</p>
        </div>
      </button>
    );
  };

  // Function to detect Chrome and desktop device
  const isChrome = () => {
    return (
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    );
  };

  const isDesktop = () => {
    return window.innerWidth > 1024; // Check for desktop based on screen size
  };

  // Use useEffect to check on component mount
  useEffect(() => {
    const hasSeenPopupThisSession = sessionStorage.getItem(
      "hasSeenPopupThisSession"
    );

    // Show the popup only if the user hasn't seen it this session
    if (!hasSeenPopupThisSession) {
      if (!isChrome() || !isDesktop()) {
        alert(
          "For a better experience, please switch to Chrome on a desktop device."
        );
        sessionStorage.setItem("hasSeenPopupThisSession", "true"); // Set flag to true after showing popup
      }
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Speaking</title>
        <meta
          name="description"
          content="Speaking Section: Practice the TOEFL Speaking section to improve your ability to communicate clearly and effectively in English. This section includes four tasks: an independent task where you express your opinion on familiar topics, and three integrated tasks that challenge you to read, listen, and respond. Our practice exercises will help you enhance your fluency, coherence, and ability to synthesize information under time constraints."
        />
        <link rel="canonical" href="/Speaking" />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
        <header className="bg-white p-4 shadow-md">
          <div className="container mx-auto flex justify-between items-center">
            <NavLink to="/dashboard">
              <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
            </NavLink>
            <NavLink
              to="/dashboard"
              className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
            >
              Back to Home
            </NavLink>
          </div>
        </header>
        <main className="flex-grow flex items-center justify-center px-4 py-12">
          <div className="w-full max-w-5xl space-y-8">
            <div className="bg-white rounded-lg shadow-md p-8">
              <h2 className="text-2xl font-bold text-gray-800 mb-6">
                Select a Speaking Task
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {taskButtons.map((task, index) => renderButton(task, index))}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SpeakingAttempts;
