import React, { useEffect, useRef, useState } from "react";

const Speech = ({ setSpeechNext ,questionNumber}) => {
  const audioRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      // Play the audio when the component mounts
      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
      });

      // Update progress and remaining time
      const updateProgress = () => {
        if (audio.duration) {
          const percent = (audio.currentTime / audio.duration) * 100;
          setProgress(percent);

          const timeRemaining = audio.duration - audio.currentTime;
          setRemainingTime(timeRemaining);
        }
      };

      audio.addEventListener("timeupdate", updateProgress);

      // Optionally, hide or remove the audio element after it finishes playing
      audio.onended = () => {
        setSpeechNext(true);
        // You can hide the audio element if needed
        audio.style.display = "none";
      };

      // Cleanup event listeners on component unmount
      return () => {
        audio.removeEventListener("timeupdate", updateProgress);
      };
    }
  }, [setSpeechNext]);

  // Function to format seconds into MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div className="min-w-[90%]">
    <div className="bg-gray-100 border border-gray-300 rounded-lg p-4 mb-2">
      <audio
        ref={audioRef}
        src={"/integratedwritingspeech/question_"+questionNumber+".mp3"}
        type="audio/mp3"
      />
      <div
        style={{
          position: "relative",
          height: "10px",
          width: "100%",
          backgroundColor: "#ccc",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: `${progress}%`,
            backgroundColor: "#4caf50",
            borderRadius: "5px", 
            transition: "width 1s linear",
          }}
        />
      </div>
      <div className="mt-[10px] text-center text-lg font-semibold">
        Professor's lecture - Remaining Time: {formatTime(remainingTime)}
      </div>
      </div>
    </div>
  );
};

export default Speech;
