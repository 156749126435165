// src/Speaking/Speaking_task.jsx
import React, { useEffect, useState, useCallback} from 'react';
import NavBar from './NavBar';
import axios from 'axios';
import Loading from "../LoadingPage/Loading";
import { Helmet } from 'react-helmet-async';

import Task1 from './Tasks/Task1';
import Task2 from './Tasks/Task2';
import Task3 from './Tasks/Task3';
import Task4 from './Tasks/Task4';

const SpeakingTask = () => {
    //const [taskNo, setTaskNo] = useState(localStorage.getItem("OpenStudyAiSpeakingTaskNo") || '1');
    const taskNo = localStorage.getItem("OpenStudyAiSpeakingTaskNo") || '1';
    const [examNum, setExamNum] = useState(localStorage.getItem("OpenStudyAiSpeakingExamNum") || '1');
    const [taskData, setTaskData] = useState(null);

    const [title, setTitle] = useState("");
    const [instructions, setInstructions] = useState("");
    const [prompt, setPrompt] = useState("");
    const [audioLength, setAudioLength] = useState(0);
    const [article, setArticle] = useState('');
    const [articleTitle, setArticleTitle] = useState('');
    const [audioSrc, setAudioSrc] = useState('');
    const [llmContext, setLlmContext] = useState('');

    const [sampleResponse1, setSampleResponse1] = useState('');
    const [sampleResponse2, setSampleResponse2] = useState('');


    const getTask = useCallback(async () => {
        try {
            if (examNum) {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/api/get-speaking-task/tasks_${examNum}.json`
                );
                setTaskData(response.data);
            }
        } catch (error) {
            console.error("Error fetching task data:", error);
        }
    }, [examNum]);
    
    useEffect(() => {
        getTask();
    }, [examNum, getTask]);

    useEffect(() => {
        const setTaskDataElements = (data) => {
            if (data && data[taskNo]) {
                setTitle(data[taskNo].title || "");
                setInstructions(data[taskNo].instructions || "");
                setAudioLength(data[taskNo].audioLength || 0);
                setPrompt(data[taskNo].prompt || "");
                setArticle(data[taskNo].article || "");
                setArticleTitle(data[taskNo].articleTitle || "");
                setAudioSrc(data[taskNo].audioSrc || "");
                setLlmContext(data[taskNo].llmContext || "");
                setSampleResponse1(data[taskNo].sample_resp_1 || "");
                setSampleResponse2(data[taskNo].sample_resp_2 || "");
                
                if (audioSrc) {
                    setAudioSrc(process.env.PUBLIC_URL + audioSrc);
                }
            } else {
                console.error(taskNo, "Data or task number is undefined.");
            }
        };
    
        if (taskData) {
            setTaskDataElements(taskData);
        }
    }, [taskData, audioSrc, taskNo]);

    const updateExamNum = (newExamNum) => {
        setExamNum(newExamNum);
        localStorage.setItem("OpenStudyAiSpeakingExamNum", newExamNum);
        getTask(); // Fetch new task data
        window.location.reload();
    };

    if(!taskData) {
        return <Loading />;
    }

    const renderTaskComponent = () => {
        switch (taskNo) {
            case '1':
                return <Task1
                    title={title}
                    instructions={instructions}
                    prompt={prompt}
                    examNum={examNum}
                    setExamNum={updateExamNum}
                    sampleResponse1={sampleResponse1}
                    sampleResponse2={sampleResponse2}
                />;
            case '2':
                return <Task2
                    title={title}
                    instructions={instructions}
                    article={article}
                    articleTitle={articleTitle}
                    audioSrc={audioSrc}
                    prompt={prompt}
                    llmContext={llmContext}
                    audioLength={audioLength}
                    examNum={examNum}
                    setExamNum={updateExamNum}
                    sampleResponse1={sampleResponse1}
                    sampleResponse2={sampleResponse2}
                />;
            case '3':
                return <Task3
                    title={title}
                    instructions={instructions}
                    prompt={prompt}
                    audioLength={audioLength}
                    article={article}
                    articleTitle={articleTitle}
                    llmContext={llmContext}
                    audioSrc={audioSrc}
                    examNum={examNum}
                    setExamNum={updateExamNum}
                    sampleResponse1={sampleResponse1}
                    sampleResponse2={sampleResponse2}
                />;
            case '4':
                return <Task4
                    title={title}
                    instructions={instructions}
                    audioSrc={audioSrc}
                    audioLength={audioLength}
                    prompt={prompt}
                    llmContext={llmContext}
                    examNum={examNum}
                    setExamNum={updateExamNum}
                    sampleResponse1={sampleResponse1}
                    sampleResponse2={sampleResponse2}
                />;
            default:
                return <div>No task loaded, restart.</div>;
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
            <Helmet>
            <title>
                Speaking task{taskNo} version{examNum}
            </title>
            {/* <meta
                name="description"
                content="Speaking Section: Practice the TOEFL Speaking section to improve your ability to communicate clearly and effectively in English. This section includes four tasks: an independent task where you express your opinion on familiar topics, and three integrated tasks that challenge you to read, listen, and respond. Our practice exercises will help you enhance your fluency, coherence, and ability to synthesize information under time constraints."
            /> */}
            <link rel="canonical" href="/SpeakingLandingPage" />
            </Helmet>
            <NavBar />
            {renderTaskComponent()}
        </div>
    );
};

export default SpeakingTask;