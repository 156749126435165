import React, { useEffect, useRef } from "react";



const SingleMCQ=({ title, textContent})=>{
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4 text-center">{title}</h1>
      {textContent
        .join(" ")
        .split("\n\n")
        .map((paragraph, index) => (
          <p key={index} className="text-justify mb-4">
            {paragraph}
          </p>
        ))}
    </div>
  );
}

const HighlightTextSingleMCQ = ({ title, textContent, highlightText }) => {
  const highlightRef = useRef(null);

  useEffect(() => {
    if (highlightRef.current) {
      highlightRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const getHighlightedParagraph = (paragraph) => {
    if (paragraph.includes(highlightText)) {
      const parts = paragraph.split(highlightText);
      return (
        <>
          {parts[0]}
          <span ref={highlightRef} className="bg-yellow-300">
            {highlightText}
          </span>
          {parts[1]}
        </>
      );
    }
    return paragraph;
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4 text-center">{title}</h1>
      {textContent
        .join(" ")
        .split("\n\n")
        .map((paragraph, index) => (
          <p key={index} className=" text-justify mb-4">
            {getHighlightedParagraph(paragraph)}
          </p>
        ))}
    </div>
  );
};

const HighlightWordSingleMCQ = ({ title, textContent, highlightWord }) => {
  const highlightRef = useRef(null);

  useEffect(() => {
    if (highlightRef.current) {
      highlightRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const getHighlightedParagraph = (paragraph) => {
    if (paragraph.includes(highlightWord)) {
      const parts = paragraph.split(highlightWord);
      return (
        <>
          {parts[0]}
          <span ref={highlightRef} className="bg-yellow-300">
            {highlightWord}
          </span>
          {parts[1]}
        </>
      );
    }
    return paragraph;
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4 text-center">{title}</h1>
      {textContent
        .join(" ")
        .split("\n\n")
        .map((paragraph, index) => (
          <p key={index} className="text-justify mb-4">
            {getHighlightedParagraph(paragraph)}
          </p>
        ))}
    </div>
  );
};

const InsertTextMCQ = ({ title, textContent, startSentence,boxI }) => {
  const firstBoxRef = useRef(null);
  useEffect(() => {
    if (firstBoxRef.current) {
      firstBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    
  }, []);
  
  const insertBoxes = (text) => {
    const sentences = text.split('. ');
    const boxes = ['Box 1', 'Box 2', 'Box 3', 'Box 4'];
    // let boxIndex = 0;
    let startSentenceFound = false;
    if(startSentence[startSentence.length-1]==='.'){
      startSentence = startSentence.substring(0, startSentence.length-1);
    }
    return sentences.map((sentence, index) => {
      if (boxI >= boxes.length)
        return (
          <span key={index}>
            {index===sentences.length-1?sentence:sentence+". "}
           
          </span>
        );
      if (sentence.includes(startSentence)) {
        
        startSentenceFound = true;
        return (
          <span key={index}>
            <span
              ref={boxI === 0 ? firstBoxRef : null}
              className="bg-gray-400 p-1 mx-1"
            >
              {boxes[boxI++]}
            </span>
            {index === sentences.length - 1 ? sentence : sentence + ". "}
            <span className="bg-gray-400 p-1 mx-1">{boxes[boxI++]}</span>{" "}
          </span>
        );
      }

      if (startSentenceFound || boxI>0) {
        
        return (
          <span key={index}>
            {index === sentences.length - 1 ? sentence : sentence + ". "}
            <span className="bg-gray-400 p-1 mx-1">{boxes[boxI++]}</span>{" "}
          </span>
        );
      }

      return (
        <span key={index}>
          {index === sentences.length - 1 ? sentence : sentence + ". "}
        </span>
      );
    });
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4 text-center">{title}</h1>
      {textContent
        .join(" ")
        .split("\n\n")
        .map((paragraph, index) => (
          <p key={index} className="text-justify mb-4">
            {insertBoxes(paragraph)}
          </p>
        ))}
    </div>
  );
};



const ReadingText = ({
  title,
  textContent,
  questionType,
  highlightText,
  highlightWord,
  startSentence,
}) => {
  if (
    questionType === "Single_MCQ" ||
    questionType === "Factual_Information" ||
    questionType === "Inference" ||
    questionType === "Rhetorical_Purpose" ||
    questionType === "Summary_MCQ" || 
    questionType === "Prose_Summary"
  )
    return <SingleMCQ title={title} textContent={textContent} />;
  else if (
    questionType === "Highlight_Text_Single_MCQ" ||
    questionType === "Sentence_Simplification"
  )
    return (
      <HighlightTextSingleMCQ
        title={title}
        textContent={textContent}
        highlightText={highlightText}
      />
    );
  else if (
    questionType === "Highlight_Word_Single_MCQ" ||
    questionType === "Vocabulary"
  )
    return (
      <HighlightWordSingleMCQ
        title={title}
        textContent={textContent}
        highlightWord={highlightWord}
      />
    );
  else if (
    questionType === "Insert_Text_MCQ" ||
    questionType === "Insert_Text"
  ) {
    return (
      <InsertTextMCQ
        title={title}
        textContent={textContent}
        startSentence={startSentence}
        boxI={0}
      />
    );
  } else return <div>error</div>;
};

export default ReadingText;
