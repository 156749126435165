import { createContext, useContext, useState } from "react";
const dataContext = createContext();
const DataProvider = ({ children }) => {
  const [storedToken, setStoredToken] = useState(
    "JSON.parse(localStorage.getItem(Mock-Token))"
  );
  const [questionNo,setQuestionNo] = useState(0);

  return (
    <dataContext.Provider
      value={{
        storedToken,
        setStoredToken,
        questionNo,
        setQuestionNo,
      }}
    >
      {children}
    </dataContext.Provider>
  );
};
export const DataState = () => {
  return useContext(dataContext);
};

export default DataProvider;
