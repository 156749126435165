import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { IoIosMail } from "react-icons/io";
import { FaDiscord } from "react-icons/fa";
import { MdFeedback } from "react-icons/md"; 

const HomePage = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <Helmet>
        <title>OpenStudyAI</title>
        <meta
          name="description"
          content="Master TOEFL with OpenStudyAI - Harness the power of artificial intelligence to supercharge your TOEFL preparation with personalized learning, instant feedback, and adaptive practice sessions."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
          <nav className="flex items-center">
            <div className="relative">
              <button 
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600 transition duration-200 mr-4"
              >
                Coursework
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                  <Link 
                    to="/courses/toefl" 
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    TOEFL
                  </Link>
                </div>
              )}
            </div>
            <Link
              to="/login"
              className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
            >
              Login
            </Link>
          </nav>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 py-12">
        <section className="text-center mb-16">
          <h2 className="text-5xl font-bold text-white mb-4">
            Master TOEFL with AI
          </h2>
          <p className="text-xl text-white mb-8 max-w-2xl mx-auto">
            Harness the power of artificial intelligence to supercharge your
            TOEFL preparation. Personalized learning, instant feedback, and
            adaptive practice sessions.
          </p>
          <Link
            to="/login"
            className="bg-amber-400 text-white px-8 py-4 rounded-md text-lg font-semibold hover:bg-amber-500 transition duration-200 inline-block"
          >
            Get Started
          </Link>
        </section>

        <section className="grid md:grid-cols-3 gap-8 mb-16">
          <FeatureCard
            title="AI-Powered Practice"
            description="Our advanced AI algorithms create personalized practice sessions tailored to your strengths and weaknesses."
            icon="🧠"
          />
          <FeatureCard
            title="Instant Feedback"
            description="Receive detailed, immediate feedback on your performance to quickly identify areas for improvement."
            icon="⚡"
          />
          <FeatureCard
            title="Progress Tracking"
            description="Monitor your improvement with advanced analytics and visual progress reports."
            icon="📈"
          />
        </section>

        <section className="bg-white rounded-lg shadow-md p-8 mb-16">
          <h3 className="text-3xl font-bold text-gray-800 mb-6">
            Why Choose OpenStudyAI?
          </h3>
          <div className="grid md:grid-cols-2 gap-6">
            <BenefitItem text="Advanced AI technology that adapts to your learning style" />
            <BenefitItem text="Comprehensive coverage of all TOEFL Reading Section" />
            <BenefitItem text="Realistic practice tests that mirror the actual TOEFL exam" />
            <BenefitItem text="Currently Free (Alpha Testing Phase)" />
            <BenefitItem text="Access anytime, anywhere - study on your schedule" />
            <BenefitItem text="Continuous updates based on the latest TOEFL trends" />
          </div>
        </section>

        <section className="text-center mb-16">
          <h3 className="text-3xl font-bold text-white mb-6">
            Ready to Boost Your TOEFL Score?
          </h3>
          <Link
            to="/login"
            className="bg-amber-400 text-white px-8 py-4 rounded-md text-lg font-semibold hover:bg-amber-500 transition duration-200 inline-block"
          >
            Start Your AI-Powered Journey
          </Link>
        </section>

        <section className="bg-teal-700 rounded-lg p-8">
          <h3 className="text-2xl font-bold text-white mb-4">
            What Our Users Say
          </h3>
          <div className="grid md:grid-cols-2 gap-6">
            <TestimonialCard
              quote="OpenStudyAI transformed my TOEFL preparation. The AI-powered Reading sessions were incredibly effective!"
              author="Sarah K., Reading Score: 30"
            />
            <TestimonialCard
              quote="I've never felt more confident going into the TOEFL. Thanks to OpenStudyAI, I achieved my target score!"
              author="Mohan L., Reading Score: 29"
            />
          </div>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-6">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 OpenStudyAI. All rights reserved.</p>
          <div className="mt-2 flex justify-center">
            <NavLink
              to="privacy-policy"
              className="text-gray-400 hover:text-white mx-2 bg-transparent border-none cursor-pointer"
            >
              Privacy Policy
            </NavLink>
            <NavLink
              to="terms-services"
              className="text-gray-400 hover:text-white mx-2 bg-transparent border-none cursor-pointer"
            >
              Terms of Service
            </NavLink>
            <NavLink
              to="/contacts"
              className="text-gray-400 hover:text-white mx-2 bg-transparent border-none cursor-pointer "
            >
              Contact Us{" "}
            </NavLink>
            <div className="flex items-center gap-1">
              <div className=" ">
                <a
                  href="mailto:mockxbytechnoverse@gmail.com"
                  className="text-gray-400 hover:text-white "
                >
                  <div className="text-lg">
                    <IoIosMail />
                  </div>
                </a>
              </div>
              <div className=" ">
                <a
                  href="https://forms.gle/BopWYfDohLpAdtxj9"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white"
                >
                  <MdFeedback />
                </a>
              </div>
              <div className="">
                <a
                  href="https://discord.gg/JFTwXm2Rgt"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white"
                >
                  <FaDiscord />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ title, description, icon }) => (
  <div className="bg-white rounded-lg shadow-md p-6 text-center">
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-xl font-semibold text-gray-800 mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const BenefitItem = ({ text }) => (
  <div className="flex items-center">
    <svg className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
    </svg>
    <span className="text-gray-700">{text}</span>
  </div>
);

const TestimonialCard = ({ quote, author }) => (
  <div className="bg-white rounded-lg p-6 shadow-md">
    <p className="text-gray-600 italic mb-4">"{quote}"</p>
    <p className="text-gray-800 font-semibold">- {author}</p>
  </div>
);

export default HomePage;