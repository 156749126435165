import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";

const CourseContentLayout = ({ title, children }) => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <Helmet>
        <title>{`${title} - TOEFL Coursework - OpenStudyAI`}</title>
        <meta
          name="description"
          content={`Learn about ${title} in our comprehensive TOEFL preparation course.`}
        />
        <link rel="canonical" href={`/courses/toefl/${title.toLowerCase().replace(/\s+/g, '-')}`} />
      </Helmet>
      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <Link to="/">
            <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
          </Link>
          <Link
            to="/courses/toefl"
            className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
          >
            Back to TOEFL Coursework
          </Link>
        </div>
      </header>
      <main className="flex-grow container mx-auto px-4 py-8">
        <h2 className="text-3xl font-bold text-white mb-6">{title}</h2>
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="prose max-w-none">
            {children}
          </div>
        </div>
      </main>
    </div>
  );
};

export default CourseContentLayout;