import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [isloading, setIsLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm();

    // Handles email submission for verification code
    const onSubmitEmail = async (data) => {
        try {
            setIsLoading(true)
            setTimeout(()=>{console.log("jgvj")},10000)
            console.log('Submitting email:', data.email);  // Debugging log
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/forgot-password`, { email: data.email });

            console.log('API Response:', response.data.status);  // Debugging log
            if (response.data.status === "success") {
                setEmail(data.email);
                setStep(2);  // Move to the next step after successful email submission
            }
            setIsLoading(false)
        } catch (error) {
            console.error('Error in API call:', error);  // Debugging log
            setError('Failed to send verification code. Please try again.');
            setIsLoading(false)
        }
    };

    // Handles the verification code and new password submission
    const onSubmitPassword = async (data) => {
        const { verificationCode, newPassword, confirmPassword } = data;
        setIsLoading(true)
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            console.log('Submitting verification code and new password:', { email, verificationCode, newPassword });  // Debugging log
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/confirm-forgot-password`, {
                email: email,
                verificationCode: verificationCode,
                newPassword: newPassword
            });

            console.log('Password reset response:', response.data);  // Debugging log
            if (response.data.status === 'success') {
                setError('Password changed successfully. You can now log in.');
                setStep(1);  // Reset the flow after successful password change
                navigate("/login");
            } else {
                setError(response.data.message);
            }
            setIsLoading(false)
        } catch (error) {
            console.error('Error in password reset:', error);  // Debugging log
            setError('Failed to reset the password. Please try again.');
            setIsLoading(false)
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
            <Helmet>
                <title>Forgot Password</title>
                <meta name="description" content="Reset your password for OpenStudyAI." />
            </Helmet>
            <header className="bg-white p-4 shadow-md flex justify-center items-center">
                <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
            </header>

            <main className="flex-grow flex items-center justify-center px-4 py-12">
                <div className="w-full max-w-md space-y-8">
                    <div className="bg-white rounded-lg shadow-md p-8">
                        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
                            {step === 1 ? "Reset Password" : "Enter Verification Code"}
                        </h2>

                        {step === 1 && (
                            // Step 1: Enter email
                            <form onSubmit={handleSubmit(onSubmitEmail)} className="space-y-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Email</label>
                                    <input
                                        {...register('email', { required: true })}
                                        type="email"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    {errors.email && <p className="text-red-500 text-sm">Email is required.</p>}
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-400 hover:bg-amber-500"
                                        disabled={isloading}
                                    >
                                        {isloading ? "Loading..." : "Send Verification Code"}
                                    </button>
                                </div>
                            </form>
                        )}
                        {step === 2 && (
                            // Step 2: Enter verification code and new password
                            <form onSubmit={handleSubmit(onSubmitPassword)} className="space-y-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Verification Code send to your mail</label>
                                    <input
                                        {...register('verificationCode', { required: true })}
                                        type="text"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    {errors.verificationCode && <p className="text-red-500 text-sm">Verification code is required.</p>}
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">New Password</label>
                                    <input
                                        {...register('newPassword', { required: true })}
                                        type="password"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    {errors.newPassword && <p className="text-red-500 text-sm">New password is required.</p>}
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Confirm New Password</label>
                                    <input
                                        {...register('confirmPassword', { required: true })}
                                        type="password"
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    {errors.confirmPassword && <p className="text-red-500 text-sm">Please confirm your new password.</p>}
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        disabled={isloading}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-400 hover:bg-amber-500"
                                    >
                                        {isloading ? "Loading..." : "Reset Password"}
                                    </button>
                                </div>
                            </form>
                        )}

                        {step === 1 ? (
                            <NavLink to="/login" className='flex justify-end mt-3 text-blue-700 hover:text-blue-900'>
                                Back to login
                            </NavLink>
                        ) : (
                            <button onClick={() => setStep(1)} className='w-full flex justify-end mt-3 text-blue-700 hover:text-blue-900'>
                                Back
                            </button>
                        )}

                        {error && (
                            <p className={`mt-2 text-center text-sm ${error === "Password changed successfully" ? "text-green-600" : "text-red-600"}`}>{error}</p>
                        )}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ForgotPassword;
