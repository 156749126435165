import React, { useEffect,useState } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import {
  questionAtom,
  attemptIndexAtom,
  answersAtom,
  scoreAtom,
} from "../atom";
import getPersonalData from "../GetPersonalData/GetpersonalData";
const ReadingGrades = () => {
  const [questionsData, setQuestionsData] = useState(null);
  const [error, setError] = useState(false);
  const [questionNum, setQuestionNum] = useAtom(questionAtom);
  const [attemptIndex, setAttemptIndex] = useAtom(attemptIndexAtom);
  const [answers, setAnswers] = useAtom(answersAtom);
  const [score, setScore] = useAtom(scoreAtom);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAttemptsData = async () => {
      const getEmail = await getPersonalData();
      const requestData = {
        Email: getEmail.email,
        SectionPrefix: "TOEFL#reading",
      };
      try{
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/get-all-results`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.statusText === "OK") {
        setQuestionsData(response.data);
        console.log(response.data);
        setError(false)
      }}
      catch(e){
        console.log(e)
        setError(true);
      }
    };


    fetchAttemptsData();
  }, []);

  const handleAttemptClick = (question, attemptIndex) => {
    // Handle what happens when an attempt button is clicked
    console.log(`Question ${question} - Attempt ${attemptIndex + 1} clicked`);
    // You can navigate to a detailed view or perform other actions
    const selectedAttempt = questionsData[question][attemptIndex];

    // Set the atoms with the relevant data
    setQuestionNum(question);
    setAttemptIndex(attemptIndex);
    setAnswers(selectedAttempt.Answers);
    setScore(selectedAttempt.Score);
    localStorage.setItem("OpenStudyAiQuestionNo", question.substring(9));
    // Navigate to ReadingLandingPage
    navigate("/reading-landing-page");
  };
  if (error) {
    return <div className="flex justify-center items-center text-xl font-bold mt-[100px]">You have't attempted this section</div>
  }

  if (!questionsData) {
    return (
        <div className="h-full w-full flex  justify-center p-[100px]">
      <div className="w-12 h-12 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin mb-5"></div>
      </div>
    );
  }

  return (
    <div className="p-4">
      {Object.entries(questionsData).map(([question, attempts], index) => (
        <div
          key={index}
          className="mb-6 p-6 bg-white rounded-lg shadow-lg border border-gray-300"
        >
          <h2 className="text-gray-800 text-2xl mb-4 font-semibold border-b border-gray-300 pb-2">
            {question}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {attempts
              ?.sort((b, a) => new Date(b.TimeStamp) - new Date(a.TimeStamp)) // Sort by TimeStamp, most recent first
              .map((attempt, attemptIndex) => {
                const formattedDate = new Date(
                  attempt.TimeStamp
                ).toLocaleString(); // Format the date and time

                return (
                  <button
                    key={attemptIndex}
                    className="bg-amber-400 hover:bg-amber-500 text-gray-700 font-bold py-2 px-4 rounded flex justify-between items-center flex-wrap"
                    onClick={() => handleAttemptClick(question, attemptIndex)}
                  >
                    <div>Score {attempt.Score}</div>
                    <div>
                      <span className="text-sm text-gray-600">
                        {formattedDate}
                      </span>
                    </div>
                  </button>
                );
              })}
          </div>
        </div>
      ))}
    </div>
  );
};
export default ReadingGrades;