import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavBar from './NavBar';
import { Helmet } from 'react-helmet-async';
import getPersonalData from '../GetPersonalData/GetpersonalData';
import Loading from '../LoadingPage/Loading';
const SpeakingLandingPage = () => {
    const [numExams, setNumExams] = useState(0);
  const [scores, setScores] = useState();
    //const [taskNum, setTaskNum] = useState(localStorage.getItem("OpenStudyAiSpeakingTaskNo"));
    const taskNum = localStorage.getItem("OpenStudyAiSpeakingTaskNo");
    const [isLoading,setIsLoading]=useState(false);
    const navigate = useNavigate();

    const getNumExams = async () => {
        try {
          setIsLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/speaking-number`);
            if (response.statusText === 'OK') {
                setNumExams(response.data.message);
                localStorage.setItem("OpenStudyAiSpeakingNumExams", response.data.message);
            }
          const getEmail = await getPersonalData();
          const requestData = {
            Email: getEmail.email,
            SectionPrefix: `TOEFL#speakingTask${taskNum}`,
          };
          const scr = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/speaking-results`,
            requestData,
            {
              headers: {
                "Content-Type": "application/json", // Ensure correct content type
              },
            }
          );
          setScores(scr.data);
          setIsLoading(false)
        } catch (error) {
            console.error("Error fetching number of exams:", error);
          setIsLoading(false)
        }
    };

    useEffect(() => {
        getNumExams();
    }, []);

    const handleExamButton = (index) => {
        localStorage.setItem("OpenStudyAiSpeakingExamNum", index + 1);
        navigate("/speakingTask");
    };


    if(isLoading)return <Loading/>
    
    
    const examButtons = Array.from(
        { length: numExams },
        (_, i) => `Version ${i + 1}`
    );

    return (
      <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
        <Helmet>
          <title>
            Speaking task{taskNum} 
          </title>
          {/* <meta
                name="description"
                content="Speaking Section: Practice the TOEFL Speaking section to improve your ability to communicate clearly and effectively in English. This section includes four tasks: an independent task where you express your opinion on familiar topics, and three integrated tasks that challenge you to read, listen, and respond. Our practice exercises will help you enhance your fluency, coherence, and ability to synthesize information under time constraints."
            /> */}
          <link rel="canonical" href="/SpeakingLandingPage" />
        </Helmet>
        <NavBar />
        <div className="p-4">
          <h1 className="text-4xl font-bold text-white mb-4 text-center">
            Task {taskNum}
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {examButtons.map((button, index) => (
              <button
                key={index}
                className={`${scores?.hasOwnProperty(button)
                    ? "hover:bg-amber-100 bg-amber-500 "
                    : "bg-amber-400 hover:bg-amber-500"
                  }bg-amber-400 hover:bg-amber-500 text-white font-bold py-2 px-4 rounded `}
                onClick={() => handleExamButton(index)}
              >
                {scores?.hasOwnProperty(button) ? (
                  <div className="flex justify-around">
                    <div>{button}</div>
                    <div className="ml-2">Score: {scores[button]}</div>
                  </div>
                ) : (
                  button
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
};

export default SpeakingLandingPage;