import React from 'react';
import CourseContentLayout from '../components/CourseContentLayout';

const FinalPreparation = () => {
  return (
    <CourseContentLayout title="Final Preparation">
      <section className="mt-8">
        <h2 className="text-2xl font-bold mb-4">7.1 Review of Key Strategies</h2>

        <h3 className="text-xl font-semibold mb-4">Reading Section Strategies:</h3>
        <ol className="list-decimal pl-6 mb-4">
          <li><strong>Skimming and Scanning:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Skim the passage quickly to get the main idea;</li>
            <li>scan for specific information when answering detail questions.</li>
          </ul>
          <li><strong>Time Management:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Allocate about 20 minutes per passage;</li>
            <li>If struggling with a question, mark it and come back later.</li>
          </ul>
          <li><strong>Vocabulary in Context:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Look for context clues in surrounding sentences;</li>
            <li>consider the overall tone and theme of the passage.</li>
          </ul>
          <li><strong>Making Inferences:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Base your answers on information from the passage, not personal knowledge;</li>
            <li>look for implied information and logical conclusions.</li>
          </ul>
        </ol>

        <h3 className="text-xl font-semibold mb-4">Listening Section Strategies:</h3>
        <ol className="list-decimal pl-6 mb-4">
          <li><strong>Active Listening:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Take notes on main ideas and important details;</li>
            <li>Pay attention to tone of voice and stress patterns.</li>
          </ul>
          <li><strong>Anticipating Information:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Listen for signal words that indicate important information;</li>
            <li>Predict what might come next based on the context.</li>
          </ul>
          <li><strong>Understanding Academic Language:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Familiarize yourself with common academic vocabulary;</li>
            <li>Practice recognizing lecture structures and patterns.</li>
          </ul>
          <li><strong>Note-Taking Techniques:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Use abbreviations and symbols;</li>
            <li>Focus on key points rather than trying to write everything.</li>
          </ul>
        </ol>

        <h3 className="text-xl font-semibold mb-4">Speaking Section Strategies:</h3>
        <ol className="list-decimal pl-6 mb-4">
          <li><strong>Organizing Your Response:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Use a clear structure: introduction, main points, conclusion;</li>
            <li>Practice transitioning smoothly between ideas.</li>
          </ul>
          <li><strong>Managing Time:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Pace yourself during preparation time;</li>
            <li>Speak at a natural pace, neither too fast nor too slow.</li>
          </ul>
          <li><strong>Elaborating on Ideas:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Provide specific examples to support your points;</li>
            <li>Explain the "why" and "how" of your statements.</li>
          </ul>
          <li><strong>Handling Integrated Tasks:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Take efficient notes during reading and listening portions;</li>
            <li>Synthesize information from both sources in your response.</li>
          </ul>
        </ol>


        <h3 className="text-xl font-semibold mb-4">Writing Section Strategies:</h3>
        <ol className="list-decimal pl-6 mb-4">
          <li><strong>Essay Structure:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Use a clear thesis statement;</li>
            <li>Organize paragraphs logically with topic sentences and supporting details.</li>
          </ul>
          <li><strong>Time Management:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Allocate time for planning, writing, and reviewing;</li>
            <li>For the integrated task, balance information from both sources.</li>
          </ul>
          <li><strong>Addressing the Task:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Ensure you're directly answering the question asked;</li>
            <li>For integrated tasks, accurately represent both the reading and listening materials.</li>
          </ul>
          <li><strong>Language Use:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Employ a variety of sentence structures;</li>
            <li>Use academic vocabulary appropriately.</li>
          </ul>
        </ol>


        <h2 className="text-2xl font-bold mb-4">7.2 Last-Minute Tips</h2>
        <ol className="list-decimal pl-6 mb-4">
          <li><strong>Review Test Format:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Familiarize yourself with the structure and timing of each section;</li>
            <li>Practice with official TOEFL iBT practice tests.</li>
          </ul>
          <li><strong>Improve Weak Areas:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Identify your weakest skills and focus on improving them;</li>
            <li>Use targeted practice exercises for specific question types.</li>
          </ul>
          <li><strong>Build Stamina:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Practice taking full-length tests to build mental endurance;</li>
            <li>Time yourself to get used to the pressure of test conditions.</li>
          </ul>
          <li><strong>Enhance Vocabulary:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Review common academic words and phrases;</li>
            <li>Practice using new vocabulary in context.</li>
          </ul>
          <li><strong>Relax and Stay Positive:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Get enough sleep in the days leading up to the test;</li>
            <li>Practice relaxation techniques to manage test anxiety.</li>
          </ul>
          <li><strong>Fine-tune Your English Immersion:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Listen to English podcasts or watch English news daily;</li>
            <li>Read English articles from reputable sources.</li>
          </ul>
          <li><strong>Practice Speaking Aloud:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Record yourself speaking and analyze your performance;</li>
            <li>Practice with a study partner or language exchange partner.</li>
          </ul>
          <li><strong>Refine Your Writing:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Practice timed writing exercises;</li>
            <li>Review common grammar and punctuation rules.</li>
          </ul>
          <li><strong>Familiarize with the Computer-Based Format:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Practice typing in English if you're not used to it;</li>
            <li>Get comfortable with on-screen timers and progress bars.</li>
          </ul>
          <li><strong>Prepare Your Test Day Kit:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Gather all required documents;</li>
            <li>Pack snacks and water for break times.</li>
          </ul>
        </ol>


        <h2 className="text-2xl font-bold mb-4">7.3 Test Day Procedures</h2>

        <h3 className="text-xl font-semibold mb-4">Before the Test:</h3>
        <ol className="list-decimal pl-6 mb-4">
          <li><strong>Get a Good Night's Sleep:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Aim for 7-8 hours of sleep the night before the test;</li>
            <li>Avoid last-minute cramming that might increase stress.</li>
          </ul>
          <li><strong>Eat a Nutritious Breakfast:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Choose foods that provide sustained energy (e.g., oatmeal, eggs, fruit);</li>
            <li>Avoid heavy or unfamiliar foods that might cause discomfort.</li>
          </ul>
          <li><strong>Arrive Early:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Plan to arrive at the test center at least 30 minutes before your scheduled time;</li>
            <li>Account for potential traffic or public transportation delays.</li>
          </ul>
          <li><strong>Bring Required Items:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Valid, unexpired ID (passport is safest for international students);</li>
            <li>Test registration confirmation, snacks, and water for breaks (to be stored in lockers).</li>
          </ul>
          <li><strong>Dress Comfortably:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Wear layers to adjust to room temperature;</li>
            <li>Choose comfortable clothing that won't distract you.</li>
          </ul>
        </ol>


        <h3 className="text-xl font-semibold mb-4">During the Test:</h3>
        <ol className="list-decimal pl-6 mb-4">
          <li><strong>Listen to Instructions Carefully:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Pay close attention to the test administrator's instructions;</li>
            <li>Ask questions if anything is unclear before the test begins.</li>
          </ul>
          <li><strong>Use Your Break Time Wisely:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Take bathroom breaks during the designated break times;</li>
            <li>Have a quick snack and water to maintain energy.</li>
          </ul>
          <li><strong>Stay Focused:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Concentrate on your own test; don't worry about other test-takers;</li>
            <li>If you finish a section early, review your answers.</li>
          </ul>
          <li><strong>Manage Your Time:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Keep an eye on the on-screen timer;</li>
            <li>Move on if you're stuck on a question; you can return to it later if time allows.</li>
          </ul>
          <li><strong>Use Scratch Paper Effectively:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Take notes during the Listening section;</li>
            <li>Plan your Speaking and Writing responses.</li>
          </ul>
          <li><strong>Technical Issues:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>If you experience any technical problems, raise your hand immediately;</li>
            <li>Don't try to fix issues yourself; wait for assistance.</li>
          </ul>
        </ol>

        <h3 className="text-xl font-semibold mb-4">After the Test:</h3>
        <ol className="list-decimal pl-6 mb-4">
          <li><strong>Check Out Properly:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Follow the test administrator's instructions for ending the test;</li>
            <li>Ensure all your personal belongings are collected.</li>
          </ul>
          <li><strong>Collect Score Report Information:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Receive information about when and how to access your scores;</li>
            <li>Understand the score reporting process.</li>
          </ul>
          <li><strong>Reflect on Your Performance:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Make mental notes about areas that felt challenging;</li>
            <li>Consider if you need to retake the test based on your performance.</li>
          </ul>
          <li><strong>Relax and Celebrate:</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li>Reward yourself for completing the test, regardless of how you think you performed;</li>
            <li>Avoid overthinking or discussing specific questions with other test-takers.</li>
          </ul>
        </ol>


        <p>Remember, staying calm and confident is key to performing well on test day. Trust in your preparation and approach each section with a positive mindset. Good luck on your TOEFL iBT test!</p>
      </section>

    </CourseContentLayout>
  );
};

export default FinalPreparation;