import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { IoIosMail } from "react-icons/io";
import { FaDiscord } from "react-icons/fa";
import { MdFeedback } from "react-icons/md"; 

const TOEFLCoursework = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLogin,setIsLogin]=useState()
  useEffect(()=>{

    const getToken = localStorage.getItem("OpenStudyAi-token")
    setIsLogin(getToken)
  },[])

  const CourseSection = ({ number, title, subsections, link }) => (
    <div className="bg-white rounded-lg shadow-md p-6 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg border-l-4 border-teal-500">
      <Link to={link} className="block">
        <h3 className="text-2xl font-bold text-teal-600 mb-3">{number}. {title}</h3>
        <ul className="list-disc list-inside text-gray-700">
          {subsections.map((subsection, index) => (
            <li key={index} className="mb-1">{subsection}</li>
          ))}
        </ul>
      </Link>
    </div>
  );

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-teal-600 to-teal-800">
      <Helmet>
        <title>TOEFL iBT Coursework - OpenStudyAI</title>
        <meta
          name="description"
          content="Comprehensive TOEFL iBT preparation course outline - Master all sections with OpenStudyAI's structured learning path."
        />
      </Helmet>

      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <Link to="/" className="text-3xl font-bold text-teal-600">OpenStudyAI</Link>
          <nav className="flex items-center">
            <div className="relative">
              <button 
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600 transition duration-200 mr-4"
              >
                Coursework
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                  <Link 
                    to={isLogin ?"/courses/toefl":"/"} 
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    TOEFL
                  </Link>
                </div>
              )}
            </div>
            <Link
              to={!isLogin ?"/login":"/"}
              className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
            >
              {!isLogin ?"Login":"BackTOHomePage"}
            </Link>
          </nav>
        </div>
      </header>

      <main className="flex-grow container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center text-white mb-10">TOEFL iBT Preparation Course</h1>
        
        <div className="grid md:grid-cols-2 gap-6">
          <CourseSection 
            number="1"
            title="Introduction to the TOEFL iBT Test"
            subsections={[
              "Overview of the Test Structure",
              "Understanding the Scoring System",
              "Computer-Based Testing Environment"
            ]}
            link="/courses/toefl/introduction"
          />
          <CourseSection 
            number="2"
            title="General Test-Taking Strategies"
            subsections={[
              "Time Management Techniques",
              "Note-Taking Skills",
              "Stress Management and Test Anxiety"
            ]}
            link="/courses/toefl/strategies"
          />
          <CourseSection 
            number="3"
            title="Reading Section"
            subsections={[
              "Overview and Structure",
              "Question Types",
              "Reading Strategies",
              "Time Management for Reading Tasks"
            ]}
            link="/courses/toefl/reading"
          />
          <CourseSection 
            number="4"
            title="Listening Section"
            subsections={[
              "Overview and Structure",
              "Question Types",
              "Listening Strategies",
              "Note-Taking Techniques"
            ]}
            link="/courses/toefl/listening"
          />
          <CourseSection 
            number="5"
            title="Speaking Section"
            subsections={[
              "Overview and Structure",
              "Independent Speaking Task",
              "Integrated Speaking Tasks",
              "Speaking Strategies",
              "Pronunciation and Fluency"
            ]}
            link="/courses/toefl/speaking"
          />
          <CourseSection 
            number="6"
            title="Writing Section"
            subsections={[
              "Overview and Structure",
              "Integrated Writing Task",
              "Writing for an Academic Discussion Task",
              "Writing Strategies",
              "Grammar and Vocabulary"
            ]}
            link="/courses/toefl/writing"
          />
          <CourseSection 
            number="7"
            title="Final Preparation"
            subsections={[
              "Review of Key Strategies",
              "Last-Minute Tips",
              "Test Day Procedures"
            ]}
            link="/courses/toefl/final-prep"
          />
        </div>

        <p className="text-lg font-semibold text-center mt-10 text-white">
          Click on each section to explore detailed content and strategies for TOEFL iBT preparation.
        </p>
      </main>

      <footer className="bg-gray-800 text-white py-6">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 OpenStudyAI. All rights reserved.</p>
          <div className="mt-2 flex justify-center">
            <NavLink
              to="/privacy-policy"
              className="text-gray-400 hover:text-white mx-2 bg-transparent border-none cursor-pointer"
            >
              Privacy Policy
            </NavLink>
            <NavLink
              to="/terms-services"
              className="text-gray-400 hover:text-white mx-2 bg-transparent border-none cursor-pointer"
            >
              Terms of Service
            </NavLink>
            <NavLink
              to="/contacts"
              className="text-gray-400 hover:text-white mx-2 bg-transparent border-none cursor-pointer"
            >
              Contact Us{" "}
            </NavLink>
            <div className="flex items-center gap-1">
              <div>
                <a
                  href="mailto:mockxbytechnoverse@gmail.com"
                  className="text-gray-400 hover:text-white"
                >
                  <div className="text-lg">
                    <IoIosMail />
                  </div>
                </a>
              </div>
              <div>
                <a
                  href="https://forms.gle/BopWYfDohLpAdtxj9"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white"
                >
                  <MdFeedback />
                </a>
              </div>
              <div>
                <a
                  href="https://discord.gg/JFTwXm2Rgt"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white"
                >
                  <FaDiscord />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TOEFLCoursework;