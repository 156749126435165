import React from 'react';

const CheckIcon = () => (
  <svg
    className="h-5 w-5 text-green-500"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
  </svg>
);

const TwoChoiceQuestion = ({
  question,
  userAnswers,
  handleAnswer,
  submissionResult,
  currentQuestionIndex,
}) => {
  const isCorrect = submissionResult?.results[currentQuestionIndex]?.is_correct;
  const explanation = submissionResult?.results[currentQuestionIndex]?.explanation;

  // Get the number of correct answers for this question
  console.log(question.correct_answer);
  const maxSelections = question.correct_answer.length;

  // Get the number of answers the user has already selected
  const currentSelections = userAnswers[question.question_number]?.length || 0;

  // Disable the checkbox if the maximum number of selections is reached
  const isDisabled = (key) =>
    currentSelections >= maxSelections && !userAnswers[question.question_number]?.includes(key);

  return (
    <div>
      {question.options &&
        Object.entries(question.options).map(([key, value]) => (
          <div key={key} className="mb-4">
            <label className="flex items-center space-x-3 p-3 rounded-lg hover:bg-gray-100 transition duration-200">
              <input
                type="checkbox"
                id={`question-${currentQuestionIndex}-${key}`}
                name={`question-${currentQuestionIndex}`}
                value={key}
                checked={userAnswers[question.question_number]?.includes(key)}
                onChange={() => handleAnswer(question.question_number, key, true)}
                className="form-checkbox h-5 w-5 text-blue-600"
                disabled={isDisabled(key) || !!submissionResult} // Disable based on max selections or if the submission is completed
              />
              <span className="text-lg text-gray-700 text-wrap">{key}) {value}</span>
              {/* Display checkmarks for each correct answer */}
              {submissionResult &&
                submissionResult.results[currentQuestionIndex].correct_answer.includes(key) && (
                  <CheckIcon />
                )}
            </label>
          </div>
        ))}
      {/* Display the explanation for incorrect answers */}
      {submissionResult && (
        <div className="mt-6 p-4 bg-gray-100 rounded-lg">
          <p
            className={`text-lg font-semibold ${
              isCorrect ? 'text-green-600' : 'text-red-600'
            }`}
          >
            {isCorrect ? 'Correct!' : 'Incorrect.'}
          </p>
          <p className="text-gray-700 mt-2">{explanation}</p>
        </div>
      )}
    </div>
  );
};

export default TwoChoiceQuestion;