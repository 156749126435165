import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import axios from "axios";
import Loading from "../LoadingPage/Loading";

const AuthRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem("OpenStudyAi-token");
      // console.log(token);
      if (!token) {
        setIsAuthenticated(true);
        return;
      }

      try {
        await axios.get(`${process.env.REACT_APP_API_URL}/api/verify-token`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setIsAuthenticated(false);
      } catch (error) {
        setIsAuthenticated(true);
      }
    };

    verifyToken();
  }, []);

  if (isAuthenticated === null) {
    return <Loading/>; // or a spinner/loading indicator
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/dashboard" />;
};

export default AuthRoute;
