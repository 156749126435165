// src/ContactPage.js
import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { IoIosMail } from "react-icons/io";
import { FaDiscord } from "react-icons/fa";
import { MdFeedback } from "react-icons/md";
import Footer from "./Footer";

const ContactPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <Helmet>
        <title>Contact Us - OpenStudyAI</title>
        <meta
          name="description"
          content="Get in touch with OpenStudyAI. We're here to assist with your TOEFL preparation and any queries you may have. Reach out to us via email, give feedback, or join our Discord community."
        />
        <link rel="canonical" href="/contact" />
      </Helmet>
      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-3xl font-bold text-teal-600">
            <Link to="/">OpenStudyAI</Link>
          </h1>
          <Link
            to="/"
            className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
          >
            Back
          </Link>
        </div>
      </header>
      <main className="flex flex-1">
        <div className="w-full h-full m-auto flex justify-center items-center">
          <div className="flex flex-col w-[90%] md:w-[60%] lg:w-[40%] bg-white p-8 rounded-lg shadow-lg">
            <h1 className="text-4xl font-bold text-center text-gray-900 mb-8">
              Get in Touch
            </h1>
            <p className="text-lg text-gray-700 mb-6 text-center">
              We’d love to hear from you! Whether you have a question, need
              support, or just want to give us feedback, feel free to reach out
              through email:
              <span className=" text-md font-semibold">mockxbytechnoverse@gmail.com</span>{" "}
              or any of the following methods.
            </p>

            <div className="mb-6 flex flex-col gap-4">
              <div className="flex flex-col items-center">
                <p className="text-lg font-semibold text-gray-800 mb-2">
                  Email Us:
                </p>
                <a
                  href="https://mail.google.com/mail/?view=cm&fs=1&to=mockxbytechnoverse@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-red-500 text-white py-3 px-6 rounded-lg flex items-center gap-2 transition-transform transform hover:translate-y-[-2px] hover:bg-red-600"
                >
                  <IoIosMail className="text-2xl" />
                  <span className="text-white">Email OpenStudyAI</span>
                </a>
              </div>

              <div className="flex flex-col items-center">
                <p className="text-lg font-semibold text-gray-800 mb-2">
                  Provide Feedback:
                </p>
                <a
                  href="https://forms.gle/BopWYfDohLpAdtxj9"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-amber-400 text-white py-3 px-6 rounded-lg flex items-center gap-2 transition-transform transform hover:translate-y-[-2px] hover:bg-amber-500"
                >
                  <MdFeedback className="text-xl" />
                  <span className="text-white">Give Feedback</span>
                </a>
              </div>

              <div className="flex flex-col items-center">
                <p className="text-lg font-semibold text-gray-800 mb-2">
                  Join Our Community:
                </p>
                <a
                  href="https://discord.gg/JFTwXm2Rgt"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-[#7289DA] text-white py-3 px-6 rounded-lg flex items-center gap-2 transition-transform transform hover:translate-y-[-2px] hover:bg-[#5b6eae]"
                >
                  <FaDiscord className="text-2xl" />
                  <span className="text-white">Join our Discord</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ContactPage;
