import axios from "axios";

const getPersonalData= async()=>{
    const token = localStorage.getItem("OpenStudyAi-token");
    const getEmail = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/protected`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return getEmail.data.logged_in_as;
}
export default getPersonalData;