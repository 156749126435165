import React, { useState, useEffect } from "react";
import TimeoutPopup from "./TimeoutPopup";
const CountdownTimer = ({ time }) => {
  // Initialize the state with 10 minutes in seconds (10 * 60 = 600 seconds)
  const [timeLeft, setTimeLeft] = useState(time*60);
const [showTimerout, setShowTimerout] = useState(false);
  useEffect(() => {
    // Exit early if timeLeft is 0
    if (timeLeft === 0) {
      handleTimeout();
      return;
    }

    // Set up an interval to update the time left every second
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
      
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [timeLeft]);
  // Convert seconds into minutes and seconds
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;

  };
  const handleTimeout = () => {
    setShowTimerout(true);
  };
  const handleCloseTimeoutPopup = () => {
    setShowTimerout(false);
  };
  return (
    <div className="flex justify-center">
      <div className="bg-white my-2 p-1 w-[60px] rounded-lg">
        <div className="flex justify-center">
          {/* <h1>Countdown Timer</h1> */}
          <p className="text-xl  font-semibold">{formatTime(timeLeft)}</p>
          {showTimerout && (
            <TimeoutPopup handleCloseTimeoutPopup={handleCloseTimeoutPopup} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
