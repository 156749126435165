import React from "react";

const TimeoutPopup = ({ time, handleCloseTimeoutPopup }) => {
  const handleClose = () => {
    handleCloseTimeoutPopup();
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
      <div className="bg-white border border-gray-300 rounded-lg shadow-lg p-6 w-100 flex flex-col">
        <p className="text-xl font-bold mb-4">
          TIME UP
        </p>
        <button
          onClick={handleClose}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 "
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default TimeoutPopup;
