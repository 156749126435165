import { useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from 'react';
import TaskStartScreen from './TaskStartScreen';
import TranscriptAndGrade from "./TranscriptAndGrade";
import '../../styles/Task.css';
import AudioRecorder from './AudioRecorder';
import AudioPrompt from './AudioPrompt';
import Timer from './Timer';
import { useAtom } from "jotai";
import {
  questionAtom,
} from "../../atom"; 

const Task1 = ({ title, instructions, prompt, examNum, setExamNum, sampleResponse1, sampleResponse2 }) => {
    const navigate = useNavigate();

    const [isRecording, setIsRecording] = useState(false);
    const [questionNum, setQuestionNum] = useAtom(questionAtom);
    const [isPrepTime, setIsPrepTime] = useState(false);
    const [taskStarted, setTaskStarted] = useState(false);
    const [taskFinished, setTaskFinished] = useState(false);
    const [grades, setGrades] = useState(false);
    const [gradedDone, setGradedDone] = useState(false);
    const [isPlayingPrompt, setIsPlayingPrompt] = useState(false);
    const [isPlayingResponseBeep, setIsPlayingResponseBeep] = useState(false);
    const audioPromptSrc = `/audios/prompts/task1/task1_prompt_prepBeep_${examNum}.mp3`;
    const audioResponseBeepSrc = '/audios/begin-response-beep.mp3';

    const instructionsOverride =  "State whether you agree or disagree with the following statement. Then explain your reasons, using specific details in your explanation.";

    const numExams = parseInt(localStorage.getItem("OpenStudyAiSpeakingNumExams"));

    const scrollContainerRef  = useRef(null);
    useEffect(()=>{
        if(questionNum){
            setTaskFinished(true);
            setGrades(true);
            setTaskStarted(true);
        }
    })

    const prepTime = 15; // 15
    const responseTime = 45; // 45

    const times = [
        { description: "Prep Time", value: prepTime },
        { description: "Response Time", value: responseTime },
    ];

    useEffect(() => {
        console.log("taskStarted: ", taskStarted);
        console.log("isTaskFinished: ", taskFinished);
        console.log("isPrepTime: ", isPrepTime);
        console.log("isRecording: ", isRecording);
        console.log("isPlayingPrompt: ", isPlayingPrompt);
        console.log("isTaskFinished: ", taskFinished);
    }, [taskStarted, taskFinished, isPrepTime, isRecording, isPlayingPrompt, taskFinished]);

    const handleSkip = () => {
        if (isPrepTime){
            setIsPrepTime(false);
            startResponseBeep();
        }
        
    };

    const handleStart = () => {
        setTaskStarted(true);
        setIsPlayingPrompt(true);
    };

    const handlePromptComplete = () => {
        setIsPlayingPrompt(false);
        setIsPrepTime(true);
    };

    const startResponseBeep = () => {
        setIsPrepTime(false);
        setIsPlayingResponseBeep(true);
    };

    const handleResponseBeepComplete = () => {  
        setIsPlayingResponseBeep(false);
        startRecording();
    };

    const startRecording = () => {
        setIsRecording(true);
        setIsPrepTime(false);
    };

    const stopRecording = () => {
        setIsRecording(false);
        setTaskFinished(true);
    };

    const nextTask = () => {
        localStorage.removeItem("audioUrlTask");
        if (examNum < numExams){
            const newExamNum = parseInt(examNum) + 1;
            setExamNum(newExamNum);
            window.location.reload();
        }
        else{
            navigate('/Speaking');
        }
    };

    const continueToGrading = () => {
        setGrades(true);
    };

    const retryTask = () => {
        window.location.reload();
        localStorage.removeItem("audioUrlTask");
    };
    
    return (
        <div className="task-container">
            {!taskStarted ? (
                <TaskStartScreen 
                    taskNumber={1} 
                    title={title} 
                    instructions={instructionsOverride} 
                    times={times} 
                    onStart={handleStart} 
                />
            ) : !grades ? (
                <div className="task-content">
                    <div className="task-inner">
                    <h2>{title}</h2>
                    <p className="instructions"><strong>Instructions: </strong>{instructionsOverride}</p>
                    <div>
                        {isPlayingPrompt && <p className="font-thin">Begin to prepare your response after the beep.</p>}
                        <div className="instructions-container"><p className="prompt"><strong>Prompt: </strong>{prompt}</p></div>
                    </div>
                    {isPlayingPrompt && (
                        <AudioPrompt audioSrc={audioPromptSrc} onComplete={handlePromptComplete} />
                    )}
                    {isPrepTime &&
                        (<Timer duration={prepTime} label={"Prep"} onComplete={startResponseBeep} />)}
                    {isPlayingResponseBeep && 
                    
                       (<div> <p className="font-thin">Begin your response after the beep.</p>
                       <AudioPrompt audioSrc={audioResponseBeepSrc} onComplete={handleResponseBeepComplete} /> </div>)
                    }

                    { (isRecording || taskFinished) && (
                        <div>
                            {!taskFinished ? <Timer duration={responseTime} label={"Response"} onComplete={stopRecording} /> : 
                            <strong className="timer">TASK FINISHED</strong>}
                            {isRecording && <p className="recording-label">Now Recording...</p>}
                            {isRecording && <AudioRecorder duration={responseTime} taskNum="1" taskPrompt={prompt} getGrades={grades} />}
                        </div>
                    )}
                    </div>
                    <div className="grading-buttons-container">
                        <button className="next-task-button" disabled={!taskFinished} onClick={continueToGrading}>Continue to Grading</button>
                        {isPrepTime && (<button className="next-task-button" onClick={handleSkip} disabled={!isPrepTime}>Skip Prep Time</button>)}
                    </div>
                </div>
            ) : (
                <div className="task-content">
                    <div className="task-inner-grading" ref={scrollContainerRef}>
                    <TranscriptAndGrade 
                        setGradedDone={setGradedDone} 
                        sampleResponse1={sampleResponse1} 
                        sampleResponse2={sampleResponse2} 
                        scrollContainerRef={scrollContainerRef}
                        audioUrl={localStorage.getItem("audioUrlTask")} 
                        responseTime={responseTime}
                        />
                    </div>
                    <div className="grading-buttons-container">
                        <button className="tryAgain-task-button" onClick={retryTask}>Retry Task</button>
                        <button className="next-task-button" disabled={!gradedDone} onClick={nextTask}>{(examNum<numExams) ? 'Next Task':'No More Tasks - Go To Speaking Home Page'}</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Task1;