import React, { useEffect, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import { NavLink } from "react-router-dom";
import ReadingGrades from "./ReadingGrades";
import WritingGrades from "./WritingGrades"
import getPersonalData from "../GetPersonalData/GetpersonalData";
import SpeakingGrades from "./SpeakingGrades";
import Loading from "../LoadingPage/Loading";

const Gradings = () => {
  const { control, handleSubmit, watch } = useForm();
  const [dropdownType, setDropdownType] = useState("reading");
  const [userName, setUserName] = useState();

  const getUserName = async () => {
    const name = await getPersonalData();
    console.log(name);
    setUserName(name.name);
    // setUserName(name)
  }

  useEffect(() => {
    getUserName();
  }, [])
  const [getData, setData] = useState({});
  const NoAttempts = () => {
    return <div className="flex justify-center items-center text-xl font-bold mt-[100px]">You have't attempted this section</div>
  }
  const onSubmit = (data) => {
    console.log(data);
    setData(data);
  };
  if (!userName){
    return <Loading/>
  }

  // Watch dropdown type to show or hide additional dropdowns
  const selectedType = watch("type", "reading");
  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <NavLink to="/dashboard">
            <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
          </NavLink>
          <NavLink
            to="/dashboard"
            className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
          >
            Back to Home
          </NavLink>
        </div>
      </header>
      <div className={`min-h-[calc(100vh-170px)] h-auto pb-4 px-4 pt-[40px]`}>
        <div className="max-w-[95rem] mx-auto bg-white p-8 rounded-lg shadow-lg flex-col  h-auto min-h-[calc(100vh-150px)]">
          <div className=" flex justify-between text-3xl font-bold text-gray-700 mb-4">
            <div>
              {/* J Abhiram Reddy */}{userName}
            </div>
            {/* <div className="text-lg flex gap-2">
              <button className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200">Change password</button>
              <button className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-700 transition duration-200">Logout</button>
            </div> */}
          </div>
          <div className="border mb-4"></div>
          <div className="text-3xl font-semibold text-gray-600 text-center mb-4">
            Grades
          </div>
          <div className="flex justify-center">
            <div className="border mb-4 w-[50%]"></div>
          </div>
          <div className="flex justify-center flex-1">
            <div className="w-[90%]">
              <form onSubmit={handleSubmit(onSubmit)} className="p-4 ">
                <div className="flex flex-1 gap-3">
                  <div className="mb-4 flex-1">
                    <label htmlFor="type" className=" text-gray-700">
                      Section
                    </label>
                    <Controller
                      name="type"
                      control={control}
                      defaultValue="reading"
                      render={({ field }) => (
                        <select
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setDropdownType(e.target.value);
                          }}
                          className="mt-1  w-full p-2 border border-gray-300 rounded"
                        >
                          <option value="reading">Reading</option>
                          <option value="writing">Writing</option>
                          <option value="speaking">Speaking</option>
                        </select>
                      )}
                    />
                  </div>

                  {selectedType === "writing" && (
                    <div className="mb-4 flex flex-1 flex-col">
                      <label
                        htmlFor="writingOptions"
                        className=" text-gray-700"
                      >
                        Writing Options
                      </label>
                      <Controller
                        name="writingOptions"
                        control={control}
                        defaultValue="acad"
                        render={({ field }) => (
                          <select
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            className="mt-1  w-full p-2 border border-gray-300 rounded"
                          >
                            {/* <option value="">Writing-type</option> */}
                            <option value="acad">Academic Discussion</option>
                            <option value="integrated">
                              Integrated Writing
                            </option>
                          </select>
                        )}
                      />
                    </div>
                  )}

                  {selectedType === "speaking" && (
                    <div className="mb-4 flex flex-1 flex-col">
                      <label
                        htmlFor="speakingOptions"
                        className="block text-gray-700"
                      >
                        Speaking Tasks
                      </label>
                      <Controller
                        name="speakingOptions"
                        control={control}
                        defaultValue="1"
                        render={({ field }) => (
                          <select
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            className="mt-1  w-full p-2 border border-gray-300 rounded"
                          >
                            {/* <option value="">Select a task</option> */}
                            <option value="1">Task 1</option>
                            <option value="2">Task 2</option>
                            <option value="3">Task 3</option>
                            <option value="4">Task 4</option>
                          </select>
                        )}
                      />
                    </div>
                  )}
                </div>
                <div className="flex w-full justify-end">
                  <button
                    type="submit"
                    className="mt-4 bg-blue-500 text-white p-2 rounded"
                  >
                    View Grades
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="w-[90%]">
              {getData?.type === "reading" && <ReadingGrades />}
              {getData?.type === "writing" &&
                getData?.writingOptions === "acad" && (
                  <WritingGrades title="TOEFL#academicDiscussion" />
                )}
              {getData?.type === "writing" &&
                getData?.writingOptions === "integrated" && (
                  <WritingGrades title="TOEFL#integratedWriting" />
                )}
              {getData?.type === "speaking" && (getData?.speakingOptions == 1 && <SpeakingGrades number={'1'} />)}
              {getData?.type === "speaking" && (getData?.speakingOptions == 2 && <SpeakingGrades number={'2'} />)}
              {getData?.type === "speaking" && (getData?.speakingOptions == 3 && <SpeakingGrades number={'3'} />)}
              {getData?.type === "speaking" && (getData?.speakingOptions == 4 && <SpeakingGrades number={'4'} />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gradings