// useQuestionAudio.js
import { useRef, useEffect } from 'react';

export const useQuestionAudio = () => {
  const audioRef = useRef(null);

  const playAudio = (src) => {
    if (!src) {
      console.error('Audio source is missing.');
      return;
    }
  
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  
    const audio = new Audio(src);
    
    // Check if the audio file can be loaded
    audio.addEventListener('error', () => {
      console.error('Failed to load audio source:', src);
    });
  
    audioRef.current = audio;
  
    audio.play().catch((error) => {
      console.error('Error playing audio:', error);
    });
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  useEffect(() => {
    return () => {
      stopAudio();
    };
  }, []);

  return { playAudio, stopAudio };
};
