// File: src/Sections/Writing.js
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useAtom } from "jotai";
import {
  questionAtom,
  attemptIndexAtom,
  answersAtom,
  scoreAtom,
} from "../atom";
const Writing = () => {
  const [questionsAvailable, setQuestionsAvailable] = useState({
    academic_discussion: false,
    integrated_writing: false
  });
  const [questionNum, setQuestionNum] = useAtom(questionAtom);
  const [attemptIndex, setAttemptIndex] = useAtom(attemptIndexAtom);
  const [answers, setAnswers] = useAtom(answersAtom);
  const [score, setScore] = useAtom(scoreAtom);

  useEffect(() => {
    const checkQuestions = async () => {
      setQuestionNum('');
      setAttemptIndex('');
      setAnswers('');
      setScore('');
      try {
        localStorage.setItem("OpenStudyAi-hasVisited", "true");
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/check-writing-questions`);
        setQuestionsAvailable(response.data);
      } catch (error) {
        console.error('Error checking writing questions:', error);
      }
    };

    checkQuestions();
  }, []);

  const renderButton = (type, title, description) => {
    const isAvailable = questionsAvailable[type];
    const Component = isAvailable ? NavLink : 'div';
    const props = isAvailable ? { to: `/${type.replace('_', '-')}` } : {};

    return (
      <Component {...props} className="block relative">
        <div className={`bg-amber-100 transition-colors duration-300 rounded-lg shadow-md p-6 ${isAvailable ? 'hover:bg-amber-200' : ''}`}>
          <h3 className="text-xl font-bold text-amber-800 mb-2">{title}</h3>
          <p className="text-amber-700">{description}</p>
        </div>
        {!isAvailable && (
          <div className="absolute inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center text-white text-lg font-bold rounded-lg opacity-0 hover:opacity-100 transition-opacity">
            Coming Soon
          </div>
        )}
      </Component>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
      <Helmet>
        <title>Writing</title>
        <meta
          name="description"
          content="Writing Section: Dive into both Academic Discussion and Integrated Writing practice. For Academic Discussion, select any prompt to refine your ability to craft well-structured essays and present clear arguments. For Integrated Writing, combine information from reading passages and listening materials to create cohesive and insightful essays. Enhance your writing skills across both formats with tailored practice and instant feedback."
        />
        <link rel="canonical" href="/writing" />
      </Helmet>
      <header className="bg-white p-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <NavLink to="/dashboard">
            <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
          </NavLink>
          <NavLink
            to="/dashboard"
            className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
          >
            Back to Home
          </NavLink>
        </div>
      </header>
      <main className="flex-grow flex items-center justify-center px-4 py-12">
        <div className="w-full max-w-4xl space-y-8">
          <div className="bg-white rounded-lg shadow-md p-8">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">
              Select a Writing Task
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {renderButton(
                "academic_discussion",
                "Practice Academic Discussion",
                "Enhance your skills in academic writing and discussion."
              )}
              {renderButton(
                "integrated_writing",
                "Practice Integrated Writing",
                "Improve your ability to synthesize information from multiple sources."
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Writing;