import { useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from 'react';
import TaskStartScreen from './TaskStartScreen';
import TranscriptAndGrade from "./TranscriptAndGrade";
import '../../styles/Task.css';  // Ensure this file exists and contains relevant styling
import AudioRecorder from './AudioRecorder';  // Placeholder, implement or import the actual recorder
import Timer from './Timer';  // Placeholder, implement or import the actual timer component
import AudioPrompt from './AudioPrompt';  // Placeholder, implement or import the actual audio prompt component
import { useAtom } from "jotai";
import {
  questionAtom,
} from "../../atom"; 
const Task4 = ({ title, instructions, audioSrc, prompt, llmContext, audioLength, examNum, setExamNum, sampleResponse1, sampleResponse2 }) => {
    const navigate = useNavigate();
    const audioRef = useRef(null);
    const [questionNum, setQuestionNum] = useAtom(questionAtom);
    const [taskPhase, setTaskPhase] = useState('start'); // start, listening, prep, response, finished, grading
    const [isRecording, setIsRecording] = useState(false);
    const [grades, setGrades] = useState(false);
    const [audioProgress, setAudioProgress] = useState(0);
    const [timeRemaining, setTimeRemaining] = useState(0);
    const [gradedDone, setGradedDone] = useState(false);

    const [skipButtonSection, setSkipButtonSection] = useState("");


    const [isPlayingPrompt, setIsPlayingPrompt] = useState(false);
    const [isPlayingResponseBeep, setIsPlayingResponseBeep] = useState(false);
    const audioPromptSrc = `/audios/prompts/task4/task4_prompt_prepBeep_${examNum}.mp3`;
    const audioResponseBeepSrc = '/audios/begin-response-beep.mp3';

    const numExams = parseInt(localStorage.getItem("OpenStudyAiSpeakingNumExams"));
    
    const scrollContainerRef  = useRef(null);
    
    const instructionsOverride =  "Listen to part of a lecture from a college class.";


    useEffect(()=>{
        if(questionNum){
            setTaskPhase('finished');
            setGrades(true);
        }
    })
    /* Timer Values*/
    const prepTime = 20;//20
    const responseTime = 60; //60 

    useEffect(() => {
        const audioElement = audioRef.current;

        if (taskPhase === 'listening' && audioElement) {
            audioElement.play();
            audioElement.addEventListener('timeupdate', updateProgress);
        }
        return () => {
            if (audioElement) {
                audioElement.removeEventListener('timeupdate', updateProgress);
            }
        };
    }, [taskPhase]);

    const updateProgress = () => {
        if (!audioRef.current) return;

        const currentTime = audioRef.current.currentTime;
        const duration = audioRef.current.duration;
        const progress = (currentTime / duration) * 100;
        const remaining = duration - currentTime;
        setAudioProgress(progress);
        setTimeRemaining(remaining);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const handleSkip = () => {
        if (taskPhase === 'listening') {
            handleListeningComplete();
        }
        else if (taskPhase === 'prep') {
            startResponseAudioBeep();
            setTaskPhase('preResponse');
        }
    }

    useEffect(() => {
        if (taskPhase === 'listening') {
            setSkipButtonSection("Listening");
        }
        else if (taskPhase === 'prep') {
            setSkipButtonSection("Prep Time");
        }
    }, [taskPhase]);

    const handleStart = () => {
        setTaskPhase('listening');
    };

    const handleListeningComplete = () => {
        setTaskPhase(' ');
        setIsPlayingPrompt(true);
    };

    const handlePromptAudioComplete = () => {
        setTaskPhase('prep')
        setIsPlayingPrompt(false);
    };


    const startResponseAudioBeep = () => {
        setIsPlayingResponseBeep(true);
    };



    const startRecording = () => {
        setIsPlayingResponseBeep(false);
        setIsRecording(true);
        setTaskPhase('response');
    };

    const stopRecording = () => {
        setIsRecording(false);
        setTaskPhase('finished');
    };

    const retryTask = () => {
        window.location.reload();
        localStorage.removeItem("audioUrlTask");
    };

    const nextTask = () => {
        localStorage.removeItem("audioUrlTask");
        if (examNum < numExams){
            const newExamNum = parseInt(examNum) + 1;
            setExamNum(newExamNum);
            window.location.reload();
        }
        else{
            navigate('/Speaking');
        }
    };

    const continueToGrading = () => {
        setGrades(true);
    };

    return (
        <div className="task-container">
            {taskPhase === 'start' ? (
                <TaskStartScreen 
                    taskNumber={4} 
                    title={title} 
                    instructions={instructionsOverride} 
                    times={[
                        { description: "Audio length", value: audioLength },
                        { description: "Prep Time", value: prepTime },
                        { description: "Response Time", value: responseTime },
                    ]} 
                    onStart={handleStart} 
                />
            ) : !grades ? (
                <div className="task-content">
                    <div className="task-inner">
                    <h2>{title}</h2>
                    <p className="instructions"><strong>Instructions: </strong>{instructionsOverride}</p>
                    {taskPhase === 'listening' && (
                        <div className="instructions-container">
                            <audio ref={audioRef} src={audioSrc} onEnded={handleListeningComplete} />
                            <div className="audio-progress-bar">
                                <div
                                    className="audio-progress"
                                    style={{ width: `${audioProgress}%` }}
                                ></div>
                            </div>
                            <div className="time-remaining">
                                Time remaining: {formatTime(timeRemaining)}
                            </div>
                        </div>
                    )}
                    {( taskPhase === 'prep' || taskPhase === 'response' || isPlayingPrompt) && (
                        <div>
                            {isPlayingPrompt && <p className="font-thin">Begin to prepare your response after the beep.</p>}
                            <p className="instructions-container prompt "><strong>Prompt: </strong>{prompt}</p>
                        </div>
                    )}
                    {isPlayingPrompt && (
                        <AudioPrompt audioSrc={audioPromptSrc} onComplete={handlePromptAudioComplete} />
                    )}
                    {taskPhase === 'prep' && (
                        <Timer duration={prepTime} label={"Prep"} onComplete={startResponseAudioBeep} />
                    )}
                    {isPlayingResponseBeep && 
                    
                        (<div> <p className="font-thin">Begin your response after the beep.</p>
                        <AudioPrompt audioSrc={audioResponseBeepSrc} onComplete={startRecording} /> </div>)
                    }
                    {taskPhase === 'response' && (
                        <div>                            
                            <Timer duration={responseTime} label={"Response"} onComplete={stopRecording} />
                            {isRecording && <p className="recording-label">Now Recording...</p>}
                            {isRecording && <AudioRecorder duration={responseTime} taskNum="4" taskPrompt={prompt} llmContext={llmContext} />}
                        </div>
                    )}
                    {taskPhase === 'finished' && (
                        <strong className="timer">TASK FINISHED</strong>
                    )}
                    </div>
                    <div className="grading-buttons-container">
                        <button className="next-task-button" disabled={taskPhase !== 'finished'} onClick={continueToGrading}>Continue To Grading</button>
                        {((taskPhase == "prep" && !isPlayingPrompt) || taskPhase == "listening" ) && (<button className="next-task-button" onClick={handleSkip} >Skip {skipButtonSection}</button>)}
                    </div>
                </div>
            ) : (
                <div className="task-content">
                    <div className="task-inner-grading" ref={scrollContainerRef}>
                    <TranscriptAndGrade 
                        setGradedDone={setGradedDone} 
                        sampleResponse1={sampleResponse1} 
                        sampleResponse2={sampleResponse2} 
                        scrollContainerRef={scrollContainerRef}
                        audioUrl={localStorage.getItem("audioUrlTask")} 
                        responseTime={responseTime}

                        />
                    </div>
                    <div className="grading-buttons-container">
                        <button className="tryAgain-task-button" onClick={retryTask}>Retry Task</button>
                        <button className="next-task-button" disabled={!gradedDone} onClick={nextTask}>{(examNum<numExams) ? 'Next Task':'No More Tasks - Go To Speaking Home Page'}</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Task4;