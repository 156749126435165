// ConversationExercise.js
import React, { useEffect, useCallback, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import axios from 'axios';
import './ConversationExercise.css';
import { ExerciseProvider, useExercise } from './ExerciseContext';
import { useAudio } from './useAudio';
import { useQuestionNavigation } from './useQuestionNavigation';
import { useSubmission } from './useSubmission';
// Ensure this line is correct
import { useSpeechSynthesis } from './useSpeechSynthesis'; // Correctly import the named export
import { useQuestionAudio } from './useQuestionAudio';

import Question from './Question';
import AudioControls from './AudioControls';

const ConversationExercise = () => {
  const { id } = useParams();
  const { exerciseData, setExerciseData, error, setError, submissionResult, setSubmissionResult } = useExercise(); 
  const [questionsVisible, setQuestionsVisible] = useState(false);
  const { userAnswers, setUserAnswers } = useExercise(); // Access from context
  const { audioRef, isPlaying, setIsPlaying, handlePlay, handleAudioEnd, handleTimeUpdate, handleLoadedMetadata, progress, duration } = useAudio();
  const { playAudio, stopAudio } = useQuestionAudio();

  const { handleNextQuestion, handlePreviousQuestion, currentQuestionIndex, handleSetQuestion } = useQuestionNavigation();
  const { handleSubmit } = useSubmission(id);
  const { speakText, cancelSpeech } = useSpeechSynthesis(); // Use speech synthesis hook

  const lectureAudioSrc = `/listening/lectures/lecture${id}/audio-lecture${id}.mp3`;


  useEffect(() => {
    return () => { 
      setSubmissionResult(null); // Reset submission result when component unmounts
      //handleSetQuestion(0); // Reset question index when component unmounts
    };
  }, [setSubmissionResult]);


  useEffect(() => {
    if (questionsVisible && exerciseData && exerciseData.questions[currentQuestionIndex] && !submissionResult) {
     
        const qAudioSrc = `/listening/lectures/lecture${id}/qAudio_lecture${id}_${currentQuestionIndex+1}.mp3`;
        console.log('Playing audio:', qAudioSrc);
        // play audio from qAudioSrc
        playAudio(qAudioSrc);

    }
    return () => {
      cancelSpeech(); // Clean up speech when component unmounts or updates
      stopAudio(); // Clean up audio when component unmounts or updates
    };
  }, [currentQuestionIndex, exerciseData, questionsVisible, submissionResult, speakText, cancelSpeech]);

  
  const handleRetake = () => {
    // Reset state to initial values
    setUserAnswers({}); // Clear user answers
    setSubmissionResult(null); // Remove submission results
    setQuestionsVisible(false); // Hide questions
    setIsPlaying(false); // Reset audio playing state
    handleSetQuestion(0); // Reset question index
  
    fetchExerciseData(); // Optionally fetch exercise data again if needed
  };

  const fetchExerciseData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/listening-exercise/lecture/${id}`);
      setExerciseData(response.data);
    } catch (error) {
      setError('Failed to load exercise data. Please try again later.');
    }
  }, [id, setExerciseData, setError]);

  useEffect(() => {
    fetchExerciseData();
  }, [fetchExerciseData]);

  const handleSkipToQuestions = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setIsPlaying(false);
    setQuestionsVisible(true);
  };

  const handleAnswer = (questionNumber, answer, isTwoChoice = false) => {
    setUserAnswers((prev) => {
      if (isTwoChoice) {
        const currentAnswers = prev[questionNumber] || [];
        const updatedAnswers = currentAnswers.includes(answer)
          ? currentAnswers.filter((a) => a !== answer)
          : [...currentAnswers, answer];
        return { ...prev, [questionNumber]: updatedAnswers };
      }
      return { ...prev, [questionNumber]: answer };
    });
  };

  if (!exerciseData) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex items-center justify-center">
        <div className="text-white text-2xl font-bold">Loading...</div>
      </div>
    );
  }

  const currentQuestion = exerciseData.questions[currentQuestionIndex];

  return (
    <ExerciseProvider>
      <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 flex flex-col">
        <header className="bg-white p-4 shadow-md">
          <div className="container mx-auto flex justify-between items-center">
            <NavLink to="/dashboard">
              <h1 className="text-3xl font-bold text-teal-600">OpenStudyAI</h1>
            </NavLink>
            <NavLink
              to="/Listening"
              className="bg-amber-400 text-white px-4 py-2 rounded-md hover:bg-amber-500 transition duration-200"
            >
              Back to Listening
            </NavLink>
          </div>
        </header>
        <main className="flex-grow flex items-center justify-center px-4 py-8">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl w-full">
            <h2 className="text-3xl font-bold mb-12 text-gray-800">{exerciseData?.title || "Student and Professor Discussion"}</h2>

            {/* Audio Element */}
            <audio
              ref={audioRef}
              src={lectureAudioSrc}
              onEnded={handleAudioEnd}
              onTimeUpdate={handleTimeUpdate}
              onLoadedMetadata={handleLoadedMetadata}
              onError={(e) => {
                console.error('Audio error:', e);
                setError(`Error loading audio: ${e.target.error.message}`);
              }}
            /> 

            {/* Audio Controls Component */}
            {!submissionResult && ( // Do not show audio controls if there is a submission result
              <AudioControls
                audioRef={audioRef}
                isPlaying={isPlaying}
                handlePlay={handlePlay}
                handleAudioEnd={handleAudioEnd}
                handleTimeUpdate={handleTimeUpdate}
                handleLoadedMetadata={handleLoadedMetadata}
                progress={progress}
                duration={duration}
              />
            )}

            {!questionsVisible && !submissionResult && ( // Do not show the Play/Skip buttons if there is a submission result
              <div className="space-y-6 mb-0 mt-5">
                {/*<button
                  onClick={handlePlay}
                  className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
                >
                  {isPlaying ? 'Playing...' : 'Play Audio'}
                </button> */}
                <button
                  onClick={handleSkipToQuestions}
                  className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
                >
                  Skip to Questions
                </button>
              </div>
            )}
            {error && <p className="text-red-500 mb-4 text-center font-semibold">{error}</p>}
            
            {questionsVisible && (
              <Question
                question={currentQuestion}
                currentQuestionIndex={currentQuestionIndex}
                userAnswers={userAnswers} 
                handleAnswer={handleAnswer} 
                handleNextQuestion={handleNextQuestion}
                handleRetake={handleRetake}
                handlePreviousQuestion={handlePreviousQuestion}
                submissionResult={submissionResult}
                isLastQuestion={currentQuestionIndex === exerciseData.questions.length - 1}
                handleSubmit={handleSubmit}
              />
            )}

            {submissionResult && ( // Display result UI
              <div className="mt-8 p-6 bg-gray-100 rounded-lg text-center">
                <p className="text-2xl font-bold text-gray-800">Final Score</p>
                <p className="text-4xl font-bold text-blue-600 mt-2">
                  {submissionResult.score} / {submissionResult.total_questions}
                </p>
                
              </div>
            )}
          </div>
        </main>
      </div>
    </ExerciseProvider>
  );
};

export default ConversationExercise;