// useSpeechSynthesis.js
import { useEffect, useState, useCallback } from 'react';

export const useSpeechSynthesis = () => {
  const [selectedVoice, setSelectedVoice] = useState(null);

 
  useEffect(() => {
    console.log(selectedVoice);
  }, [selectedVoice]);

  const selectVoice = (voices) => {
    // Attempt to select "Samantha" first
    let selectedVoice = voices.find(voice => voice.name === 'Samantha');
  
    // If "Samantha" is not available, choose a default voice
    if (!selectedVoice) {
        // You can prioritize by language or other criteria
        // Prioritize by language (e.g., 'en-US')
        const testVoices = voices.filter(voice => voice.lang === 'en-US');
        console.log("testVoices", testVoices);

        // Select the first available 'en-US' voice or fallback to the first voice in the list
        selectedVoice = testVoices.at(0) || voices[0];
        //selectedVoice = voices.find(voice => voice.lang === 'en-US') || voices[1];
    }
  
    return selectedVoice;
  };

  useEffect(() => {
    const voices = window.speechSynthesis.getVoices();
    console.log('Available voices:', voices);

    //const defaultVoice = voices.find(v => v.name.includes('Samantha') || v.name.includes('US English Female'));
    const defaultVoice = selectVoice(voices);
    setSelectedVoice(defaultVoice || voices[0]); // Default to first available voice
  }, []);

  const speakText = useCallback((text) => {
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel(); // Stop any ongoing speech
    }
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = selectedVoice;
    utterance.lang = 'en-US';
    window.speechSynthesis.speak(utterance);
  }, [selectedVoice]);

  const cancelSpeech = useCallback(() => {
    window.speechSynthesis.cancel();
  }, []);

  return {
    speakText,
    cancelSpeech,
    selectedVoice,
  };
};