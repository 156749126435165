import React from 'react';
import CourseContentLayout from '../components/CourseContentLayout';

const WritingSection = () => {
  return (
    <CourseContentLayout title="Writing Section">
      <section >
        <h2 className="text-2xl font-bold mb-4">6.1 Overview and Structure</h2>
        <p className="mb-4">
          The TOEFL iBT Writing section assesses your ability to write in English in an academic context. It includes two tasks designed to measure how well you can express, support, and organize your ideas in written form. The tasks require you to combine writing, reading, and listening skills, similar to the demands you may face in a university setting.
        </p>

        <h3 className="text-xl font-semibold mb-2">Key Structural Points:</h3>
        <ol className="list-decimal pl-6 mb-4">
          <li><strong>Task 1: Integrated Writing Task</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>Time:</strong> 20 minutes</li>
            <li><strong>Content:</strong> Read a passage (3 minutes), listen to a lecture, and write an essay that integrates both sources.</li>
            <li><strong>Length:</strong> 150-225 words</li>
          </ul>
          <li><strong>Task 2: Writing for an Academic Discussion Task</strong></li>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>Time:</strong> 10 minutes</li>
            <li><strong>Content:</strong> Write a response to a short academic discussion prompt where you share your opinion on a specific issue.</li>
            <li><strong>Length:</strong> 75-100 words</li>
          </ul>
        </ol>

        <h3 className="text-xl font-semibold mb-2">Total Time:</h3>
        <p className="mb-4">29 minutes total to complete both tasks.</p>

        <h3 className="text-xl font-semibold mb-2">Scoring:</h3>
        <ol className="list-decimal pl-6 mb-4">
          <li>Each task is scored based on the TOEFL Writing Rubrics, which evaluate:</li>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>Task Response:</strong> How well you address the task and answer the question.</li>
            <li><strong>Organization:</strong> The structure of your essay and how clearly you express your ideas.</li>
            <li><strong>Language Use:</strong> Grammar, vocabulary range, and accuracy.</li>
            <li><strong>Development:</strong> How well you develop and support your ideas with examples and reasoning.</li>
          </ul>
          <li>The final Writing score is reported on a scale of 0-30.</li>
        </ol>
      </section>
      <section className="mt-8">
        <h2 className="text-2xl font-bold mb-4">6.2 Integrated Writing Task</h2>

        <h3 className="text-xl font-semibold mb-4">6.2.1 Task Explanation</h3>
        <p className="mb-4">
          In the Integrated Writing task, you'll read a passage about an academic topic, listen to a lecture that discusses the same topic, and write a response summarizing the points from the lecture and explaining how they relate to the reading.
          You have 20 minutes to write your response, which should be between 150-225 words.
        </p>
        <p className="mb-4">
          <strong>Task Breakdown:</strong>
          <ol className="list-decimal pl-6">
            <li><strong>Read:</strong> A passage on an academic topic (3 minutes).</li>
            <li><strong>Listen:</strong> A lecture on the same topic (2-3 minutes).</li>
            <li><strong>Write:</strong> Summarize the lecture's points and explain how they relate to the reading (20 minutes).</li>
          </ol>
        </p>

        <h3 className="text-xl font-semibold mb-4">6.2.2 Detailed Strategies</h3>

        <h4 className="text-lg font-semibold mb-2">Reading Phase (3 minutes):</h4>
        <ul className="list-decimal pl-6 mb-4">
          <li>Skim the passage quickly to grasp the main topic.</li>
          <li>Re-read carefully, focusing on:
            <ul className="list-disc pl-6">
              <li>The main argument or position.</li>
              <li>3 key supporting points.</li>
            </ul>
          </li>
          <li>Take brief notes on your scratch paper:
            <ul className="list-disc pl-6">
              <li>Main topic: [1-2 words]</li>
              <li>Main argument: [Short phrase]</li>
              <li>Key point 1: [Brief note]</li>
              <li>Key point 2: [Brief note]</li>
              <li>Key point 3: [Brief note]</li>
            </ul>
          </li>
        </ul>

        <h4 className="text-lg font-semibold mb-2">Listening Phase (2-3 minutes):</h4>
        <ul className="list-decimal pl-6 mb-4">
          <li>Listen actively for:
            <ul className="list-disc pl-6">
              <li>The speaker's main position (usually contrasts with the reading).</li>
              <li>Specific points that challenge the reading's key points.</li>
            </ul>
          </li>
          <li>Take notes using abbreviations and symbols:
            <ul className="list-disc pl-6">
              <li>Speaker's position: [Short phrase]</li>
              <li>Point 1 vs Reading: [Brief note]</li>
              <li>Point 2 vs Reading: [Brief note]</li>
              <li>Point 3 vs Reading: [Brief note]</li>
            </ul>
          </li>
        </ul>

        <h4 className="text-lg font-semibold mb-2">Writing Phase (20 minutes):</h4>
        <ul className="list-decimal pl-6 mb-4">
          <li><strong>Plan (2-3 minutes):</strong> Review your notes and quickly outline your response:
            <ul className="list-disc pl-6">
              <li>Intro: Topic + Relationship between lecture and reading.</li>
              <li>Para 1: Reading point 1 + Lecture's counter-argument.</li>
              <li>Para 2: Reading point 2 + Lecture's counter-argument.</li>
              <li>Para 3: Reading point 3 + Lecture's counter-argument.</li>
            </ul>
          </li>
          <li><strong>Write (15-16 minutes):</strong>
            <ul className="list-disc pl-6">
              <li>Intro: Briefly state the topic and how the lecture relates to the reading.</li>
              <li>Body paragraphs:
                <ul className="list-disc pl-6">
                  <li>Start with a reading point, then explain how the lecture challenges or relates to this point.</li>
                  <li>Include specific details from both sources.</li>
                </ul>
              </li>
              <li>Use clear transitions between ideas.</li>
            </ul>
          </li>
          <li><strong>Review (1-2 minutes):</strong> Check for clarity, coherence, and correct any obvious grammar or spelling errors.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-4">6.2.3 Example Question</h3>
        <p className="mb-4">
          <strong>Reading Passage (3 minutes):</strong>
          Several hill forts in Scotland have vitrified stones. Three theories attempt to explain this phenomenon: religious rituals, accidental fire, or defensive strategy. However, none fully explain the vitrification process.
        </p>
        <p className="mb-4">
          <strong>Listening Passage (paraphrased):</strong> The professor challenges the reading theories, explaining that vitrification requires controlled conditions not possible during attacks or religious rituals and that vitrified walls are structurally weaker than traditional walls.
        </p>

        <h3 className="text-xl font-semibold mb-4">6.2.4 Sample Response with Applied Strategies</h3>
        <p className="mb-4">
          <strong>Introduction:</strong> The reading passage and the lecture both discuss theories about why some Scottish hill forts from the Middle Ages have vitrified walls. While the reading presents three possible explanations for this phenomenon, the lecturer challenges each of these theories with contrasting information.
        </p>
        <p className="mb-4">
          <strong>Body Paragraph 1:</strong> The reading suggests that vitrification might have been part of a religious ritual. However, the lecturer disputes this theory, explaining that by the time these forts were built, Christianity had replaced older traditions, making it unlikely that vitrification had religious significance.
        </p>
        <p className="mb-4">
          <strong>Body Paragraph 2:</strong> The second theory proposes that vitrification occurred accidentally during enemy attacks. The lecturer counters this by stating that the temperatures required to vitrify stone (over 1000°C) could not be achieved by open-air fires set during an attack.
        </p>
        <p className="mb-4">
          <strong>Body Paragraph 3:</strong> Finally, the reading suggests that fort builders may have vitrified walls to strengthen them against attacks. The lecturer challenges this by explaining that vitrified stones are more brittle and prone to shattering, which would weaken the walls rather than strengthen them.
        </p>

        <h3 className="text-xl font-semibold mb-4">6.2.5 Analysis of the Sample Response</h3>
        <p className="mb-4">
          The sample response follows the recommended structure, with an introduction that summarizes the topic and relationship between the reading and lecture, and body paragraphs that clearly address the key points from both sources.
        </p>
        <p className="mb-4">
          <strong>Key Elements of Success:</strong>
          <ul className="list-disc pl-6">
            <li>Clear organization: Each paragraph covers one theory and its counter-argument.</li>
            <li>Accurate content: The response correctly summarizes the main points from both sources.</li>
            <li>Language use: Effective use of transitions and academic vocabulary.</li>
          </ul>
        </p>
      </section>
      <section className="mt-8">
        <h2 className="text-2xl font-bold mb-4">6.3 Writing for an Academic Discussion Task</h2>

        <h3 className="text-xl font-semibold mb-4">6.3.1 Task Explanation</h3>
        <p className="mb-4">
          In this task, you'll participate in an online academic discussion by reading a professor's question and your classmates' responses, then writing your own response contributing to the discussion. You have 10 minutes to complete your response, which should be at least 100 words.
        </p>
        <p className="mb-4">
          <strong>Task Breakdown:</strong>
          <ul className="list-disc pl-6">
            <li><strong>Read:</strong> A professor’s question and two classmates’ responses (2-3 minutes).</li>
            <li><strong>Plan:</strong> Choose your stance and organize your points (1-2 minutes).</li>
            <li><strong>Write:</strong> Compose your response, providing reasons and examples (5-6 minutes).</li>
            <li><strong>Review:</strong> Ensure clarity, correct errors, and check coherence (1 minute).</li>
          </ul>
        </p>

        <h3 className="text-xl font-semibold mb-4">6.3.2 Detailed Strategies</h3>

        <h4 className="text-lg font-semibold mb-2">Reading Phase (2-3 minutes):</h4>
        <ul className="list-disc pl-6 mb-4">
          <li>Quickly read the professor’s question to understand the main topic and the specific aspect to address.</li>
          <li>Skim classmates’ responses, noting their positions and the key points or examples they use.</li>
        </ul>

        <h4 className="text-lg font-semibold mb-2">Planning Phase (1-2 minutes):</h4>
        <ul className="list-decimal pl-6 mb-4">
          <li>Decide on your position:
            <ul className="list-disc pl-6">
              <li>Agree with a classmate.</li>
              <li>Disagree with a classmate.</li>
              <li>Present a new perspective.</li>
            </ul>
          </li>
          <li>Brainstorm 1-2 main points to support your position.</li>
          <li>Think of specific examples or explanations for each point.</li>
        </ul>

        <h4 className="text-lg font-semibold mb-2">Writing Phase (5-6 minutes):</h4>
        <ul className="list-decimal pl-6 mb-4">
          <li>Start with a clear statement of your position.</li>
          <li>Develop each main point in a separate paragraph:
            <ul className="list-disc pl-6">
              <li>State the point.</li>
              <li>Explain or elaborate.</li>
              <li>Provide a specific example or reason.</li>
            </ul>
          </li>
          <li>If relevant, acknowledge other perspectives:
            <ul className="list-disc pl-6">
              <li>“While [Classmate] makes a good point about [X], I believe…”</li>
            </ul>
          </li>
          <li>Use transitions to connect ideas: “Furthermore,” “On the other hand,” “For instance.”</li>
        </ul>

        <h4 className="text-lg font-semibold mb-2">Review Phase (1 minute):</h4>
        <ul className="list-decimal pl-6 mb-4">
          <li>Check for clarity and coherence.</li>
          <li>Correct any obvious grammar or spelling errors.</li>
          <li>Ensure you have directly addressed the professor's question.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-4">6.3.3 Example Question</h3>
        <p className="mb-4"><strong>Professor’s Post:</strong> "In our discussion on education systems, we’ve explored various school types. I'd like you to consider boarding schools versus day schools. Do you think boarding schools are beneficial for students’ education, or are day schools better for most students? Explain your reasoning."</p>
        <p className="mb-4"><strong>Classmate 1 (Claire):</strong> "I would have loved to attend a boarding school. I think they help establish a strict daily routine and develop discipline. Plus, being with friends and classmates around the clock would have been a great opportunity for social growth."</p>
        <p className="mb-4"><strong>Classmate 2 (Andrew):</strong> "I don't support the boarding school system. I've heard about ‘boarding school syndrome,’ which suggests that attending boarding schools at a young age can lead to long-term emotional or behavioral challenges. Why take that risk when most students can attend day schools?"</p>

        <h3 className="text-xl font-semibold mb-4">6.3.4 Sample Response with Applied Strategies</h3>
        <p className="mb-4"><strong>Clear position statement:</strong> While both Claire and Andrew make valid points, I believe that the benefits of day schools generally outweigh those of boarding schools for most students.</p>
        <p className="mb-4"><strong>Main point 1 with explanation and example:</strong> Firstly, day schools allow students to maintain strong family connections, which are crucial for emotional development. By living at home, students can participate in family activities, share daily experiences with parents and siblings, and learn important life skills in a familiar environment. For instance, my cousin who attended a day school often talks about how family dinners were a time to discuss school challenges and receive guidance from her parents, helping her navigate academic and social pressures more effectively.</p>
        <p className="mb-4"><strong>Main point 2 with explanation and example:</strong> Furthermore, day schools offer a more balanced lifestyle by allowing students to engage with their local community. This exposure to a variety of people and experiences beyond the school environment can foster adaptability and real-world social skills. In my own experience attending a day school, I was able to participate in local sports leagues and volunteer activities, which broadened my perspective and helped me develop a strong sense of community involvement.</p>
        <p className="mb-4"><strong>Acknowledging other perspectives:</strong> While I appreciate Claire’s point about the discipline fostered in boarding schools, I believe similar routines and self-discipline can be developed in day schools with proper support from both teachers and parents. Additionally, addressing Andrew’s concern about emotional challenges, day schools might offer a more gradual and natural progression towards independence.</p>
        <p className="mb-4"><strong>Conclusion tying back to the professor’s question:</strong> In conclusion, while boarding schools may be beneficial for some students, I believe day schools provide a more well-rounded educational experience for most students by balancing academic growth with strong family connections and diverse community interactions.</p>
        <p className="mb-4"><strong>Word count:</strong> 258</p>

        <h3 className="text-xl font-semibold mb-4">6.3.5 Analysis of the Sample Response</h3>
        <p className="mb-4">
          This sample response effectively applies the strategies outlined earlier:
          <ul className="list-decimal pl-6">
            <li><strong>Clear Position:</strong> The writer clearly states their stance in the first sentence, favoring day schools.</li>
            <li><strong>Main Points:</strong> Each main point is developed in a separate paragraph and supported with specific examples and explanations.</li>
            <li><strong>Acknowledgment of Other Perspectives:</strong> The writer addresses both Claire’s and Andrew’s points, demonstrating critical thinking by considering multiple viewpoints.</li>
            <li><strong>Structure:</strong> The response follows a logical flow with a position statement, main points, addressing counterarguments, and conclusion.</li>
            <li><strong>Relevance:</strong> The response directly addresses the professor’s question about boarding schools versus day schools and provides reasoning for the preference of day schools.</li>
            <li><strong>Language Use:</strong> The response employs academic vocabulary and maintains a respectful and thoughtful tone.</li>
          </ul>
        </p>
      </section>
      <section className="mt-8">
        <h2 className="text-2xl font-bold mb-4">6.4 Writing Strategies</h2>

        <h3 className="text-xl font-semibold mb-4">6.4.1 Essay Structure and Organization</h3>
        <p className="mb-4">
          Effective essay structure is crucial for both the Integrated Writing and Writing for Academic Discussion tasks. Here's a detailed guide to structuring your essays:
        </p>

        <h4 className="text-lg font-semibold mb-2">Integrated Writing Task Structure</h4>
        <ol className="list-decimal pl-6 mb-4">
          <li><strong>Introduction</strong> (1-2 sentences):
            <ul className="list-disc pl-6">
              <li>Briefly state the topic</li>
              <li>Indicate that the lecture challenges or supports the reading</li>
            </ul>
          </li>
          <li><strong>Body Paragraphs</strong> (typically 3):
            <ul className="list-disc pl-6">
              <li>Each paragraph should focus on one main point from the reading and the corresponding point from the lecture.</li>
              <li>Structure:
                <ul className="list-disc pl-6">
                  <li>State the reading's point</li>
                  <li>Explain how the lecture responds to this point</li>
                  <li>Provide specific details from both sources</li>
                </ul>
              </li>
            </ul>
          </li>
          <li><strong>Conclusion</strong>: Not typically necessary due to time constraints</li>
        </ol>

        <pre className="bg-gray-100 p-4 rounded mb-4">
          <code>
            I. Introduction: Topic + Relationship between lecture and reading{"\n"}
            II. Body Paragraph 1: Reading point 1 + Lecture's response{"\n"}
            III. Body Paragraph 2: Reading point 2 + Lecture's response{"\n"}
            IV. Body Paragraph 3: Reading point 3 + Lecture's response
          </code>
        </pre>

        <h4 className="text-lg font-semibold mb-2">Writing for Academic Discussion Task Structure</h4>
        <ol className="list-decimal pl-6 mb-4">
          <li><strong>Opening statement</strong> (1-2 sentences): Clearly state your position on the topic</li>
          <li><strong>Body</strong> (2-3 paragraphs):
            <ul className="list-disc pl-6">
              <li>Each paragraph should present a main point supporting your position</li>
              <li>Structure:
                <ul className="list-disc pl-6">
                  <li>State your point</li>
                  <li>Explain or elaborate</li>
                  <li>Provide a specific example or reason</li>
                </ul>
              </li>
            </ul>
          </li>
          <li><strong>Acknowledgment of other perspectives</strong> (optional): Briefly address a counterargument or another viewpoint</li>
          <li><strong>Conclusion</strong> (1-2 sentences): Summarize your main points and restate your position</li>
        </ol>

        <pre className="bg-gray-100 p-4 rounded mb-4">
          <code>
            I. Opening statement: Position on the topic{"\n"}
            II. Main Point 1: Explanation + Example{"\n"}
            III. Main Point 2: Explanation + Example{"\n"}
            IV. (Optional) Acknowledgment of other perspectives{"\n"}
            V. Conclusion: Summary of points + Restated position
          </code>
        </pre>

        <h3 className="text-xl font-semibold mb-4">6.4.2 Developing Strong Thesis Statements</h3>
        <p className="mb-4">A strong thesis statement is crucial for clearly conveying your main argument or position. Here are strategies for developing effective thesis statements:</p>

        <ol className="list-decimal pl-6 mb-4">
          <li><strong>Be specific and focused</strong>:
            <p>Bad: "Boarding schools have both advantages and disadvantages."</p>
            <p>Good: "While boarding schools offer intensive academic environments, day schools provide a better balance of academic and personal development for most students."</p>
          </li>
          <li><strong>Make it arguable</strong>:
            <p>Bad: "The lecture discusses three theories about vitrified forts."</p>
            <p>Good: "The lecture systematically challenges the three main theories presented in the reading about the origin of vitrified forts."</p>
          </li>
          <li><strong>Align with the task</strong>:
            <ul className="list-disc pl-6">
              <li>For Integrated Writing: Indicate the relationship between the reading and lecture</li>
              <li>For Academic Discussion: Clearly state your position on the topic</li>
            </ul>
          </li>
          <li><strong>Keep it concise</strong>: Aim for one or two sentences that clearly express your main idea</li>
        </ol>

        <h4 className="text-lg font-semibold mb-2">Practice Exercise</h4>
        <p className="mb-4">Develop thesis statements for the following topics:</p>
        <ol className="list-decimal pl-6 mb-4">
          <li>Impact of social media on interpersonal relationships</li>
          <li>Effectiveness of standardized testing in education</li>
          <li>Pros and cons of renewable energy sources</li>
        </ol>

        <h3 className="text-xl font-semibold mb-4">6.4.3 Practical Application of Strategies with Examples</h3>
        <p className="mb-4">Let’s apply these strategies to a sample Writing for Academic Discussion prompt:</p>

        <p className="mb-4"><strong>Professor’s question:</strong> "Do you think universities should require students to take courses outside their major? Why or why not?"</p>

        <p className="mb-4"><strong>Step 1: Develop a thesis statement</strong></p>
        <p>"While specialization is important, universities should require students to take courses outside their major to foster well-rounded education and develop diverse skills essential for future career success."</p>

        <p className="mb-4"><strong>Step 2: Outline main points</strong></p>
        <ol className="list-decimal pl-6 mb-4">
          <li>Broadens perspective and critical thinking skills</li>
          <li>Enhances adaptability in the job market</li>
          <li>Promotes interdisciplinary problem-solving</li>
        </ol>

        <p className="mb-4"><strong>Step 3: Write the response</strong></p>
        <ul className="list-disc rounded pl-6">
        
            <li>I believe universities should indeed require students to take courses outside their major. While specialization is important, a well-rounded education that includes diverse subjects fosters critical thinking skills and prepares students for the complexities of the modern workforce.</li>
            
            <li>Firstly, exposure to various disciplines broadens students' perspectives and enhances their critical thinking abilities. For instance, an engineering student taking a literature course might develop new analytical skills that can be applied to problem-solving in their field. This interdisciplinary approach encourages students to think outside the box and approach challenges from multiple angles.</li>
            
            <li>Secondly, diverse course requirements enhance students' adaptability in the job market. In today's rapidly changing economy, employers value versatile employees who can navigate various contexts. A biology major with experience in business courses, for example, might have an edge in pharmaceutical sales or biotechnology startups.</li>
            
            <li>Lastly, promoting interdisciplinary studies prepares students for real-world problem-solving. Many of today's global challenges, such as climate change or public health crises, require collaboration across different fields. A political science major with knowledge of environmental science would be better equipped to contribute to policy discussions on climate change mitigation.</li>
            
            <li>While I understand the argument for deeper specialization, I believe the benefits of a diverse course load outweigh the potential drawbacks. By requiring students to step outside their comfort zones, universities can cultivate more well-rounded, adaptable, and innovative graduates ready to tackle the complex challenges of our interconnected world.</li>
        </ul>

        <p className="mb-4">This response effectively applies the strategies we've discussed:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>It opens with a clear thesis statement</li>
          <li>Each paragraph develops a main point with explanation and examples</li>
          <li>It acknowledges potential counterarguments</li>
          <li>The conclusion reinforces the main argument</li>
        </ul>
      </section>
      <section className="mt-8">
        <h2 className="text-2xl font-bold mb-4">6.5 Grammar and Vocabulary</h2>

        <h3 className="text-xl font-semibold mb-4">6.5.1 Common Grammar Issues and How to Avoid Them</h3>
        <ol className="list-decimal pl-6 mb-4">
          <li><strong>Subject-Verb Agreement</strong>
            <p>Incorrect: "The data show that there is many advantages."</p>
            <p>Correct: "The data show that there are many advantages."</p>
            <p><strong>Tip:</strong> Identify the subject and ensure the verb matches in number.</p>
          </li>
          <li><strong>Verb Tense Consistency</strong>
            <p>Incorrect: "The lecture discussed three theories and then explains their flaws."</p>
            <p>Correct: "The lecture discussed three theories and then explained their flaws."</p>
            <p><strong>Tip:</strong> Maintain consistent tense unless there's a clear reason to switch.</p>
          </li>
          <li><strong>Pronoun Reference</strong>
            <p>Unclear: "The professor and the students discussed the theory, which was interesting."</p>
            <p>Clear: "The professor and the students discussed the theory, which was an interesting topic."</p>
            <p><strong>Tip:</strong> Ensure pronouns clearly refer to a specific noun.</p>
          </li>
          <li><strong>Parallel Structure</strong>
            <p>Incorrect: "The course offers opportunities for learning, to network, and building skills."</p>
            <p>Correct: "The course offers opportunities for learning, networking, and building skills."</p>
            <p><strong>Tip:</strong> Use the same grammatical form for each item in a list.</p>
          </li>
          <li><strong>Run-on Sentences and Comma Splices</strong>
            <p>Incorrect: "The lecture contradicted the reading, it provided new evidence."</p>
            <p>Correct: "The lecture contradicted the reading; it provided new evidence."</p>
            <p><strong>Tip:</strong> Use appropriate punctuation or conjunctions to join independent clauses.</p>
          </li>
        </ol>

        <h4 className="text-lg font-semibold mb-2">Practice Exercise</h4>
        <p className="mb-4">Correct the grammar issues in the following sentences:</p>
        <ol className="list-decimal pl-6 mb-4">
          <li>"The data indicates that there is several factors to consider."</li>
          <li>"Students who studied abroad often develop new perspectives and became more independent."</li>
          <li>"Both the professor and the textbook discusses the theory in detail."</li>
          <li>"The research involved collecting data, analyzing results, and to write a report."</li>
          <li>"The experiment failed, the hypothesis was incorrect."</li>
        </ol>

        <h3 className="text-xl font-semibold mb-4">6.5.2 Expanding Academic Vocabulary</h3>
        <p className="mb-4">A strong academic vocabulary is crucial for effective TOEFL Writing responses. Here are strategies to expand your vocabulary:</p>

        <ol className="list-decimal pl-6 mb-4">
          <li><strong>Learn word families:</strong>
            <p>Example: analyze (verb), analysis (noun), analytical (adjective)</p>
            <p><strong>Practice:</strong> Create sentences using different forms of these words:
              <ul className="list-disc pl-6">
                <li>conclude</li>
                <li>significant</li>
                <li>diverse</li>
              </ul>
            </p>
          </li>
          <li><strong>Master academic collocations:</strong>
            <p>Examples:
              <ul className="list-disc pl-6">
                <li>"conduct research" (not "do research")</li>
                <li>"draw a conclusion" (not "make a conclusion")</li>
              </ul>
            </p>
            <p><strong>Practice:</strong> Fill in the blanks with appropriate collocations:
              <ul className="list-disc pl-6">
                <li>The study aims to ______ light on the issue of climate change.</li>
                <li>The results ______ significant implications for future research.</li>
                <li>The author ______ a compelling argument for educational reform.</li>
              </ul>
            </p>
          </li>
          <li><strong>Use precise verbs:</strong>
            <p>Instead of always using "say" or "show," incorporate verbs like:
              <ul className="list-disc pl-6">
                <li>argue</li>
                <li>contend</li>
                <li>demonstrate</li>
                <li>illustrate</li>
                <li>imply</li>
              </ul>
            </p>
            <p><strong>Practice:</strong> Replace "say" or "show" with more precise verbs in these sentences:
              <ul className="list-disc pl-6">
                <li>"The graph shows that pollution levels have increased."</li>
                <li>"The author says that the theory has limitations."</li>
              </ul>
            </p>
          </li>
          <li><strong>Incorporate academic phrases:</strong>
            <p>Examples:
              <ul className="list-disc pl-6">
                <li>"In light of the evidence..."</li>
                <li>"It can be argued that..."</li>
                <li>"This lends support to the idea that..."</li>
              </ul>
            </p>
            <p><strong>Practice:</strong> Use these phrases to begin sentences about a topic of your choice.</p>
          </li>
          <li><strong>Learn discipline-specific vocabulary:</strong>
            <p>Familiarize yourself with terms commonly used in various academic fields:
              <ul className="list-disc pl-6">
                <li>Economics: supply and demand, inflation, fiscal policy</li>
                <li>Psychology: cognitive dissonance, behavioral conditioning, neural plasticity</li>
                <li>Environmental Science: biodiversity, ecosystem, sustainability</li>
              </ul>
            </p>
            <p><strong>Practice:</strong> Choose an academic field and list 5-10 key terms. Then, write a short paragraph using at least 3 of these terms.</p>
          </li>
        </ol>

        <h3 className="text-xl font-semibold mb-4">6.5.3 Enhancing Coherence and Cohesion</h3>
        <p className="mb-4">Coherence (logical flow of ideas) and cohesion (smooth connection between sentences and paragraphs) are crucial for effective writing. Here are strategies to improve these aspects:</p>

        <ol className="list-decimal pl-6 mb-4">
          <li><strong>Use transition words and phrases:</strong>
            <ul className="list-disc pl-6">
              <li>To add information: furthermore, moreover, in addition</li>
              <li>To contrast: however, on the other hand, conversely</li>
              <li>To show cause and effect: therefore, consequently, as a result</li>
              <li>To conclude: in conclusion, to sum up, ultimately</li>
            </ul>
            <p><strong>Practice:</strong> Add appropriate transition words to connect these sentences:
              <ul className="list-disc pl-6">
                <li>The study showed a correlation between exercise and mental health. ______, it did not prove causation.</li>
                <li>Many students prefer online classes for their flexibility. ______, some find the lack of in-person interaction challenging.</li>
              </ul>
            </p>
          </li>
          <li><strong>Use referencing words:</strong>
            <p>This, that, these, those, it, they, such</p>
            <p>Example: "The research examined the effects of sleep deprivation. This study revealed significant impacts on cognitive function."</p>
          </li>
          <li><strong>Use paragraph topic sentences:</strong>
            <p>Start each paragraph with a sentence that introduces the main idea of that paragraph.</p>
            <p>Example: "One major advantage of renewable energy sources is their minimal environmental impact."</p>
          </li>
          <li><strong>Create coherent paragraphs:</strong>
            <p>Ensure all sentences in a paragraph relate to the main idea introduced in the topic sentence.</p>
          </li>
          <li><strong>Use parallel structures:</strong>
            <p>Example: "The study aimed to analyze the data, interpret the results, and formulate new hypotheses."</p>
          </li>
        </ol>

        <h4 className="text-lg font-semibold mb-2">Practice Exercise</h4>
        <p className="mb-4">Improve the coherence and cohesion of this paragraph:</p>
        <blockquote className="italic mb-4">"Renewable energy is important. Solar power is one type. Wind power is another. They don't pollute the environment. Fossil fuels cause pollution. Renewable energy can be expensive to set up. It saves money in the long run."</blockquote>

        <p className="mb-4">Improved version:</p>
        <blockquote className="italic mb-4">"Renewable energy sources play a crucial role in addressing environmental concerns. Two primary types of renewable energy are solar and wind power. Unlike fossil fuels, which contribute significantly to pollution, these renewable sources have minimal environmental impact. Although the initial setup costs for renewable energy systems can be high, they often result in long-term cost savings. Therefore, despite some challenges, the benefits of renewable energy make it an important part of our future energy strategy."</blockquote>

        <p>By applying these strategies and practicing regularly, you can significantly improve your grammar, vocabulary, and overall writing coherence for the TOEFL iBT Writing section.</p>
      </section>


    </CourseContentLayout>
  );
};

export default WritingSection;