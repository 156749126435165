// src/Speaking/Tasks/TaskStartScreen.jsx
import PropTypes from 'prop-types';
import '../../styles/TaskStartScreen.css';
import AudioTester from './AudioTester';
import React, { useEffect, useRef } from 'react';


const TaskStartScreen = ({ taskNumber, title, instructions, times, onStart }) => {
    const audioTesterRef = useRef(null);

    //const audioTesterRef = useRef(null);
    useEffect(() => {
        return () => {
            if (audioTesterRef.current && audioTesterRef.current.cleanup) {
                audioTesterRef.current.cleanup();
            }
        };
    }, []);

    

    return (
        <div className="task-content">
            <div className="task-inner">

            <h2 className="task-title">{title}</h2>
            <div className="instructions instructions-container">
                <strong>Instructions:</strong> {instructions}
            </div>
            <table className="time-table">
                <thead>
                    <tr>
                        <th>Description</th>
                        <th>Time</th>
                    </tr>
                </thead>
                <tbody>
                    {times.map((time, index) => (
                        <tr key={index}>
                            <td>{time.description}</td>
                            <td>{time.value} seconds</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="volume-reminder">
                    Please refresh page if audio isn't working. Thank you! 
            </div>
            <AudioTester ref={audioTesterRef} />
            {/*<div className="volume-reminder">
                    Reminder: Make sure your volume is turned up before starting the task!
                </div> */}
            </div>
            <button className="start-task-button" onClick={onStart}>
                Start Task
            </button>
        </div>
    );
};

function detectBrowser() {
    let userAgent = navigator.userAgent;
    let browserName = "Unknown";

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "Chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "Firefox";
    } else if (userAgent.match(/safari/i)) {
        browserName = "Safari";
    } else if (userAgent.match(/opr\//i)) {
        browserName = "Opera";
    } else if (userAgent.match(/edg/i)) {
        browserName = "Edge";
    } else if (userAgent.match(/msie|trident/i)) {
        browserName = "Internet Explorer";
    }

    return browserName;
}

let browser = detectBrowser();
localStorage.setItem("browser", browser);
console.log("You are using: " + browser);

TaskStartScreen.propTypes = {
    taskNumber: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    instructions: PropTypes.string.isRequired,
    times: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired
        })
    ).isRequired,
    onStart: PropTypes.func.isRequired
};

export default TaskStartScreen;