import React, { useEffect, useRef, useState } from 'react';

const AudioPrompt = ({ audioSrc, onComplete }) => {
    const audioRef = useRef(null);
    const [playbackFailed, setPlaybackFailed] = useState(false);

    useEffect(() => {
        const audio = audioRef.current;
        audio.addEventListener('ended', onComplete);
        
        // Attempt to autoplay
        audio.play().catch(() => {
            setPlaybackFailed(true);
        });

        return () => {
            audio.removeEventListener('ended', onComplete);
        };
    }, [onComplete]);

    const handleManualPlay = () => {
        const audio = audioRef.current;
        audio.play().then(() => {
            setPlaybackFailed(false);
        });
    };

    return (
        <div>
            <audio ref={audioRef} src={audioSrc} />
            {playbackFailed && (
                <div>
                    <button className="font-bold bg-red-700 hover:bg-red-800 hover:border-red-800 shadow-red-900 shadow-sm text-white border-2 border-red-700 px-3 py-2 rounded-lg mb-2 mt-2" onClick={handleManualPlay}>&#9658; Play Instruction Audio</button>
                    <p className="text-red-700 font-bold mt-3" >You're on an unsupported browser. Autoplay might not be available. Please press Play Audio button above &#8593; or continue with Chrome on desktop. </p>
                </div>
            )}

        </div>
    );
};

export default AudioPrompt;